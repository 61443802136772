import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  Card,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
  Alert
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import login from "../../assets/adminlogin.png";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { SessionContext } from "../SessionContext";
import COLORS from "../../ColorCodes/color"

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Username is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
});

const AdminLogin = ({ setOpen }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [backendError, setBackendError] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { setIsAdminSessionExpired } = useContext(SessionContext);
  const initialValues = {
    name: "",
    password: "",
  };

  useEffect(() => {
    setOpen(false);
  }, [setOpen]);

  const handleLogin = async (values, { setSubmitting }) => {
    try {
      const loginResponse = await axios.post("http://localhost:5000/api/admin/login", values);
      const { token } = loginResponse.data;

      localStorage.setItem("adminToken", token);
      setIsAdminSessionExpired(false);
      navigate("/admin/dashboard");
    } catch (error) {
      if (error.response && error.response.data) {
        setBackendError(error.response.data.message || "An error occurred during login.");
      } else {
        setBackendError("Error: Unable to process your request.");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isMediumScreen ? "0px" : "60px",
        background: COLORS.GREY_COLOR,
      }}
    >
      <Card
        style={{
          width: isMediumScreen ? "100%" : "70%",
          maxWidth: "1100px",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={12}
            lg={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                background: COLORS.LIGHTER_TEAL
              }}
            >
              <img src={login} alt="Login" style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "90%",
                padding: "20px",
              }}
            >
              <Typography sx={{ mb: 1, color: COLORS.BACKGROUND_COLOR0, fontWeight: "600", fontSize: "17px" }}>
                Admin Login
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", marginBottom: "10px", textAlign: "center" }}
              >
                Enter your details to login into the admin dashboard
              </Typography>
              {backendError && <Alert severity="error" sx={{ mb: 2 }}>{backendError}</Alert>}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                {({ errors, touched, handleChange, handleBlur }) => (
                  <Form style={{ width: "100%" }}>
                    <Field
                      as={TextField}
                      fullWidth
                      id="name"
                      name="name"
                      label="Username"
                      autoComplete="username"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      error={errors.name && touched.name}
                      helperText={errors.name && touched.name ? errors.name : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Field
                      as={TextField}
                      fullWidth
                      id="password"
                      name="password"
                      label="Password"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      error={errors.password && touched.password}
                      helperText={errors.password && touched.password ? errors.password : ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Button fullWidth variant="contained"  sx={{ mt: 3, mb: 2, backgroundColor: COLORS.BACKGROUND_COLOR0, "&:hover": { backgroundColor: COLORS.BACKGROUND_COLOR1, color: COLORS.BACKGROUND_COLOR0, fontWeight: "700" } }} type="submit">
                      Login
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default AdminLogin;
