import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CardMedia,
  InputAdornment,
  TextField,Breadcrumbs, Link
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import engineerImage from "../../assets/courses/engineer.jpg";
import SearchIcon from "@mui/icons-material/Search";
import COLORS from "../../ColorCodes/color";

const disciplines = [
  {
    id: 1,
    title: "MECHANICAL ENGINEERING",
    description:
      "Mechanical engineering deals with the design, development, and maintenance of mechanical systems. This includes machines, tools, engines, and more. Graduates can work in various industries like automotive, aerospace, manufacturing, and energy.",
    scope: [
      "Automotive Engineer",
      "Aerospace Engineer",
      "Mechanical Design Engineer",
      "Robotics Engineer",
      "HVAC Engineer",
      "Manufacturing Engineer",
      "Materials Engineer",
    ],
    salary: "₹5,00,000 - ₹12,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/2.jpg"),
  },
  {
    id: 2,
    title: "CIVIL ENGINEERING",
    description:
        "Civil Engineering involves designing and constructing infrastructure projects such as buildings, roads, bridges, and water supply systems. Graduates work in construction firms, government agencies, and consulting firms.",
    scope: [
      "Structural Engineer",
      "Transportation Engineer",
      "Environmental Engineer",
      "Geotechnical Engineer",
      "Water Resources Engineer",
      "Construction Manager",
      "Urban Planner"
    ],
    salary: "₹4,50,000 - ₹10,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/3.jpg"),
  },
  {
    id: 3,
    title: "ELECTRICAL AND ELECTRONICS ENGINEERING",
    description:
      "Electrical and electronics engineering involves the study of electrical systems, electronics, and electromagnetism. Graduates work with power generation, telecommunications, and various electronic devices. They design, develop, and test electrical equipment.",
    scope: [
      "Electrical Engineer",
      "Electronics Engineer",
      "Power Engineer",
      "Control Systems Engineer",
      "Telecommunications Engineer",
      "Instrumentation Engineer",
      "Renewable Energy Engineer"
    ],
    salary: "₹4,80,000 - ₹11,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/4.jpg"),
  },
  {
    id: 4,
    title: "AEROSPACE ENGINEERING",
    description:
      "Aerospace engineering deals with the design, development, and testing of aircraft, spacecraft, and missiles. This field involves aerodynamics, materials science, and propulsion systems. Graduates can work for aerospace companies, defense contractors, or government agencies.",
    scope: [
      "Aerospace Engineer",
      "Aircraft Design Engineer",
      "Avionics Engineer",
      "Flight Test Engineer",
      "Systems Engineer",
      "Propulsion Engineer",
      "Structural Engineer"
    ],
    salary: "₹5,50,000 - ₹15,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/5.jpg"),
  },
  {
    id: 5,
    title: "CHEMICAL ENGINEERING",
    description:
      "Chemical engineering focuses on the design and operation of chemical processes used in industries such as pharmaceuticals, food, and energy production. Graduates work with materials, chemicals, and processes to create efficient and safe production methods.",
    scope: [
      "Process Engineer",
      "Chemical Plant Operator",
      "Petrochemical Engineer",
      "Environmental Engineer",
      "Materials Engineer",
      "Quality Control Engineer",
      "Food and Beverage Engineer",
    ],
    salary: "₹5,20,000 - ₹12,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/6.jpg"),
  },
  {
    id: 6,
    title: "COMPUTER ENGINEERING",
    description:
      "Computer engineering combines aspects of electrical engineering and computer science. It involves the design and development of computer systems and software. Graduates work in areas such as software development, computer hardware, and network design.",
    scope: [
      "Information Technology",
      "Software Development",
      "Precision Agriculture Technology",
      "Agricultural IoT",
      "Farm Management Software",
      "Agricultural Data Analytics",
      "IoT Applications in Agriculture",
    ],
    salary: "₹4,80,000 - ₹12,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/7.jpg"),
  },
  {
    id: 7,
    title: "BIOMEDICAL ENGINEERING",
    description:
      "Biomedical engineering applies engineering principles to the medical field, focusing on healthcare technology and equipment. Graduates work on designing medical devices, prosthetics, and healthcare software. They bridge the gap between engineering and healthcare.",
    scope: [
      "Biomedical Engineer",
      "Clinical Engineer",
      "Medical Device Engineer",
      "Biomechanical Engineer",
      "Rehabilitation Engineer",
      "Healthcare Software Developer",
      "Research Scientist",
    ],
    salary: "₹4,50,000 - ₹10,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/8.jpg"),
  },
  {
    id: 8,
    title: "ENVIRONMENTAL ENGINEERING",
    description:
      "Environmental engineering involves the protection and improvement of the environment. It focuses on sustainability, waste management, pollution control, and water treatment. Graduates work to ensure a cleaner and healthier environment for communities and ecosystems.",
    scope: [
      "Environmental Engineer",
      "Water Resources Engineer",
      "Sustainability Consultant",
      "Waste Management Engineer",
      "Air Quality Engineer",
      "Renewable Energy Engineer",
      "Ecologist",
    ],
    salary: "₹4,20,000 - ₹9,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/9.jpg"),
  },
  {
    id: 9,
    title: "AUTOMOBILE ENGINEERING",
    description:
      "Automobile engineering deals with the design, development, and manufacturing of vehicles. Graduates work in the automotive industry, focusing on areas such as vehicle dynamics, safety, and performance.",
    scope: [
      "Automotive Engineer",
      "Vehicle Dynamics Engineer",
      "Automotive Design Engineer",
      "Safety Engineer",
      "Performance Engineer",
      "Manufacturing Engineer",
      "Electric Vehicle Engineer"
    ],
    salary: "₹4,80,000 - ₹11,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/10.jpg"),
  },
  {
    id: 10,
    title: "AGRICULTURAL ENGINEERING",
    description:
      "Agricultural engineering combines engineering principles with agricultural production. Graduates work on improving farming equipment, irrigation systems, and sustainable farming practices. They contribute to the efficiency and environmental impact of agriculture.",
    scope: [
      "Agricultural Engineer",
      "Farm Equipment Engineer",
      "Irrigation Engineer",
      "Precision Agriculture Engineer",
      "Food Processing Engineer",
      "Sustainable Agriculture Engineer",
      "Soil Conservation Engineer",
      "Food and Beverage Engineer",
    ],
    salary: "₹4,20,000 - ₹9,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/11.jpg"),
  },
  {
    id: 11,
    title: "INDUSTRIAL ENGINEERING",
    description:
      "Industrial Engineering focuses on optimizing complex processes and systems, improving efficiency and productivity in various industries like manufacturing, healthcare, and logistics.",
    scope: [
      "Manufacturing Optimization",
      "Supply Chain Management",
      "Quality Control",
      "Process Improvement",
      "Logistics Management",
      "Healthcare Systems Optimization",
      "Ergonomics",
    ],
    salary: "₹5,00,000 - ₹12,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/12.jpg"),
  },
  {
    id: 12,
    title: "MARINE ENGINEERING",
    description:
      "Marine Engineering involves designing and maintaining ships and offshore structures, as well as developing marine technology for renewable energy, underwater robotics, and naval architecture.",
    scope: [
      "Ship Design and Construction",
      "Marine Renewable Energy",
      "Naval Architecture",
      "Oceanographic Research",
      "Maritime Safety",
      "Offshore Oil and Gas",
      "Underwater Robotics",
    ],
    salary: "₹5,20,000 - ₹13,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/13.jpg"),
  },
  {
    id: 13,
    title: "STRUCTURAL ENGINEERING",
    description:
      "Structural Engineering focuses on designing safe and sustainable structures such as buildings, bridges, and dams, using advanced materials and technologies to withstand various environmental conditions.",
    scope: [
      "Building Design and Construction",
      "Bridge Engineering",
      "Tall Building Engineering",
      "Infrastructure Rehabilitation",
      "Forensic Engineering",
      "Seismic Engineering",
    ],
    salary: "₹5,50,000 - ₹13,50,000 per year",
    image: require("../../assets/coursesDetail/engineer/14.jpg"),
  },
  {
    id: 14,
    title: "ARCHITECTURAL ENGINEERING",
    description:
      "Architectural Engineering combines architectural principles with engineering to design efficient and sustainable buildings, integrating systems like HVAC, lighting, and water management for optimal performance.",
    scope: [
      "Building Systems Design",
      "Energy Efficiency",
      "Building Information Modeling",
      "Healthcare Facility Design",
      "Green Building Certification",
      "Historic Preservation",
    ],
    salary: "₹5,20,000 - ₹13,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/15.jpg"),
  },
  {
    id: 15,
    title: "ENGINEERING MANAGEMENT",
    description:
      "Engineering Management involves overseeing engineering projects and teams, focusing on strategic planning, resource allocation, risk management, and business development within engineering organizations.",
    scope: [
      "Project Management",
      "Team Leadership",
      "Strategic Planning",
      "Resource Allocation",
      "Risk Management",
      "Quality Assurance",
      "Business Development",
    ],
    salary: "₹6,00,000 - ₹15,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/16.jpg"),
  },
  {
    id: 16,
    title: "ROBOTICS ENGINEERING",
    description:
      "Robotics Engineering deals with designing and developing robotic systems for industrial automation, autonomous vehicles, medical applications, and space exploration, integrating AI and advanced sensors.",
    scope: [
      "Industrial Automation",
      "Autonomous Vehicles",
      "Medical Robotics",
      "Human-Robot Interaction",
      "AI and Machine Learning",
      "Space Exploration",
      "Agricultural Robotics"
    ],
    salary: "₹5,50,000 - ₹14,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/17.jpg"),
  },
  {
    id: 17,
    title: "NUCLEAR ENGINEERING",
    description:
      "Nuclear Engineering focuses on nuclear energy applications, including power generation, radiation protection, medical imaging, and research in advanced nuclear technologies like fusion and waste management.",
    scope: [
      "Nuclear Power Generation",
      "Radiation Protection",
      "Nuclear Reactor Design",
      "Medical Applications",
      "Nuclear Waste Management",
      "Nuclear Safety Regulation"
    ],
    salary: "₹6,20,000 - ₹15,50,000  per year",
    image: require("../../assets/coursesDetail/engineer/18.jpg"),
  },
  {
    id: 18,
    title: "PETROLEUM ENGINEERING",
    description:
      "Petroleum Engineering deals with exploration, drilling, and production of oil and gas resources, including reservoir engineering, drilling operations, offshore platforms, and petroleum geology.",
    scope: [
      "Oil and Gas Exploration",
      "Drilling Operations",
      "Reservoir Engineering",
      "Petroleum Geology",
      "Production Optimization",
      "Health and Safety",
      "Renewable Energy"
    ],
    salary: "₹6,50,000 - ₹16,50,000 per year",
    image: require("../../assets/coursesDetail/engineer/19.jpg"),
  },
  {
    id: 19,
    title: "TELECOMMUNICATION ENGINEERING",
    description:
      "Telecommunication Engineering involves designing, developing, and maintaining communication systems such as networks, mobile technologies, and satellite communications for voice, data, and multimedia transmission.",
    scope: [
      "Wireless Communication",
      "Network Security",
      "Internet of Things (IoT)",
      "5G Technology",
      "Satellite Communication",
      "Optical Fiber Communication",
      "Mobile Networks"
    ],
    salary: "₹5,80,000 - ₹14,50,000 per year",
    image: require("../../assets/coursesDetail/engineer/20.jpg"),
  },
  {
    id: 20,
    title: "TRANSPORTATION ENGINEERING",
    description:
      "Transportation Engineering focuses on designing and managing transportation systems, including roads, railways, airports, and public transit, to ensure efficient and safe movement of people and goods.",
    scope: [
      "Roadway Design",
      "Traffic Engineering",
      "Public Transportation",
      "Urban Planning",
      "Intelligent Transportation Systems",
      "Railway Engineering",
      "Airport Design"
    ],
    salary: "₹5,20,000 - ₹13,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/21.jpg"),
  },
  {
    id: 21,
    title: "FOUNDATION ENGINEERING",
    description:
      "Foundation Engineering is a branch of civil engineering that focuses on the design and construction of foundations for buildings, bridges, and other structures. Students learn about soil mechanics, foundation types, and construction techniques.",
    scope: [
      "Geotechnical Engineer",
      "Foundation Design Engineer",
      "Construction Manager",
      "Structural Engineer",
      "Site Engineer",
      "Tunneling Engineer",
      "Earthquake Engineer"
    ],
    salary: "₹4,00,000 - ₹8,00,000  per year",
    image: require("../../assets/coursesDetail/engineer/22.jpg"),
  },
  {
    id: 22,
    title: "MATERIALS ENGINEERING",
    description:
      "Materials Engineering is a branch of engineering that focuses on the study of materials and their properties for various applications. Students learn about material science, metallurgy, and material testing.",
    scope: [
      "Materials Engineer",
      "Metallurgical Engineer",
      "Quality Control Engineer",
      "Research Scientist",
      "Process Engineer",
      "Manufacturing Engineer",
      "Corrosion Engineer"
    ],
    salary: "₹4,00,000 - ₹8,00,000  per year",
    image: require("../../assets/coursesDetail/engineer/23.jpg"),
  },
  {
    id: 23,
    title: "ENERGY ENGINEERING",
    description:
      "Energy Engineering is a branch of engineering that focuses on the study of energy sources, conversion, and utilization. Students learn about renewable energy, power generation, and energy efficiency.",
    scope: [
      "Energy Consultant",
      "Renewable Energy Engineer",
      "Power Plant Engineer",
      "Energy Auditor",
      "Energy Analyst",
      "Sustainability Engineer",
      "Electrical Engineer"
    ],
    salary: "₹4,50,000 - ₹9,00,000  per year",
    image: require("../../assets/coursesDetail/engineer/24.jpg"),
  },
  {
    id: 24,
    title: "AERONAUTICAL ENGINEERING",
    description:
      "Aeronautical Engineering is a branch of engineering that focuses on the study of aircraft design, construction, and operation. Students learn about aerodynamics, propulsion systems, and aircraft structures.",
    scope: [
      "Aerospace Engineer",
      "Aircraft Design Engineer",
      "Avionics Engineer",
      "Flight Test Engineer",
      "Aerospace Systems Engineer",
      "Aircraft Maintenance Engineer",
      "Aerodynamicist"
    ],
    salary: "₹5,00,000 - ₹10,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/25.jpg"),
  },
  {
    id: 25,
    title: "ARTIFICIAL INTELLIGENCE & DATA SCIENCE",
    description:
      "Artificial Intelligence & Data Science is an interdisciplinary field that focuses on the study of algorithms and techniques for analyzing and interpreting complex data. Students learn about machine learning, data mining, and predictive analytics.",
    scope: [
      "Data Scientist",
      "Machine Learning Engineer",
      "Artificial Intelligence Researcher",
      "Big Data Analyst",
      "Business Intelligence Developer",
      "Data Engineer",
      "Statistician"
    ],
    salary: "₹6,00,000 - ₹12,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/26.jpg"),
  },
  {
    id: 26,
    title: "ARTIFICIAL INTELLIGENCE & MACHINE LEARNING",
    description:
        "Artificial Intelligence & Machine Learning is a subfield of artificial intelligence that focuses on the study of algorithms and models that enable computers to learn from data and make predictions. Students learn about neural networks, deep learning, and pattern recognition.",
    scope: [
      "Machine Learning Engineer",
      "Data Scientist",
      "AI Research Scientist",
      "AI Solutions Architect",
      "Robotics Engineer",
      "NLP Engineer",
      "Computer Vision Engineer"
    ],
    salary: "₹6,00,000 - ₹12,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/27.jpg"),
  },
  {
    id: 27,
    title: "AUTOMATION & ROBOTICS",
    description:
          "Automation & Robotics is a branch of engineering that focuses on the study of automated systems and robotic technologies. Students learn about control systems, sensors, and robotic programming.",
    scope: [
      "Robotics Engineer",
      "Automation Engineer",
      "Control Systems Engineer",
      "Mechatronics Engineer",
      "Industrial Engineer",
      "Robotics Technician",
      "Automation Specialist"
    ],
    salary: "₹4,50,000 - ₹9,00,000  per year",
    image: require("../../assets/coursesDetail/engineer/28.jpg"),
  },
  {
    id: 28,
    title: "BIOTECHNOLOGY",
    description:
        "Biotechnology is a field of applied biology that focuses on the study of living organisms and biological systems to develop products and technologies. Students learn about genetic engineering, bioprocessing, and bioinformatics.",
    scope: [
      "Biotechnologist",
      "Genetic Engineer",
      "Bioprocess Engineer",
      "Bioinformatician",
      "Research Scientist",
      "Pharmaceutical Scientist",
      "Clinical Research Associate"
    ],
    salary: "₹4,50,000 - ₹9,00,000  per year",
    image: require("../../assets/coursesDetail/engineer/29.jpg"),
  },
  {
    id: 29,
    title: "CYBER SECURITY",
    description:
          "Cyber Security is a branch of engineering that focuses on the study of protecting computer systems, networks, and data from cyber threats. Students learn about cryptography, network security, and ethical hacking.",
    scope: [
      "Cyber Security Analyst",
      "Information Security Consultant",
      "Security Operations Engineer",
      "Penetration Tester",
      "Incident Responder",
      "Security Architect",
      "Threat Intelligence Analyst"
    ],
    salary: "₹5,00,000 - ₹10,00,000  per year",
    image: require("../../assets/coursesDetail/engineer/30.jpg"),
  },
  {
    id: 30,
    title: "COMPUTER SCIENCE & TECHNOLOGY",
    description:
            "Computer Science & Technology is a field of engineering that focuses on the study of computer systems, software development, and information technology. Students learn about programming languages, algorithms, and computer networks.",
    scope: [
      "Software Engineer",
      "Web Developer",
      "Systems Analyst",
      "Database Administrator",
      "Network Engineer",
      "IT Consultant"
    ],
    salary: "₹6,00,000 - ₹12,00,000  per year",
    image: require("../../assets/coursesDetail/engineer/31.jpg"),
  },
  {
    id: 31,
    title: "COMPUTER SCIENCE & DESIGN",
    description:
      "Computer Science & Design is a specialized field that combines computer science principles with design methodologies to create user-friendly software applications and interfaces. Students learn about user experience design, human-computer interaction, and graphic design.",
    scope: [
      "UX/UI Designer",
      "Frontend Developer",
      "Interaction Designer",
      "Product Designer",
      "Web Designer",
      "Graphic Designer",
      "Game Designer"
    ],
    salary: "₹4,00,000 - ₹8,00,000  per year",
    image: require("../../assets/coursesDetail/engineer/32.jpg"),
  },
  {
    id: 32,
    title: "ELECTRONICS & COMMUNICATION",
    description:
      "Electronics & Communication Engineering is a branch of engineering that focuses on the study of electronic devices, circuits, and communication systems. Students learn about analog and digital electronics, signal processing, and wireless communication.",
    scope: [
      "Electronics Engineer",
      "Communication Engineer",
      "Network Engineer",
      "Telecommunication Engineer",
      "Embedded Systems Engineer",
      "RF Engineer",
      "VLSI Design Engineer"
    ],
    salary: "₹4,50,000 - ₹9,00,000  per year",
    image: require("../../assets/coursesDetail/engineer/33.jpg"),
  },
  {
    id: 33,
    title: "ELECTRONICS & COMPUTER ENGINEERING",
    description:
        "Electronics & Computer Engineering is a field of engineering that integrates electronic hardware design with computer software development. Students learn about computer architecture, digital systems design, and embedded software programming.",
    scope: [
      "Hardware Engineer",
      "Firmware Engineer",
      "Systems Engineer",
      "Embedded Software Developer",
      "Computer Vision Engineer",
      "Robotics Engineer",
      "IoT Engineer"
    ],
    salary: "₹5,00,000 - ₹10,00,000   per year",
    image: require("../../assets/coursesDetail/engineer/34.jpg"),
  },
  {
    id: 34,
    title: "FASHION TECHNOLOGY",
    description:
      "Fashion Technology is a specialized field that combines fashion design principles with technology to create innovative clothing and accessories. Students learn about textile science, garment construction, and fashion CAD software.",
    scope: [
      "Fashion Designer",
      "Textile Engineer",
      "Apparel Merchandiser",
      "Fashion Technologist",
      "Pattern Maker",
      "Fashion Illustrator",
      "Fashion Buyer"
    ],
    salary: "₹3,50,000 - ₹7,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/35.jpg"),
  },
  {
    id: 35,
    title: "INFORMATION TECHNOLOGY",
    description:
      "Information Technology is a broad field that focuses on the use of computers and telecommunications to store, retrieve, and transmit data. Students learn about computer networks, database management, and software development.",
    scope: [
      "Software Developer",
      "Network Administrator",
      "Systems Analyst",
      "Database Administrator",
      "IT Consultant",
      "Cyber Security Analyst",
      "Web Developer"
    ],
    salary: "₹4,00,000 - ₹8,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/36.jpg"),
  },
  {
    id: 36,
    title: "INSTRUMENTATION & CONTROL ENGINEERING",
    description:
      "Instrumentation & Control Engineering is a branch of engineering that focuses on the study of measurement and control systems in various industrial processes. Students learn about sensors, transducers, and process control techniques.",
    scope: [
      "Control Systems Engineer",
      "Instrumentation Engineer",
      "Automation Engineer",
      "PLC Programmer",
      "Process Engineer",
      "Robotics Engineer",
      "SCADA Engineer"
    ],
    salary: "₹4,50,000 - ₹9,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/37.jpg"),
  },
  {
    id: 37,
    title: "MECHATRONICS ENGINEERING",
    description:
      "Mechatronics Engineering is an interdisciplinary field that integrates mechanical engineering, electrical engineering, and computer science to design and control complex systems. Students learn about robotics, automation, and embedded systems.",
    scope: [
      "Mechatronics Engineer",
      "Robotics Engineer",
      "Automation Engineer",
      "Embedded Systems Engineer",
      "Control Systems Engineer",
      "Product Design Engineer",
      "System Integration Engineer"
    ],
    salary: "₹5,00,000 - ₹10,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/38.jpg"),
  },
  {
    id: 38,
    title: "METALLURGICAL ENGINEERING",
    description:
        "Metallurgical Engineering is a branch of engineering that focuses on the study of metals and their properties. Students learn about metallurgical processes, materials testing, and alloy development.",
    scope: [
      "Metallurgical Engineer",
      "Materials Engineer",
      "Quality Control Engineer",
      "Process Engineer",
      "Foundry Engineer",
      "Corrosion Engineer",
      "Metallography Technician"
    ],
    salary: "₹4,00,000 - ₹8,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/39.jpg"),
  },
  {
    id: 39,
    title: "NANO TECHNOLOGY",
    description:
          "Nano Technology is a multidisciplinary field that focuses on the manipulation of matter at the atomic and molecular scale to create new materials and devices with unique properties. Students learn about nanomaterials, nanoelectronics, and nanomedicine.",
    scope: [
      "Nanotechnologist",
      "Research Scientist (Nano)",
      "Nano Engineer",
      "Nanomaterials Specialist",
      "Nanoelectronics Engineer",
      "Biomedical Nanotechnologist",
      "Nanotechnology Consultant"
    ],
    salary: "₹5,50,000 - ₹11,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/40.jpg"),
  },
  {
    id: 40,
    title: "PLASTIC ENGINEERING",
    description:
        "Plastic Engineering is a branch of engineering that focuses on the study of polymers and plastic materials. Students learn about polymer chemistry, plastic processing techniques, and product design.",
    scope: [
      "Polymer Engineer",
      "Plastic Product Designer",
      "Plastic Processing Engineer",
      "Quality Assurance Engineer (Plastics)",
      "Material Scientist",
      "Packaging Engineer",
      "Composite Materials Engineer",
    ],
    salary: "₹3,60,000 - ₹8,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/41.jpg"),
  },
  {
    id :41,
    title: "SAFETY & FIRE ENGINEERING ENGINEERING",
    description:
        "Safety & Fire Engineering focuses on the study of fire prevention, protection, and safety management systems. Students learn about fire dynamics, risk assessment, and emergency response planning.",
    scope: [
      "Fire Safety Engineer",
      "Safety Officer",
      "HSE Manager",
      "Industrial Hygienist",
      "Risk Analyst",
      "Emergency Planner",
      "Occupational Health"
    ],
    salary: "₹4,50,000 - ₹9,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/42.jpg"),
  },
  {
    id: 42,
    title: "TEXTILE ENGINEERING",
    description:
      "Textile Engineering focuses on the study of textile materials, production processes, and product development. Students learn about fabric manufacturing, dyeing, and quality control",
    scope: [
      "Textile Technologist",
      "Textile Engineer",
      "Production Manager (Textiles)",
      "Quality Assurance Manager",
      "Research Scientist (Textiles)",
      "Technical Sales Engineer",
      "Fashion Designer"
    ],
    salary: "₹₹4,00,000 - ₹8,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/43.jpg"),
  },
  {
    id: 43,
    title: "B.TECH. MATHEMATICAL AND COMPUTING",
    description:
      "B.Tech. Mathematical and Computing is a specialized program that integrates mathematics with computing principles. Students learn about mathematical modeling, computational algorithms, and data analysis.",
    scope: [
      "Data Scientist",
      "Machine Learning Engineer",
      "Computational Mathematician",
      "Quantitative Analyst",
      "Software Engineer",
      "Research Scientist (Computing)",
      "Cryptographer"
    ],
    salary: "₹5,00,000 - ₹10,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/44.jpg"),
  },
  {
    id: 44,
    title: "COMPUTER SCIENCE",
    description:
      "Computer Science is a field that focuses on the study of computer systems, software development, and information technology. Students learn about programming languages, algorithms, and computer networks.",
    scope: [
      "Software Engineer",
      "Systems Analyst",
      "Web Developer",
      "Network Engineer",
      "Database Administrator",
      "IT Consultant",
      "Cybersecurity Analyst"
    ],
    salary: "₹4,00,000 - ₹8,00,000 per year",
    image: require("../../assets/coursesDetail/engineer/45.jpg"),
  },
]

const Engineering = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDisciplines, setFilteredDisciplines] = useState(disciplines);

  useEffect(() => {
    const handleFiltering = () => {
      if (!searchTerm.trim()) {
        setFilteredDisciplines(disciplines);
        return;
      }
      const filtered = disciplines.filter(discipline =>
        discipline.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDisciplines(filtered);
    };
    handleFiltering();
  }, [searchTerm, disciplines]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCardClick = (index,name) => {
    setSelectedCard(selectedCard === index ? null : index);
    navigate(`/courses/engineering/${name}`);
  };

  const CardComponent = () => {
    return (
      <div style={{ background: COLORS.GREY_COLOR, paddingLeft: "15px", paddingRight: "15px", paddingBottom: "22px" }}>
        {filteredDisciplines.length === 0 ? (
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            No courses found
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredDisciplines.map((discipline, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    cursor: "pointer",
                    transition: "box-shadow 0.3s ease-in-out",
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BACKGROUND_COLOR0,
                    "&:hover": {
                      boxShadow: 10,
                      color: COLORS.BACKGROUND_COLOR1
                    },
                  }}
                  onClick={() => handleCardClick(index, discipline.title)}
                >
                  <CardMedia
                    component="img"
                    image={discipline.image}
                    alt={discipline.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {discipline.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };
  return (
    <Box sx={{ padding: "36px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
          Courses
        </Link>
        <Link component={RouterLink} to="/courses/engineering"style={{fontWeight: "600"}} color={COLORS.BACKGROUND_COLOR1}>
          Engineering
        </Link>
      </Breadcrumbs>
      <div style={{ background: COLORS.GREY_COLOR, marginTop: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={titleRef}
            variant="h1"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginBottom: "10px",
              fontSize: "25px",
              textAlign: "center",
              background: COLORS.BACKGROUND_COLOR0,
              padding: "10px",
              color: COLORS.BACKGROUND_COLOR2,
              flexGrow: 1,
            }}
          >
           ENGINEERING
          </Typography>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
          <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
          Engineering is a broad field that involves the application of scientific and mathematical principles to design, create, and improve systems, structures, processes, and devices. Engineers use their knowledge to solve practical problems, innovate, and contribute to the development of technology in various industries. The scope of engineering is vast, encompassing disciplines such as civil engineering, mechanical engineering, electrical engineering, computer engineering, aerospace engineering, chemical engineering, biomedical engineering, and many more. Engineers play a crucial role in shaping the modern world by designing and implementing solutions that address challenges and enhance the quality of life.
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: "10px", textAlign: "justify" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Diverse Career Opportunities:</strong> Engineering offers a wide array of career paths, allowing individuals to specialize in areas such as civil engineering, mechanical engineering, electrical engineering, computer engineering, and more. This diversity enables individuals to find a niche that aligns with their interests and passions.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>High Demand and Job Security:</strong> Engineers are consistently in demand across various industries due to their specialized skills. The demand for engineering expertise often translates into favorable job security and opportunities, even in challenging economic conditions.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Innovation and Impact:</strong> Engineers have the opportunity to be at the forefront of innovation. Through designing and creating new technologies, systems, and solutions, engineers contribute to advancements that can have a significant impact on society, fostering a sense of purpose and accomplishment.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Global Mobility and Collaboration:</strong> Engineering is a globally recognized profession, and many engineering projects require collaboration on an international scale. Choosing engineering opens up opportunities for professionals to work on diverse projects and engage with a global network of colleagues and experts.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Financial Reward:</strong> Engineering professions often come with competitive salaries. The combination of specialized skills, high demand, and the level of responsibility involved in engineering roles can lead to financial rewards, providing a stable and potentially lucrative career.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <img src={engineerImage} alt="nurse" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: "10px", paddingRight:"10px"}}>
          <h2 style={{color: COLORS.BACKGROUND_COLOR0}}>COURSES OFFERED ({disciplines.length} courses)</h2>
          <TextField
            placeholder="Search courses..."
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box sx={{ mt: 4 }}>
          <CardComponent />
        </Box>
      </div>

    </Box>
  );
};

export default Engineering;
