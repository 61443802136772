import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Avatar, Typography } from "@mui/material";
import backgroundImg from "../../assets/testimonials.jpg";
import COLORS from "../../ColorCodes/color";
import testimonial1 from "../../assets/testimonials/THARUN M.jpg";

const testimonials = [
  {
    id: 1,
    avatarUrl: testimonial1,
    text: "I found the ideal teacher training program thanks to What Next Educate. Their dedication to quality services truly sets them apart.",
    name: "Nanthakumar",
  },
  {
    id: 2,
    avatarUrl: testimonial1,
    text: "What Next Educate changed my life! The career counseling I received helped me choose the perfect path after 12th. Their placement assistance landed me my dream job.",
    name: "Balasankari",
  },
  {
    id: 3,
    avatarUrl: testimonial1,
    text: "I'm so grateful for the education counseling at What Next Educate. Their study abroad services opened up a world of opportunities for me!",
    name: "Janakrishnamoorthy",
  },
  {
    id: 4,
    avatarUrl: testimonial1,
    text: "The personalized admissions assistance I got here made applying to colleges stress-free. I highly recommend What Next Educate for anyone unsure about their future.",
    name: "Mala",
  },
];

const Testimonials = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 720 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      style={{
        position: "relative",
        backgroundImage: `url(${backgroundImg})`,
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding: "50px 20px",
        color: COLORS.WHITE,
        zIndex: 1,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.6)",
          zIndex: -1,
        }}
      />
      <div
        style={{
          background: "rgba(32, 44, 69, 0.5)",
          padding: "30px",
          borderRadius: "8px",
          margin: "0 auto",
          maxWidth: "800px",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <Typography variant="h5" style={{ fontWeight: 300, color: COLORS.BACKGROUND_COLOR2 }}>
            Happy Student
          </Typography>
          <Typography variant="h2" style={{ color: COLORS.BACKGROUND_COLOR1, fontWeight: "bold", marginBottom: "10px" }}>
            Testimonials
          </Typography>
        </div>

        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          arrows={false}
          renderDotsOutside={false}
          containerClass="carousel-container"
          itemClass="carousel-item-padding-40-px"
          dotListClass="custom-dot-list-style"
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} style={{ display: "flex", alignItems: "center", padding: "10px", background: COLORS.CARD_COLOR, borderRadius: "8px" }}>
              <Avatar src={testimonial.avatarUrl} sx={{ width: 75, height: 75 }} style={{ marginRight: "20px" }} />
              <div>
                <Typography variant="body1" style={{ color: COLORS.WHITE, marginBottom: "10px" }}>
                  {testimonial.text}
                </Typography>
                <Typography variant="body2" style={{ color: COLORS.BACKGROUND_COLOR1, fontStyle: "italic" }}>
                  - {testimonial.name}, <small style={{ color: COLORS.GRAY_LIGHT }}>Student</small>
                </Typography>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Testimonials;
