import React, { useEffect } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography, Card, CardContent, Grid, List, ListItem, ListItemText, ListItemIcon, Button } from "@mui/material";
import { FiberManualRecord as FiberManualRecordIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import diploma1 from "../../assets/coursesDetail/diploma/1.jpg";
import diploma2 from "../../assets/coursesDetail/diploma/2.jpg";
import diploma3 from "../../assets/coursesDetail/diploma/3.jpg";
import diploma4 from "../../assets/coursesDetail/diploma/4.jpg";
import diploma5 from "../../assets/coursesDetail/diploma/5.jpg";
import diploma6 from "../../assets/coursesDetail/diploma/6.jpg";
import diploma7 from "../../assets/coursesDetail/diploma/7.jpg";
import diploma8 from "../../assets/coursesDetail/diploma/8.jpg";
import diploma9 from "../../assets/coursesDetail/diploma/9.jpg";
import diploma10 from "../../assets/coursesDetail/diploma/10.jpg";
import COLORS from "../../ColorCodes/color";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const disciplines = [
  {
    id: "diploma1",
    title: "DIPLOMA IN CIVIL ENGINEERING",
    description:
        "is a vocational program that provides training in various aspects of civil engineering, including construction techniques, surveying, and structural analysis. Students learn about building design, infrastructure development, and project management.",
    scope: [
      "Civil Engineering Technician",
      "Construction Supervisor",
      "Surveyor",
      "CAD Technician",
      "Estimator",
      "Site Inspector",
      "Highway Technician"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma1
  },
  {
    id: "diploma2",
    title: "DIPLOMA IN MECHANICAL ENGINEERING",
    description:
        "is a vocational program that focuses on the study of mechanical systems, machinery, and manufacturing processes. Students learn about thermodynamics, fluid mechanics, and machine design.",
    scope: [
      "Mechanical Engineering Technician",
      "Maintenance Supervisor",
      "CAD/CAM Engineer",
      "Quality Control Inspector",
      "Production Supervisor",
      "HVAC Technician",
      "Tool Designer"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma2
  },
  {
    id: "diploma3",
    title: "DIPLOMA IN ELECTRICAL ENGINEERING",
    description:
        "is a vocational program that provides training in electrical systems, power generation, and distribution. Students learn about circuit analysis, electrical machines, and renewable energy technologies.",
    scope: [
      "Electrical Engineering Technician",
      "Electrician",
      "Power Plant Operator",
      "Control Panel Designer",
      "Instrumentation Technician",
      "Renewable Energy Technician",
      "Maintenance Electrician"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma3
  },
  {
    id: "diploma4",
    title: "DIPLOMA IN ELECTRONICS AND COMMUNICATION ENGINEERING",
    description:
        "is a vocational program that focuses on the study of electronic devices, circuits, and communication systems. Students learn about analog and digital electronics, telecommunications, and microcontroller programming.",
    scope: [
      "Electronics Technician",
      "Communication Engineer",
      "Network Technician",
      "Embedded Systems Developer",
      "Broadcast Technician",
      "RF Engineer",
      "Telecommunication Technician"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma4
  },
  {
    id: "diploma5",
    title: "DIPLOMA IN COMPUTER SCIENCE AND ENGINEERING",
    description:
        "is a vocational program that provides training in computer hardware, software development, and information technology. Students learn about programming languages, database management, and computer networks.",
    scope: [
      "Computer Technician",
      "IT Support Specialist",
      "Web Developer",
      "Network Administrator",
      "Software Tester",
      "System Analyst",
      "Cybersecurity Technician"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma5
  },
  {
    id: "diploma6",
    title: "DIPLOMA IN CIVIL CONSTRUCTION",
    description:
        "is a vocational program that provides training in construction management, building technology, and project supervision. Students learn about construction materials, structural design, and construction safety regulations.",
    scope: [
      "Construction Supervisor",
      "Building Inspector",
      "Construction Estimator",
      "Site Engineer",
      "Project Coordinator",
      "Quantity Surveyor",
      "Construction Foreman"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma6
  },
  {
    id: "diploma7",
    title: "DIPLOMA IN INTERIOR DESIGN",
    description:
        "is a vocational program that focuses on the study of interior spaces, aesthetics, and design principles. Students learn about space planning, furniture design, and interior decoration techniques.",
    scope: [
      "Interior Designer",
      "Furniture Designer",
      "Architectural Drafter",
      "Visual Merchandiser",
      "Set Designer",
      "Exhibition Designer",
      "Retail Space Planner"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma7
  },
  {
    id: "diploma8",
    title: "DIPLOMA IN HOTEL MANAGEMENT",
    description:
        "is a vocational program that provides training in hospitality operations, food service management, and hotel administration. Students learn about front office operations, housekeeping, and culinary arts.",
    scope: [
      "Hotel Manager",
      "Restaurant Manager",
      "Front Desk Supervisor",
      "Food and Beverage Manager",
      "Banquet Manager",
      "Executive Chef",
      "Event Coordinator"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma8
  },
  {
    id: "diploma9",
    title: "DIPLOMA IN FASHION DESIGNING",
    description:
        "is a vocational program that provides training in various aspects of fashion design, including garment construction, pattern making, and textile selection. Students learn about fashion trends, design principles, and fashion illustration techniques.",
    scope: [
      "Fashion Designer",
      "Fashion Stylist",
      "Apparel Merchandiser",
      "Fashion Illustrator",
      "Costume Designer",
      "Textile Designer",
      "Fashion Consultant"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma9
  },
  {
    id: "diploma10",
    title: "DIPLOMA IN AUTOMOBILE ENGINEERING",
    description:
        "is a vocational program that focuses on the study of automotive technology, vehicle design, and maintenance. Students learn about engine mechanics, vehicle dynamics, and automotive electronics.",
    scope: [
      "Automobile Technician",
      "Automotive Engineer",
      "Service Advisor",
      "Automotive Designer",
      "Diagnostics Technician",
      "Quality Control Inspector",
      "Fleet Manager"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma10
  }
];



const DiplomaDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const disciplineDetails = disciplines.find((discipline) => discipline.title === name);
  if (!disciplineDetails) {
    return <Typography variant="h5">Courses not found.</Typography>;
  }

  const { title, description, scope, salary } = disciplineDetails;

  const currentIndex = disciplines.findIndex((discipline) => discipline.title === name);
  const prevDiscipline = currentIndex > 0 ? disciplines[currentIndex - 1] : null;
  const nextDiscipline = currentIndex < disciplines.length - 1 ? disciplines[currentIndex + 1] : null;

  return (
    <Box sx={{ padding: "36px" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
            Courses
          </Link>
          <Link component={RouterLink} to="/courses/diploma" color={COLORS.MUTED}>
            Diploma
          </Link>
          <Typography color={COLORS.BACKGROUND_COLOR1} style={{fontWeight: "600"}}>{title}</Typography>
        </Breadcrumbs>
        <Box sx={{ display: { xs: "flex", md: "none" }, gap: "18px", marginTop: "10px"}}>
          {prevDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={RouterLink}
              to={`/courses/diploma/${prevDiscipline.title}`}
            >
              Prev
            </Button>
          )}
          {nextDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              size="small"
              endIcon={<ArrowForwardIcon />}
              component={RouterLink}
              to={`/courses/diploma/${nextDiscipline.title}`}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>

      <Card style={{ textAlign: "justify", height: "100%", marginTop: "20px", backgroundColor: COLORS.GREY_COLOR }}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Text content column */}
            <Grid item xs={12} sm={9} md={7} order={{ xs: 1, md: 1 }}>
              {/* Title and description */}
              <Typography variant="h6" gutterBottom style={{ marginTop: "10px",  fontFamily: COLORS.FONT,fontWeight: "600", textAlign: "left", color: COLORS.BACKGROUND_COLOR0 }}>
                {title}
              </Typography>
              <hr
                style={{
                  width: "15%",
                  backgroundColor: COLORS.BACKGROUND_COLOR1,
                  height: "4px",
                  border: "none",
                  margin: "5px 0",
                }}
              />
              <Typography variant="body1" paragraph style={{marginTop : "20px"}}>
                {title} {description}
              </Typography>


              {/* Scope */}
              <Typography variant="body1">
                <strong style={{color: COLORS.STRONG_COLOR}}>Scope:</strong>
              </Typography>
              <List dense>
                {scope.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon style={{ minWidth: "24px" }}>
                      <FiberManualRecordIcon style={{ fontSize: "13px" }} />
                    </ListItemIcon>
                    <ListItemText primary={item} disableTypography />
                  </ListItem>
                ))}
              </List>

              {/* Salary range */}
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                <strong style={{color: COLORS.STRONG_COLOR}}>Salary:</strong>{" "}
                <span style={{ padding: "3px 6px", borderRadius: "5px" }}>{salary}</span>
              </Typography>
            </Grid>

            {/* Image column */}
            <Grid item xs={12} sm={3} md={5} order={{ xs: 2, md: 2 }}>
              <Box sx={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "baseline", height: "100%", marginTop: { xs: "10px", md: "30px" } }}>
                <img src={disciplineDetails.imageSrc} alt={title} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BLACK,
                    padding: "10px 20px",
                    marginTop: "30px",
                    transition: "transform 0.3s ease",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100%"
                  }}
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1)";
                  }}
                  endIcon={<ArrowRightAltIcon style={{ backgroundColor: COLORS.BACKGROUND_COLOR2, color: COLORS.BLACK }} />}
                >
          Contact Us
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "space-between", marginTop: "20px" }}>
            {prevDiscipline && (
              <Button
                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                startIcon={<ArrowBackIcon />}
                component={RouterLink}
                to={`/courses/diploma/${prevDiscipline.title}`}
              >
                Previous Course
              </Button>
            )}
            {nextDiscipline && (
              <Button
                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                endIcon={<ArrowForwardIcon />}
                component={RouterLink}
                to={`/courses/diploma/${nextDiscipline.title}`}
              >
                Next Course
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DiplomaDetail;
