import React, { useState, useEffect, useRef} from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CardMedia,
  InputAdornment,
  TextField,Breadcrumbs, Link
} from "@mui/material";
import Aggriculture1 from "../../assets/courses/aggri.jpg";
import aggri1 from "../../assets/coursesDetail/agri/1.jpg";
import aggri2 from "../../assets/coursesDetail/agri/3.jpg";
import aggri3 from "../../assets/coursesDetail/agri/4.jpg";
import aggri4 from "../../assets/coursesDetail/agri/5.jpg";
import aggri5 from "../../assets/coursesDetail/agri/6.jpg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import COLORS from "../../ColorCodes/color";

const disciplines = [
  {
    id: 1,
    title: "B.Sc.(AGRICULTURE)",
    description:
        "is a professional degree program that prepares students for a career in agricultural science and technology. It covers areas such as agronomy, crop science, soil science, agriculture extension, and more.",
    scope: [
      "Agronomy",
      "Crop Science",
      "Soil Science",
      "Agricultural Extension",
      "Horticulture",
      "Agribusiness Management",
      "Sustainable Agriculture"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri1,
  },
  {
    id: 2,
    title: "DIPLOMA IN AGRICULTURE",
    description:
        "is a vocational course that provides practical training in various agricultural practices. It covers topics such as crop cultivation, pest management, farm machinery operation, and agricultural marketing",
    scope: [
      "Crop Cultivation Techniques",
      "Pest and Disease Management",
      "Farm Management",
      "Agricultural Marketing",
      "Organic Farming",
      "Irrigation Management",
      "Agricultural Extension Services"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri2,
  },
  {
    id: 3,
    title: "B.SC.(HORTICULTURE)",
    description:
        "is a specialized degree program focused on the science and art of cultivating fruits, vegetables, flowers, and ornamental plants. It includes topics such as plant propagation, nursery management, landscape design, and post-harvest management.",
    scope: [
      "Fruit and Vegetable Production",
      "Floriculture",
      "Landscape Architecture",
      "Nursery Management",
      "Plant Breeding",
      "Pest and Disease Control",
      "Post-harvest Technology"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri3,
  },
  {
    id: 4,
    title: "B.SC.(FORESTRY)",
    description:
        "is a degree program that deals with the conservation, management, and sustainable use of forest resources. It covers subjects like forest ecology, wildlife management, forest economics, and forest policy.",
    scope: [
      "Forest Ecology",
      "Wildlife Management",
      "Forest Economics",
      "Forest Policy",
      "Agroforestry",
      "Timber Management",
      "Environmental Conservation"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri4,
  },
  {
    id: 5,
    title: "B.SC.(FISHERY)",
    description:
        "is an undergraduate program that focuses on the science and management of fisheries and aquaculture. It covers topics such as fish biology, aquaculture techniques, fish nutrition, and fisheries management.",
    scope: [
      "Aquaculture Management",
      "Fish Health Management",
      "Fish Genetics and Breeding",
      "Fisheries Economics",
      "Fish Processing Technology",
      "Fisheries Extension",
      "Marine Resource Management"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri5,
  },
  {
    id: 6,
    title: "DIPLOMA IN HORTICULTURE",
    description:
        "is a vocational course that provides practical training in various aspects of horticulture. It covers topics such as nursery management, landscape gardening, flower arrangement, and greenhouse technology.",
    scope: [
      "Nursery Management Techniques",
      "Landscape Gardening",
      "Floriculture Practices",
      "Greenhouse Management",
      "Plant Propagation Methods",
      "Pest and Disease Control in Horticulture",
      "Ornamental Plant Cultivation"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri2,
  },
];

const Aggriculture = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDisciplines, setFilteredDisciplines] = useState(disciplines);

  useEffect(() => {
    const handleFiltering = () => {
      if (!searchTerm.trim()) {
        setFilteredDisciplines(disciplines);
        return;
      }
      const filtered = disciplines.filter(discipline =>
        discipline.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDisciplines(filtered);
    };
    handleFiltering();
  }, [searchTerm, disciplines]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCardClick = (index,name) => {
    setSelectedCard(selectedCard === index ? null : index);
    navigate(`/courses/agriculture/${name}`);
  };

  const CardComponent = () => {
    return (
      <div style={{ background: COLORS.GREY_COLOR, paddingLeft: "15px", paddingRight: "15px", paddingBottom: "22px" }}>
        {filteredDisciplines.length === 0 ? (
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            No courses found
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredDisciplines.map((discipline, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    cursor: "pointer",
                    transition: "box-shadow 0.3s ease-in-out",
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BACKGROUND_COLOR0,
                    "&:hover": {
                      boxShadow: 10,
                      color: COLORS.BACKGROUND_COLOR1
                    },
                  }}
                  onClick={() => handleCardClick(index, discipline.title)}
                >
                  <CardMedia
                    component="img"
                    image={discipline.imageSrc}
                    alt={discipline.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {discipline.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };

  return (
    <Box sx={{ padding: "36px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
          Courses
        </Link>
        <Link component={RouterLink} to="/courses/agriculture" style={{fontWeight: "600"}} color={COLORS.BACKGROUND_COLOR1}>
        Agriculture
        </Link>
      </Breadcrumbs>
      <div style={{  background: COLORS.GREY_COLOR, marginTop: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={titleRef}
            variant="h1"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginBottom: "10px",
              fontSize: "25px",
              textAlign: "center",
              background: COLORS.BACKGROUND_COLOR0,
              padding: "10px",
              color: COLORS.BACKGROUND_COLOR2,
              flexGrow: 1,
            }}
          >
           AGRICULTURE
          </Typography>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
          <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
          Agriculture courses encompass a range of educational programs focused on the principles, practices, and technologies related to farming, crop production, animal husbandry, and agribusiness. These courses aim to provide students with a comprehensive understanding of agriculture, including sustainable farming practices, soil management, crop science, animal science, and the broader agri-food industry.
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: "10px", textAlign: "justify" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph >
                <strong style={{color:COLORS.STRONG_COLOR}}>Sustainable Expertise:</strong> Agriculture courses provide specialized knowledge in sustainable farming practices, enabling individuals to contribute to environmentally conscious and efficient agricultural systems.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Diverse Career Paths:</strong> Pursuing agriculture courses opens up diverse career opportunities, from agronomy to agribusiness, allowing graduates to play key roles in the agri-food industry and address global food security challenges.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Global Impact:</strong> Agriculture courses empower individuals to address worldwide food security challenges, equipping them with the skills to enhance crop production, resource management, and contribute to sustainable solutions on a global scale.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Global Mobility and Collaboration:</strong> Engineering is a globally recognized profession, and many engineering projects require collaboration on an international scale. Choosing engineering opens up opportunities for professionals to work on diverse projects and engage with a global network of colleagues and experts.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Financial Reward:</strong> Engineering professions often come with competitive salaries. The combination of specialized skills, high demand, and the level of responsibility involved in engineering roles can lead to financial rewards, providing a stable and potentially lucrative career.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <img src={Aggriculture1} alt="Aggriculture" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: "10px", paddingRight:"10px"}}>
          <h2 style={{color: COLORS.BACKGROUND_COLOR0}}>COURSES OFFERED ({disciplines.length} courses)</h2>
          <TextField
            placeholder="Search courses..."
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box sx={{ mt: 4 }}>
          <CardComponent />
        </Box>
      </div>
    </Box>
  );
};

export default Aggriculture;
