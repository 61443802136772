const COLORS = {
  PRIMARY: "#007BFF",
  SECONDARY: "#6C757D",
  SUCCESS: "#28A745",
  DANGER: "#DC3545",
  WARNING: "#FFC107",
  INFO: "#17A2B8",
  LIGHT: "#F8F9FA",
  DARK: "#343A40",
  MUTED: "#6C757D",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  GRAY: "#6C757D",
  TRANSPARENT: "transparent",


  BACKGROUND_COLOR0: "#00468B ", //cyan
  BACKGROUND_COLOR2: "#D4F0F7 ", //teal
  BACKGROUND_COLOR1: "#2ECBE9", //orange
  GREY_COLOR: "#eceff1",
  STRONG_COLOR: "#2072B2",
  FONT: "Raleway",
  LIGHTER_TEAL: "#B0DBF1",
  FOOTER_TEXT_COLOR: "#e5fcff",
  FOOTER_HOVER_COLOR: "#81D4FA"
};

export default COLORS;

