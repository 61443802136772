import React, { useState, useEffect } from "react";
import image1 from "../../assets/Banner.jpg";
import imageMobile from "../../assets/bannerMobile.jpg";

const handleDragStart = (e) => e.preventDefault();

const BannerImage = () => {
  const [imageSrc, setImageSrc] = useState(image1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setImageSrc(imageMobile);
      } else {
        setImageSrc(image1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const bannerStyle = {
    width: "100%",
    height: "auto",
    maxHeight: "600px",
    overflow: "hidden",
    position: "relative",
  };

  const imgStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <div style={{ margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div style={bannerStyle}>
        <img src={imageSrc} alt="Banner" onDragStart={handleDragStart} style={imgStyle} />
      </div>
    </div>
  );
};

export default BannerImage;
