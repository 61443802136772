import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/logo.svg";
import AppHeader from "./AppHeader";
import { Grid } from "@mui/material";
import CareerGuidanceModal from "./CareerGuidanceModal";
import CreateIcon from "@mui/icons-material/Create";
import { useMediaQuery, useTheme } from "@mui/material";
import {jwtDecode} from "jwt-decode";
import { SessionContext } from "../screens/SessionContext";
import COLORS from "../ColorCodes/color";


const importAll = (r) => r.keys().map(r);
const questions = importAll(require.context("../assets/question", false, /\.(pdf|jpe?g|svg)$/));
console.log(questions, "ques");

function ResponsiveAppBar() {
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { isSessionExpired, setIsSessionExpired } = React.useContext(SessionContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleRightDrawerOpen = () => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      const expiryTime = decodedToken.exp * 1000;
      const currentTime = Date.now();

      if (expiryTime > currentTime) {
        navigate("/questionBank");
      } else {
        setIsSessionExpired(true);
        localStorage.removeItem("jwtToken");
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const drawer = (
    <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
      <List>
        <ListItem button component={Link} to="/" selected={isActive("/")}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/about" selected={isActive("/about")}>
          <ListItemText primary="About Us" />
        </ListItem>
        <ListItem button component={Link} to="/courses" selected={isActive("/courses")}>
          <ListItemText primary="Courses" />
        </ListItem>
        <ListItem button component={Link} to="/entrance-exams" selected={isActive("/entrance-exams")}>
          <ListItemText primary="Entrance Exams" />
        </ListItem>
        <ListItem button component={Link} to="/gallery" selected={isActive("/gallery")}>
          <ListItemText primary="Gallery" />
        </ListItem>
        <ListItem button component={Link} to="/contact" selected={isActive("/contact")}>
          <ListItemText primary="Contact Us" />
        </ListItem>
        <ListItem button onClick={handleRightDrawerOpen} selected={isActive("/questionBank")}>
          <ListItemText primary="Question Bank" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <AppHeader />
      <AppBar position="sticky" style={{ background: COLORS.BACKGROUND_COLOR2, paddingLeft: "10px", paddingRight: "10px" }}>
        <Toolbar disableGutters>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <img src={logo} alt="" style={{ width: 150, height: 60}} />
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, marginLeft: 2 }}>
              <Grid container justifyContent="flex-start" spacing={1}>
                <Grid item>
                  <Tooltip title="Home">
                    <IconButton
                      size="small"
                      component={Link}
                      to="/"
                      sx={{
                        color: COLORS.BLACK,
                        padding: "6px",
                        borderRadius: "16px",
                        fontWeight: "600",
                        "&:hover": { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE },
                        ...(isActive("/") && { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE }),
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>Home</span>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="About Us">
                    <IconButton
                      size="small"
                      component={Link}
                      to="/about"
                      sx={{
                        color: COLORS.BLACK,
                        padding: "6px",
                        borderRadius: "16px",
                        fontWeight: "600",
                        "&:hover": { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE },
                        ...(isActive("/about") && { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE }),
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>About Us</span>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Courses">
                    <IconButton
                      size="small"
                      component={Link}
                      to="/courses"
                      sx={{
                        color: COLORS.BLACK,
                        borderRadius: "16px",
                        fontWeight: "600",
                        padding: "6px",
                        "&:hover": { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE },
                        ...(isActive("/courses") && { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE }),
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>Courses</span>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Entrance Exams">
                    <IconButton
                      size="small"
                      component={Link}
                      to="/entrance-exams"
                      sx={{
                        color: COLORS.BLACK,
                        borderRadius: "16px",
                        padding: "6px",
                        fontWeight: "600",
                        "&:hover": { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE },
                        ...(isActive("/entrance-exams") && { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE }),
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>Entrance Exams</span>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Gallery">
                    <IconButton
                      size="small"
                      component={Link}
                      to="/gallery"
                      sx={{
                        color: COLORS.BLACK,
                        borderRadius: "16px",
                        padding: "6px",
                        fontWeight: "600",
                        "&:hover": { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE },
                        ...(isActive("/gallery") && { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE }),
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>Gallery</span>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Contact Us">
                    <IconButton
                      size="small"
                      component={Link}
                      to="/contact"
                      sx={{
                        color: COLORS.BLACK,
                        borderRadius: "16px",
                        padding: "6px",
                        fontWeight: "600",
                        "&:hover": { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE },
                        ...(isActive("/contact") && { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE }),
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>Contact Us</span>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Question Bank">
                    <IconButton
                      size="small"
                      component={Link}
                      to={isSessionExpired ? "/login" : "/questionBank"}
                      sx={{
                        color: COLORS.BLACK,
                        borderRadius: "16px",
                        padding: "6px",
                        fontWeight: "600",
                        "&:hover": { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE },
                        ...(isActive(isSessionExpired ? "/login" : "/questionBank") && { bgcolor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE }),
                      }}
                      onClick={handleRightDrawerOpen}
                    >
                      <span style={{ fontSize: "16px" }}>Question Bank</span>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "flex" } }}>
            {isMobile || isTab ? (
              <Tooltip title="Register">
                <CreateIcon
                  onClick={handleClickOpen}
                  style={{
                    background: COLORS.BACKGROUND_COLOR0,
                    cursor: "pointer",
                    padding: "5px",
                    fontSize: "39px",
                    borderRadius: "16px",
                    color: COLORS.WHITE
                  }}
                />
              </Tooltip>
            ) : (
              <Button
                startIcon={<CreateIcon style={{ padding: "3px", fontSize: "23px" }} />}
                style={{
                  background: COLORS.BACKGROUND_COLOR0,
                  color: COLORS.WHITE,
                  marginRight: "10px",
                  fontSize: "13px",
                  borderRadius: "10px"
                }}
                onClick={handleClickOpen}
              >
                Register
              </Button>
            )}
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            size="large"
            onClick={handleDrawerToggle}
            sx={{ color: COLORS.BLACK, display: { xs: "block", md: "none" }, marginLeft: "14px" }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: {
            marginTop: "105px",
            height: "calc(100% - 105px)",
            transform: "translateY(105px)",
          },
        }}
      >
        <Box sx={{ width: 250 }} onClick={(e) => e.stopPropagation()}>
          {drawer}
        </Box>
      </Drawer>
      <CareerGuidanceModal open={open} setOpen={setOpen} />
    </div>
  );
}

export default ResponsiveAppBar;
