import React, { useState, useEffect, useRef} from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CardMedia,
  InputAdornment,
  TextField,Breadcrumbs, Link
} from "@mui/material";
import nurse from "../../assets/courses/nurse.jpg";
import nurse1 from "../../assets/coursesDetail/Nursing/2.jpg";
import nurse2 from "../../assets/coursesDetail/Nursing/3.jpg";
import nurse3 from "../../assets/coursesDetail/Nursing/4.jpg";
import nurse4 from "../../assets/coursesDetail/Nursing/5.jpg";
import nurse5 from "../../assets/coursesDetail/Nursing/6.jpg";
import nurse6 from "../../assets/coursesDetail/Nursing/7.jpg";
import nurse7 from "../../assets/coursesDetail/Nursing/8.jpg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import COLORS from "../../ColorCodes/color";

const disciplines = [
  {
    id: "nurse1",
    title: "BSC. (NURSING)",
    description:
        "is an undergraduate degree program that focuses on the study of nursing principles, healthcare practices, and patient care. Students learn about anatomy, physiology, pharmacology, and nursing ethics.",
    scope: [
      "Registered Nurse",
      "Nurse Practitioner",
      "Nurse Educator",
      "Nurse Manager",
      "Critical Care Nurse",
      "Pediatric Nurse",
      "Public Health Nurse"
    ],
    salary: "₹3,00,000 - ₹6,00,000 per year",
    imageSrc: nurse1
  },
  {
    id: "nurse2",
    title: "ANM (AUXILIARY NURSE MIDWIFERY)",
    description:
        "is a diploma program that focuses on basic nursing skills, maternal and child healthcare, and community nursing. Students learn about primary healthcare, maternal care, and preventive healthcare practices.",
    scope: [
      "Auxiliary Nurse Midwife",
      "Community Health Worker",
      "Maternity Nurse",
      "Health Educator",
      "Family Planning Counselor",
      "Rural Healthcare Worker",
      "Immunization Coordinator"
    ],
    salary: "₹2,00,000 - ₹4,00,000 per year",
    imageSrc: nurse2
  },
  {
    id: "nurse3",
    title: "DIPLOMA NURSING",
    description:
        "is a program that focuses on the study of nursing principles, patient care, and healthcare practices. Students learn about medical-surgical nursing, pediatric nursing, and psychiatric nursing.",
    scope: [
      "Licensed Practical Nurse",
      "Nursing Assistant",
      "Medical-Surgical Nurse",
      "Psychiatric Nurse",
      "Pediatric Nurse",
      "Geriatric Nurse",
      "Home Health Nurse"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: nurse3
  },
  {
    id: "nurse4",
    title: "GNM (GENERAL NURSING AND MIDWIFERY)",
    description:
        "is a diploma program that focuses on the study of nursing principles, healthcare practices, and midwifery skills. Students learn about medical-surgical nursing, obstetrics, and gynecology.",
    scope: [
      "Staff Nurse",
      "Midwife",
      "Community Health Nurse",
      "Nursing Supervisor",
      "Critical Care Nurse",
      "Operation Theatre Nurse",
      "Infection Control Nurse"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: nurse4
  },
  {
    id: "nurse5",
    title: "B.PHARM",
    description:
        "or Bachelor of Pharmacy, is an undergraduate degree program focused on pharmaceutical science and medication management. Students study drug composition, development, and its effects on the human body, preparing them for roles in pharmacy practice, research, and the pharmaceutical industry. The curriculum typically includes coursework in pharmacology, medicinal chemistry, pharmaceutics, and pharmacy practice.",
    scope: [
      "Pharmacist Assistant",
      "Pharmaceutical Sales Representative",
      "Clinical Research Associate",
      "Healthcare Consultant",
      "Drug Safety Associate",
      "Medical Writer",
      "Pharmacy Manager"
    ],
    salary: "₹3,00,000 - ₹5,00,000 per year",
    imageSrc: nurse5
  },
  {
    id: "nurse6",
    title: "D.PHARM",
    description:
        "(Diploma in Pharmacy) is a diploma program that focuses on the study of pharmaceutical sciences, drug dispensing, and pharmacy practice. Students learn about pharmacy nursing-pharmacys, drug dosage forms, and drug interactions.",
    scope: [
      "Pharmacist Assistant",
      "Pharmacy Technician",
      "Hospital Pharmacy Assistant",
      "Retail Pharmacy Assistant",
      "Pharmaceutical Sales Assistant",
      "Drug Safety Assistant",
      "Pharmacy Manager"
    ],
    salary: "₹2,00,000 - ₹4,00,000 per year",
    imageSrc: nurse6
  },
  {
    id: "nurse7",
    title: "PHARM D",
    description:
        "(Doctor of Pharmacy) is a professional doctoral degree program that focuses on advanced pharmacy practice and patient care. Students learn about clinical pharmacy, pharmacotherapy, and patient counseling.",
    scope: [
      "Clinical Pharmacist",
      "Pharmacist Consultant",
      "Hospital Pharmacist",
      "Clinical Research Pharmacist",
      "Pharmacovigilance Officer",
      "Drug Information Specialist",
      "Pharmaceutical Care Specialist"
    ],
    salary: "₹4,00,000 - ₹8,00,000 per year",
    imageSrc: nurse7
  },
];

const Nursing = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDisciplines, setFilteredDisciplines] = useState(disciplines);

  useEffect(() => {
    const handleFiltering = () => {
      if (!searchTerm.trim()) {
        setFilteredDisciplines(disciplines);
        return;
      }
      const filtered = disciplines.filter(discipline =>
        discipline.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDisciplines(filtered);
    };
    handleFiltering();
  }, [searchTerm, disciplines]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCardClick = (index,name) => {
    setSelectedCard(selectedCard === index ? null : index);
    navigate(`/courses/nursing-pharmacy/${name}`);
  };

  const CardComponent = () => {
    return (
      <div style={{ background: COLORS.GREY_COLOR, paddingLeft: "15px", paddingRight: "15px", paddingBottom: "22px" }}>
        {filteredDisciplines.length === 0 ? (
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            No courses found
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredDisciplines.map((discipline, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    cursor: "pointer",
                    transition: "box-shadow 0.3s ease-in-out",
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BACKGROUND_COLOR0,
                    "&:hover": {
                      boxShadow: 10,
                      color: COLORS.BACKGROUND_COLOR1
                    },
                  }}
                  onClick={() => handleCardClick(index, discipline.title)}
                >
                  <CardMedia
                    component="img"
                    image={discipline.imageSrc}
                    alt={discipline.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {discipline.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };

  return (
    <Box sx={{ padding: "36px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
          Courses
        </Link>
        <Link component={RouterLink} to="/courses/nursing-pharmacy"style={{fontWeight: "600"}} color={COLORS.BACKGROUND_COLOR1}>
        Nursing & Pharmacy
        </Link>
      </Breadcrumbs>
      <div style={{  background: COLORS.GREY_COLOR, marginTop: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={titleRef}
            variant="h1"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginBottom: "10px",
              fontSize: "25px",
              textAlign: "center",
              background: COLORS.BACKGROUND_COLOR0,
              padding: "10px",
              color: COLORS.BACKGROUND_COLOR2,
              flexGrow: 1,
            }}
          >
           Nursing and Pharmacy
          </Typography>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
          <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
          Nursing courses and pharmacy courses are educational programs that equip individuals for vital roles in healthcare. Nursing courses focus on patient care, health promotion, and ethical nursing practices, while pharmacy courses emphasize pharmaceutical sciences, drug therapy, and the safe dispensing of medications. Together, these programs prepare healthcare professionals to collaborate in providing comprehensive patient care, with nurses attending to direct patient needs and pharmacists ensuring the safe and effective use of medications. The combination of nursing and pharmacy education contributes to a well-rounded healthcare team, addressing diverse aspects of patient well-being.
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: "10px", textAlign: "justify" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Comprehensive Patient Care:</strong> The combination of nursing and pharmacy courses equips individuals to provide holistic and comprehensive patient care. Nurses focus on direct patient needs, while pharmacists ensure the safe and effective use of medications, contributing to well-rounded healthcare delivery.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Enhanced Interprofessional Collaboration:</strong> Graduates from combined nursing and pharmacy courses possess a deep understanding of each other&apos;s roles, fostering effective interprofessional collaboration. This collaboration is crucial in healthcare settings to optimize patient outcomes and safety.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Diverse Career Opportunities:</strong> Individuals with training in both nursing and pharmacy have a diverse set of career opportunities. They can pursue roles in hospitals, clinics, community pharmacies, long-term care facilities, research institutions, and other healthcare settings, offering versatility in their professional paths.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Improved Medication Management:</strong> Having knowledge in both nursing and pharmacy allows professionals to better manage medication-related aspects of patient care. This includes administering medications safely, monitoring patient responses, and educating patients on medication adherence and potential side effects.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Increased Professional Flexibility:</strong> Professionals with a background in both nursing and pharmacy have increased flexibility to adapt to evolving healthcare needs. They can take on roles that require a combination of clinical skills and pharmacological expertise, positioning themselves as valuable assets in healthcare teams.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <img src={nurse} alt="nurse" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: "10px", paddingRight:"10px"}}>
          <h2 style={{color: COLORS.BACKGROUND_COLOR0}}>COURSES OFFERED ({disciplines.length} courses)</h2>
          <TextField
            placeholder="Search courses..."
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box sx={{ mt: 4 }}>
          <CardComponent />
        </Box>
      </div>
    </Box>
  );
};

export default Nursing;
