import React, { useState, useEffect, useRef} from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CardMedia,
  InputAdornment,
  TextField,Breadcrumbs, Link
} from "@mui/material";
import medical from "../../assets/courses/medical.avif";
import medical1 from "../../assets/coursesDetail/Medical/2.jpg";
import medical2 from "../../assets/coursesDetail/Medical/3.jpg";
import medical3 from "../../assets/coursesDetail/Medical/4.jpg";
import medical4 from "../../assets/coursesDetail/Medical/5.jpg";
import medical5 from "../../assets/coursesDetail/Medical/6.jpg";
import medical6 from "../../assets/coursesDetail/Medical/7.jpg";
import medical7 from "../../assets/coursesDetail/Medical/8.jpg";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import COLORS from "../../ColorCodes/color";


const disciplines = [
  {
    id: "medical1",
    title: "MBBS (INDIA & ABROAD)",
    description: "Bachelor of Medicine, Bachelor of Surgery is an undergraduate medical degree program that prepares students to become medical doctors. The program covers various medical subjects, clinical rotations, and practical training.",
    scope: [
      "General Physician",
      "Surgeon",
      "Specialist Doctor",
      "Medical Officer",
      "Emergency Physician",
      "Anesthesiologist",
      "Pediatrician"
    ],
    salary: "₹8,00,000 - ₹20,00,000 per year",
    imageSrc: medical1
  },
  {
    id: "medical2",
    title: "BDS",
    description: "(Bachelor of Dental Surgery) is an undergraduate dental degree program that focuses on the study of dental anatomy, oral diseases, and dental treatments. Students learn about dental procedures, oral surgery, and preventive dentistry.",
    scope: [
      "Dentist",
      "Oral Surgeon",
      "Orthodontist",
      "Periodontist",
      "Endodontist",
      "Prosthodontist",
      "Pediatric Dentist"
    ],
    salary: "₹5,00,000 - ₹12,00,000 per year",
    imageSrc: medical2
  },
  {
    id: "medical3",
    title: "BSMS (SIDDHA)",
    description: "(Bachelor of Siddha Medicine and Surgery) is an undergraduate degree program in Siddha medicine, a traditional Indian system of medicine. Students learn about Siddha principles, herbal medicine, and holistic healthcare practices.",
    scope: [
      "Siddha Practitioner",
      "Siddha Researcher",
      "Siddha Consultant",
      "Siddha Pharmacist",
      "Siddha Hospital Administrator",
      "Siddha Medicine Manufacturing",
      "Siddha Clinical Trials Coordinator"
    ],
    salary: "₹3,00,000 - ₹8,00,000 per year",
    imageSrc: medical3
  },
  {
    id: "medical4",
    title: "BHMS (HOMEOPATHY)",
    description: "(Bachelor of Homeopathic Medicine and Surgery) is an undergraduate degree program in homeopathic medicine. Students learn about homeopathic principles, remedies, and treatment modalities.",
    scope: [
      "Homeopathic Doctor",
      "Homeopathic Consultant",
      "Homeopathic Researcher",
      "Homeopathic Pharmacist",
      "Homeopathic Hospital Administrator",
      "Homeopathic Lecturer",
      "Homeopathic Product Developer"
    ],
    salary: "₹3,00,000 - ₹8,00,000 per year",
    imageSrc: medical4
  },
  {
    id: "medical5",
    title: "BAMS (AYURVEDIC)",
    description: "(Bachelor of Ayurvedic Medicine and Surgery) is an undergraduate degree program in Ayurvedic medicine. Students learn about Ayurvedic principles, herbal medicine, and holistic healthcare practices.",
    scope: [
      "Ayurvedic Doctor",
      "Ayurvedic Consultant",
      "Ayurvedic Researcher",
      "Ayurvedic Pharmacist",
      "Ayurvedic Hospital Administrator",
      "Ayurvedic Lecturer",
      "Ayurvedic Product Developer"
    ],
    salary: "₹3,00,000 - ₹8,00,000 per year",
    imageSrc: medical5
  },
  {
    id: "medical6",
    title: "BUMS (UNANI)",
    description: "(Bachelor of Unani Medicine and Surgery) is an undergraduate degree program in Unani medicine, a traditional Indian system of medicine. Students learn about Unani principles, herbal medicine, and holistic healthcare practices.",
    scope: [
      "Unani Doctor",
      "Unani Consultant",
      "Unani Researcher",
      "Unani Pharmacist",
      "Unani Hospital Administrator",
      "Unani Lecturer",
      "Unani Product Developer"
    ],
    salary: "₹3,00,000 - ₹8,00,000 per year",
    imageSrc: medical6
  },
  {
    id: "medical7",
    title: "BPT",
    description: "(Bachelor of Physiotherapy) is an undergraduate degree program that focuses on the study of physical therapy and rehabilitation techniques. Students learn about human anatomy, exercise physiology, and therapeutic modalities.",
    scope: [
      "Physiotherapist",
      "Sports Physiotherapist",
      "Rehabilitation Specialist",
      "Orthopedic Physiotherapist",
      "Neurological Physiotherapist",
      "Cardiopulmonary Physiotherapist",
      "Pediatric Physiotherapist"
    ],
    salary: "₹3,50,000 - ₹7,50,000 per year",
    imageSrc: medical7
  },
];

const Medical = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDisciplines, setFilteredDisciplines] = useState(disciplines);

  useEffect(() => {
    const handleFiltering = () => {
      if (!searchTerm.trim()) {
        setFilteredDisciplines(disciplines);
        return;
      }
      const filtered = disciplines.filter(discipline =>
        discipline.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDisciplines(filtered);
    };
    handleFiltering();
  }, [searchTerm, disciplines]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCardClick = (index,name) => {
    setSelectedCard(selectedCard === index ? null : index);
    navigate(`/courses/medical/${name}`);
  };

  const CardComponent = () => {
    return (
      <div style={{ background: COLORS.GREY_COLOR, paddingLeft: "15px", paddingRight: "15px", paddingBottom: "22px" }}>
        {filteredDisciplines.length === 0 ? (
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            No courses found
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredDisciplines.map((discipline, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    cursor: "pointer",
                    transition: "box-shadow 0.3s ease-in-out",
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BACKGROUND_COLOR0,
                    "&:hover": {
                      boxShadow: 10,
                      color: COLORS.BACKGROUND_COLOR1
                    },
                  }}
                  onClick={() => handleCardClick(index, discipline.title)}
                >
                  <CardMedia
                    component="img"
                    image={discipline.imageSrc}
                    alt={discipline.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {discipline.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };

  return (
    <Box sx={{ padding: "36px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
          Courses
        </Link>
        <Link component={RouterLink} to="/courses/medical" style={{fontWeight: "600"}} color={COLORS.BACKGROUND_COLOR1}>
        Medical
        </Link>
      </Breadcrumbs>
      <div style={{  background: COLORS.GREY_COLOR, marginTop: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={titleRef}
            variant="h1"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginBottom: "10px",
              fontSize: "25px",
              textAlign: "center",
              background: COLORS.BACKGROUND_COLOR0,
              padding: "10px",
              color: COLORS.BACKGROUND_COLOR2,
              flexGrow: 1,
            }}
          >
           MEDICAL
          </Typography>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
          <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
          Medical courses refer to educational programs that focus on the study of medicine, healthcare, and the sciences related to the prevention, diagnosis, and treatment of diseases. These courses prepare individuals for careers in the medical field, covering a wide range of disciplines such as medicine, nursing, dentistry, pharmacy, physiotherapy, and allied health professions. Medical education includes both theoretical knowledge and practical training, often involving clinical rotations, internships, and hands-on experiences to ensure that graduates are well-equipped to contribute to patient care, medical research, and public health.
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: "10px", textAlign: "justify" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Healthcare Professional Development:</strong> Medical courses provide a pathway for individuals to become healthcare professionals, such as doctors, nurses, dentists, pharmacists, and allied health practitioners. Graduates are equipped with the skills and knowledge to contribute to patient care and well-being.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Diverse Career Opportunities:</strong> The medical field offers a wide range of career opportunities in various specialties and healthcare settings. Graduates can choose to work in hospitals, clinics, research institutions, public health organizations, or pursue private practice, allowing for versatility in career paths.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Impact on Public Health:</strong> Medical courses empower individuals to make a positive impact on public health. Healthcare professionals play a vital role in preventing, diagnosing, and treating diseases, contributing to the overall well-being of individuals and communities.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Continuous Learning and Innovation:</strong> The field of medicine is dynamic and constantly evolving. Medical courses emphasize lifelong learning and staying abreast of advancements in healthcare. Graduates have the opportunity to engage in continuous professional development and contribute to medical research and innovation.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Global Demand and Job Security:</strong> There is a global demand for qualified healthcare professionals. Pursuing medical courses provides a high level of job security, as healthcare services are essential and in constant need. This demand ensures favorable career prospects for graduates in various regions of the world.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <img src={medical} alt="medical" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: "10px", paddingRight:"10px"}}>
          <h2 style={{color: COLORS.BACKGROUND_COLOR0}}>COURSES OFFERED ({disciplines.length} courses)</h2>
          <TextField
            placeholder="Search courses..."
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box sx={{ mt: 4 }}>
          <CardComponent />
        </Box>
      </div>
    </Box>
  );
};

export default Medical;
