import React, { useState } from "react";
import { Button, TextField, Typography, Alert, Box, InputAdornment, IconButton } from "@mui/material";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBack from "@mui/icons-material/ArrowBack";
import LoginQuestion from "../LoginQuestion";

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Invalid mobile number"),
  newPassword: Yup.string()
    .required("New Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const ForgotPassword = ({ setOpen, setShowForgotPassword }) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const initialValues = {
    mobileNumber: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleResetPassword = async (values) => {
    try {
      const response = await axios.post("http://localhost:5000/api/forgot-password", values);
      setMessage(response.data.message);
      setError("");
      setResetSuccess(true);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message || "An error occurred while resetting the password.");
      } else {
        setError("Error: Unable to process your request.");
      }
      setMessage("");
    }
  };

  if (redirectToLogin) {
    return <LoginQuestion setOpen={setOpen} />;
  }

  const handleNumberChange = (e, handleChange) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      handleChange(e);
    }
  };

  return (
    <Box>
      {!resetSuccess ? (
        <>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton onClick={() => {setShowForgotPassword(false); setRedirectToLogin(true)}}>
              <ArrowBack />
            </IconButton>
            <Typography sx={{ ml: 1, color: "#162d78", fontWeight: "600", fontSize: "17px" }}>
              Reset Password
            </Typography>
          </Box>
          {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleResetPassword}
          >
            {({ errors, touched, handleChange }) => (
              <Form style={{ width: "100%" }}>
                <Field
                  as={TextField}
                  fullWidth
                  id="mobileNumber"
                  name="mobileNumber"
                  label="Mobile Number"
                  autoComplete="tel"
                  style={{ marginBottom: "10px" }}
                  error={errors.mobileNumber && touched.mobileNumber}
                  helperText={errors.mobileNumber && touched.mobileNumber ? errors.mobileNumber : ""}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onChange={(e) => handleNumberChange(e, handleChange,setError(""))}
                />
                <Field
                  as={TextField}
                  fullWidth
                  id="newPassword"
                  name="newPassword"
                  label="New Password"
                  type={showNewPassword ? "text" : "password"}
                  autoComplete="new-password"
                  style={{ marginBottom: "10px" }}
                  error={errors.newPassword && touched.newPassword}
                  helperText={errors.newPassword && touched.newPassword ? errors.newPassword : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    setError("");
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm New Password"
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="new-password"
                  style={{ marginBottom: "10px" }}
                  error={errors.confirmPassword && touched.confirmPassword}
                  helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    setError("");
                  }}
                />
                <Button fullWidth variant="contained" sx={{ mt: 2, mb: 2, backgroundColor: "#202c45", "&:hover": {
                  backgroundColor: "#202c45",
                }}} type="submit">
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <Alert severity="success" sx={{ mb: 2 }}>Password has been reset successfully.</Alert>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => setRedirectToLogin(true)}
          >
            Back to Login
          </Button>
        </>
      )}
    </Box>
  );
};

export default ForgotPassword;
