import React, { useEffect } from "react";
import { Typography, Grid, Avatar } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import ContactForm from "./contactUs/ContactForm";
import contactImg from "../assets/contactus.png";
import COLORS from "../ColorCodes/color";

const ContactUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ padding: "17px" }}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={contactImg}
            alt="Contact"
            style={{ width: 100, height: 100, marginRight: 20 }}
          />
          <div style={{ textAlign: "left" }}>
            <Typography
              variant="h2"
              sx={{
                textTransform: "uppercase",
                fontWeight: "600",
                marginTop: "20px",
                fontSize: "23px",
              }}
            >
              Intersted in Discussing ?
            </Typography>
            <hr
              style={{
                width: "50%",
                backgroundColor: COLORS.BACKGROUND_COLOR1,
                height: "4px",
                border: "none",
                margin: "10px 0",
              }}
            />

          </div>
        </Grid>
      </Grid>
      <ContactForm isMobile={isMobile} isTab={isTab} />
    </div>
  );
};

export default ContactUs;
