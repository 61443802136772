import React, { createContext, useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode";

const SessionContext = createContext();

const SessionProvider = ({ children }) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isAdminSessionExpired, setIsAdminSessionExpired] = useState(false);

  useEffect(() => {
    const checkSession = () => {
      const userToken = localStorage.getItem("jwtToken");
      const adminToken = localStorage.getItem("adminToken");
      const currentTime = Date.now() / 1000;

      if (userToken) {
        try {
          const decodedUserToken = jwtDecode(userToken);
          if (decodedUserToken.exp < currentTime) {
            setIsSessionExpired(true);
            localStorage.removeItem("jwtToken");
          } else {
            setIsSessionExpired(false);
          }
        } catch (error) {
          setIsSessionExpired(true);
          localStorage.removeItem("jwtToken");
        }
      } else {
        setIsSessionExpired(true);
      }

      if (adminToken) {
        try {
          const decodedAdminToken = jwtDecode(adminToken);
          if (decodedAdminToken.exp < currentTime) {
            setIsAdminSessionExpired(true);
            localStorage.removeItem("adminToken");
          } else {
            setIsAdminSessionExpired(false);
          }
        } catch (error) {
          setIsAdminSessionExpired(true);
          localStorage.removeItem("adminToken");
        }
      } else {
        setIsAdminSessionExpired(true);
      }
    };

    checkSession();

    checkSession();
    const intervalId = setInterval(checkSession, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <SessionContext.Provider value={{ isSessionExpired, isAdminSessionExpired, setIsSessionExpired, setIsAdminSessionExpired }}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
