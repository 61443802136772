import React, { useEffect } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography, Card, CardContent, Grid, List, ListItem, ListItemText, ListItemIcon, Button } from "@mui/material";
import { FiberManualRecord as FiberManualRecordIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import medical1 from "../../assets/coursesDetail/Medical/2.jpg";
import medical2 from "../../assets/coursesDetail/Medical/3.jpg";
import medical3 from "../../assets/coursesDetail/Medical/4.jpg";
import medical4 from "../../assets/coursesDetail/Medical/5.jpg";
import medical5 from "../../assets/coursesDetail/Medical/6.jpg";
import medical6 from "../../assets/coursesDetail/Medical/7.jpg";
import medical7 from "../../assets/coursesDetail/Medical/8.jpg";
import COLORS from "../../ColorCodes/color";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const disciplines = [
  {
    id: "medical1",
    title: "MBBS (INDIA & ABROAD)",
    description: "Bachelor of Medicine, Bachelor of Surgery is an undergraduate medical degree program that prepares students to become medical doctors. The program covers various medical subjects, clinical rotations, and practical training.",
    scope: [
      "General Physician",
      "Surgeon",
      "Specialist Doctor",
      "Medical Officer",
      "Emergency Physician",
      "Anesthesiologist",
      "Pediatrician"
    ],
    salary: "₹8,00,000 - ₹20,00,000 per year",
    imageSrc: medical1
  },
  {
    id: "medical2",
    title: "BDS",
    description: "(Bachelor of Dental Surgery) is an undergraduate dental degree program that focuses on the study of dental anatomy, oral diseases, and dental treatments. Students learn about dental procedures, oral surgery, and preventive dentistry.",
    scope: [
      "Dentist",
      "Oral Surgeon",
      "Orthodontist",
      "Periodontist",
      "Endodontist",
      "Prosthodontist",
      "Pediatric Dentist"
    ],
    salary: "₹5,00,000 - ₹12,00,000 per year",
    imageSrc: medical2
  },
  {
    id: "medical3",
    title: "BSMS (SIDDHA)",
    description: "(Bachelor of Siddha Medicine and Surgery) is an undergraduate degree program in Siddha medicine, a traditional Indian system of medicine. Students learn about Siddha principles, herbal medicine, and holistic healthcare practices.",
    scope: [
      "Siddha Practitioner",
      "Siddha Researcher",
      "Siddha Consultant",
      "Siddha Pharmacist",
      "Siddha Hospital Administrator",
      "Siddha Medicine Manufacturing",
      "Siddha Clinical Trials Coordinator"
    ],
    salary: "₹3,00,000 - ₹8,00,000 per year",
    imageSrc: medical3
  },
  {
    id: "medical4",
    title: "BHMS (HOMEOPATHY)",
    description: "(Bachelor of Homeopathic Medicine and Surgery) is an undergraduate degree program in homeopathic medicine. Students learn about homeopathic principles, remedies, and treatment modalities.",
    scope: [
      "Homeopathic Doctor",
      "Homeopathic Consultant",
      "Homeopathic Researcher",
      "Homeopathic Pharmacist",
      "Homeopathic Hospital Administrator",
      "Homeopathic Lecturer",
      "Homeopathic Product Developer"
    ],
    salary: "₹3,00,000 - ₹8,00,000 per year",
    imageSrc: medical4
  },
  {
    id: "medical5",
    title: "BAMS (AYURVEDIC)",
    description: "(Bachelor of Ayurvedic Medicine and Surgery) is an undergraduate degree program in Ayurvedic medicine. Students learn about Ayurvedic principles, herbal medicine, and holistic healthcare practices.",
    scope: [
      "Ayurvedic Doctor",
      "Ayurvedic Consultant",
      "Ayurvedic Researcher",
      "Ayurvedic Pharmacist",
      "Ayurvedic Hospital Administrator",
      "Ayurvedic Lecturer",
      "Ayurvedic Product Developer"
    ],
    salary: "₹3,00,000 - ₹8,00,000 per year",
    imageSrc: medical5
  },
  {
    id: "medical6",
    title: "BUMS (UNANI)",
    description: "(Bachelor of Unani Medicine and Surgery) is an undergraduate degree program in Unani medicine, a traditional Indian system of medicine. Students learn about Unani principles, herbal medicine, and holistic healthcare practices.",
    scope: [
      "Unani Doctor",
      "Unani Consultant",
      "Unani Researcher",
      "Unani Pharmacist",
      "Unani Hospital Administrator",
      "Unani Lecturer",
      "Unani Product Developer"
    ],
    salary: "₹3,00,000 - ₹8,00,000 per year",
    imageSrc: medical6
  },
  {
    id: "medical7",
    title: "BPT",
    description: "(Bachelor of Physiotherapy) is an undergraduate degree program that focuses on the study of physical therapy and rehabilitation techniques. Students learn about human anatomy, exercise physiology, and therapeutic modalities.",
    scope: [
      "Physiotherapist",
      "Sports Physiotherapist",
      "Rehabilitation Specialist",
      "Orthopedic Physiotherapist",
      "Neurological Physiotherapist",
      "Cardiopulmonary Physiotherapist",
      "Pediatric Physiotherapist"
    ],
    salary: "₹3,50,000 - ₹7,50,000 per year",
    imageSrc: medical7
  },
];

const MedicalDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const disciplineDetails = disciplines.find((discipline) => discipline.title === name);
  if (!disciplineDetails) {
    return <Typography variant="h5">Courses not found.</Typography>;
  }

  const { title, description, scope, salary } = disciplineDetails;

  const currentIndex = disciplines.findIndex((discipline) => discipline.title === name);
  const prevDiscipline = currentIndex > 0 ? disciplines[currentIndex - 1] : null;
  const nextDiscipline = currentIndex < disciplines.length - 1 ? disciplines[currentIndex + 1] : null;

  return (
    <Box sx={{ padding: "36px" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
            Courses
          </Link>
          <Link component={RouterLink} to="/courses/medical" color={COLORS.MUTED}>
            Medical
          </Link>
          <Typography color={COLORS.BACKGROUND_COLOR1} style={{fontWeight: "600"}}>{title}</Typography>
        </Breadcrumbs>
        <Box sx={{ display: { xs: "flex", md: "none" }, gap: "18px", marginTop: "10px"}}>
          {prevDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={RouterLink}
              to={`/courses/medical/${prevDiscipline.title}`}
            >
              Prev
            </Button>
          )}
          {nextDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              size="small"
              endIcon={<ArrowForwardIcon />}
              component={RouterLink}
              to={`/courses/medical/${nextDiscipline.title}`}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>

      <Card style={{ textAlign: "justify", height: "100%", marginTop: "20px", backgroundColor:COLORS.GREY_COLOR }}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Text content column */}
            <Grid item xs={12} sm={9} md={7} order={{ xs: 1, md: 1 }}>
              {/* Title and description */}
              <Typography variant="h6" gutterBottom style={{ marginTop: "10px",  fontFamily: COLORS.FONT,fontWeight: "600", textAlign: "left", color: COLORS.BACKGROUND_COLOR0 }}>
                {title}
              </Typography>
              <hr
                style={{
                  width: "10%",
                  backgroundColor: COLORS.BACKGROUND_COLOR1,
                  height: "4px",
                  border: "none",
                  margin: "5px 0",
                }}
              />
              <Typography variant="body1" paragraph style={{marginTop : "20px"}}>
                {title} {description}
              </Typography>

              {/* Scope */}
              <Typography variant="body1">
                <strong style={{color: COLORS.STRONG_COLOR}}>Scope:</strong>
              </Typography>
              <List dense>
                {scope.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon style={{ minWidth: "24px" }}>
                      <FiberManualRecordIcon style={{ fontSize: "13px" }} />
                    </ListItemIcon>
                    <ListItemText primary={item} disableTypography />
                  </ListItem>
                ))}
              </List>

              {/* Salary range */}
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                <strong style={{color: COLORS.STRONG_COLOR}}>Salary:</strong>{" "}
                <span style={{padding: "3px 6px", borderRadius: "5px" }}>{salary}</span>
              </Typography>
            </Grid>

            {/* Image column */}
            <Grid item xs={12} sm={3} md={5} order={{ xs: 2, md: 2 }}>
              <Box sx={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "baseline", height: "100%", marginTop: { xs: "10px", md: "30px" } }}>
                <img src={disciplineDetails.imageSrc} alt={title} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BLACK,
                    padding: "10px 20px",
                    marginTop: "30px",
                    transition: "transform 0.3s ease",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100%"
                  }}
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1)";
                  }}
                  endIcon={<ArrowRightAltIcon style={{ backgroundColor: COLORS.BACKGROUND_COLOR2, color: COLORS.BLACK }} />}
                >
          Contact Us
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "space-between", marginTop: "20px" }}>
            {prevDiscipline && (
              <Button
                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                startIcon={<ArrowBackIcon />}
                component={RouterLink}
                to={`/courses/medical/${prevDiscipline.title}`}
              >
                Previous Course
              </Button>
            )}
            {nextDiscipline && (
              <Button
                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                endIcon={<ArrowForwardIcon />}
                component={RouterLink}
                to={`/courses/medical/${nextDiscipline.title}`}
              >
                Next Course
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MedicalDetail;
