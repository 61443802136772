import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SchoolIcon from "@mui/icons-material/School";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import COLORS from "../../ColorCodes/color";

const Features = () => {
  const cardData = [
    { name: "Career Counseling", desc: "Assessing skills, interests, and goals for informed decisions.", icon: <SchoolIcon /> },
    { name: "Admissions Assistance", desc: "Guidance in selecting, preparing, and securing aid.", icon: <CreateIcon /> },
    { name: "Study Abroad Services", desc: "University selection, application support, visa guidance.", icon: <SearchIcon /> },
  ];

  return (
    <>
      <div style={{ paddingLeft: "17px"}}>
        <Typography variant="h2" style={{ textTransform: "uppercase", fontWeight: "600", marginTop: "30px", fontSize: "23px"}}>
          OUR FEATURES
        </Typography>
        <hr style={{ width: "10%", backgroundColor: COLORS.BACKGROUND_COLOR1, height: "4px", border: "none", margin: "10px 0" }} />
      </div>
      <Grid container spacing={2} style={{ padding: "20px 40px 0px 17px" }}>
        {cardData.map((card, index) => {
          const { ref, inView } = useInView({
            triggerOnce: true,
            threshold: 0.1,
          });

          return (
            <Grid item xs={12} md={6} lg={4} key={index} ref={ref}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1}}
              >
                <Box style={{ height: "100px", backgroundColor: COLORS.GREY_COLOR, padding: "16px", borderRadius: "8px", display: "flex", alignItems: "center", borderLeft: "5px solid white", }}>
                  <Box style={{ alignSelf: "center", fontSize: "40px", textAlign: "center", color: COLORS.WHITE, backgroundColor: COLORS.BACKGROUND_COLOR0, padding: "10px", borderRadius: "10px", marginRight: "16px" }}>
                    {card.icon}
                  </Box>
                  <Box>
                    <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>{card.name}</Typography>
                    <Typography variant="body2">{card.desc}</Typography>
                  </Box>
                </Box>
              </motion.div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Features;
