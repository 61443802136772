import React, { useState } from "react";
import { Typography, Grid, Card, CardContent } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import VisionIcon from "@mui/icons-material/Visibility";
import MissionIcon from "@mui/icons-material/Flag";
import WhoWeAreIcon from "@mui/icons-material/People";
import WhatWeDoIcon from "@mui/icons-material/Work";
import COLORS from "../../ColorCodes/color";

const WhyChooseUs = ({ isMobile }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const icons = [
    {
      IconComponent: VisionIcon,
      title: "Our Vision",
      description: "At What Next! our vision is to lead in education counseling and career guidance in Theni and beyond, empowering, encouraging students through personalized support and quality services for their future."
    },
    {
      IconComponent: MissionIcon,
      title: "Our Mission",
      description: "Our mission at What Next! is to provide comprehensive education counseling and career guidance. We're committed to offering personalized support to students, helping them make informed decisions about their future."
    },
    {
      IconComponent: WhoWeAreIcon,
      title: "Who We Are",
      description: "Welcome to What Next! Your premier destination for expert education counseling and career guidance in Theni. Our dedicated team provides personalized support to help you make informed decisions about your future."
    },
    {
      IconComponent: WhatWeDoIcon,
      title: "What We Do",
      description: "Welcome to What Next! We offer education counseling, admissions assistance, and career guidance. Explore higher education choices and career paths after 12th with us. Located in Theni, we're your trusted partner."
    }
  ];

  return (
    <Grid item xs={12} md={6} style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: isMobile ? "20px" : "30px" }}>
      <Typography variant="h2" style={{ textTransform: "uppercase", fontWeight: "600", marginTop: "10px", fontSize: "28px" }}>
        Why <span style={{ color: COLORS.BACKGROUND_COLOR1 }}>CHOOSE US?</span>
      </Typography>
      <hr style={{ width: "10%", backgroundColor: COLORS.BACKGROUND_COLOR1, height: "4px", border: "none", margin: "10px 0" }} />
      <Typography variant="body1" align="justify" style={{ fontSize: "16px", marginTop: "20px", paddingRight: "17px", width: "100%" }} gutterBottom>
        At What Next!, our education counseling and career guidance set us apart. With personalized support and quality services, every student benefits. Join us and discover why we&apos;re your partner in success.
      </Typography>

      <Grid container spacing={2} style={{ marginTop: "20px", paddingBottom: "40px", paddingRight: "10px" }}>
        {icons.map((icon, index) => {
          const { ref, inView } = useInView({
            triggerOnce: true,
            threshold: 0.1,
          });

          return (
            <Grid item xs={12} sm={6} md={3} key={index} ref={ref}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : {}}
                transition={{ duration: 1.5, delay: index * 0.5}}
              >
                <Card
                  style={{
                    transition: "transform 0.3s",
                    transform: hoveredIndex === index ? "scale(1.05)" : "scale(1)",
                    cursor: "pointer",
                    height: "100%",
                    width: "100%",
                    backgroundColor: COLORS.GREY_COLOR,
                  }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <CardContent style={{ textAlign: "center" }}>
                    <icon.IconComponent style={{ fontSize: 60, color: COLORS.BACKGROUND_COLOR0}} />
                    <hr/>
                    <Typography variant="h6" style={{ marginTop: "10px", fontWeight: "600", color:COLORS.BLACK}}>
                      {icon.title}
                    </Typography>
                    <hr/>
                    <Typography variant="body1" align="justify" style={{ marginTop: "10px", fontSize: "14px" }}>
                      {icon.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default WhyChooseUs;
