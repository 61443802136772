
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SessionContext } from "../screens/SessionContext";

const AdminProtectedRoute = ({ element }) => {
  const { isAdminSessionExpired } = useContext(SessionContext);


  if (isAdminSessionExpired) {
    return <Navigate to="/admin" />;
  }

  return element;
};

export default AdminProtectedRoute;
