import React, { useEffect } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography, Card, CardContent, Grid, List, ListItem, ListItemText, ListItemIcon, Button } from "@mui/material";
import { FiberManualRecord as FiberManualRecordIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import aggri1 from "../../assets/coursesDetail/agri/1.jpg";
import aggri2 from "../../assets/coursesDetail/agri/3.jpg";
import aggri3 from "../../assets/coursesDetail/agri/4.jpg";
import aggri4 from "../../assets/coursesDetail/agri/5.jpg";
import aggri5 from "../../assets/coursesDetail/agri/6.jpg";
import COLORS from "../../ColorCodes/color"


const disciplines = [
  {
    id: 1,
    title: "B.Sc.(AGRICULTURE)",
    description:
          "is a professional degree program that prepares students for a career in agricultural science and technology. It covers areas such as agronomy, crop science, soil science, agriculture extension, and more.",
    scope: [
      "Agronomy",
      "Crop Science",
      "Soil Science",
      "Agricultural Extension",
      "Horticulture",
      "Agribusiness Management",
      "Sustainable Agriculture"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri1,
  },
  {
    id: 2,
    title: "DIPLOMA IN AGRICULTURE",
    description:
          "is a vocational course that provides practical training in various agricultural practices. It covers topics such as crop cultivation, pest management, farm machinery operation, and agricultural marketing",
    scope: [
      "Crop Cultivation Techniques",
      "Pest and Disease Management",
      "Farm Management",
      "Agricultural Marketing",
      "Organic Farming",
      "Irrigation Management",
      "Agricultural Extension Services"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri2,
  },
  {
    id: 3,
    title: "B.SC.(HORTICULTURE)",
    description:
          "is a specialized degree program focused on the science and art of cultivating fruits, vegetables, flowers, and ornamental plants. It includes topics such as plant propagation, nursery management, landscape design, and post-harvest management.",
    scope: [
      "Fruit and Vegetable Production",
      "Floriculture",
      "Landscape Architecture",
      "Nursery Management",
      "Plant Breeding",
      "Pest and Disease Control",
      "Post-harvest Technology"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri3,
  },
  {
    id: 4,
    title: "B.SC.(FORESTRY)",
    description:
          "is a degree program that deals with the conservation, management, and sustainable use of forest resources. It covers subjects like forest ecology, wildlife management, forest economics, and forest policy.",
    scope: [
      "Forest Ecology",
      "Wildlife Management",
      "Forest Economics",
      "Forest Policy",
      "Agroforestry",
      "Timber Management",
      "Environmental Conservation"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri4,
  },
  {
    id: 5,
    title: "B.SC.(FISHERY)",
    description:
          "is an undergraduate program that focuses on the science and management of fisheries and aquaculture. It covers topics such as fish biology, aquaculture techniques, fish nutrition, and fisheries management.",
    scope: [
      "Aquaculture Management",
      "Fish Health Management",
      "Fish Genetics and Breeding",
      "Fisheries Economics",
      "Fish Processing Technology",
      "Fisheries Extension",
      "Marine Resource Management"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri5,
  },
  {
    id: 6,
    title: "DIPLOMA IN HORTICULTURE",
    description:
          "is a vocational course that provides practical training in various aspects of horticulture. It covers topics such as nursery management, landscape gardening, flower arrangement, and greenhouse technology.",
    scope: [
      "Nursery Management Techniques",
      "Landscape Gardening",
      "Floriculture Practices",
      "Greenhouse Management",
      "Plant Propagation Methods",
      "Pest and Disease Control in Horticulture",
      "Ornamental Plant Cultivation"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: aggri2,
  },
];

const AggricultureDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const disciplineDetails = disciplines.find((discipline) => discipline.title === name);

  if (!disciplineDetails) {
    return <Typography variant="h5">Courses not found.</Typography>;
  }

  const { title, description, scope, salary } = disciplineDetails;

  const currentIndex = disciplines.findIndex((discipline) => discipline.title === name);
  const prevDiscipline = currentIndex > 0 ? disciplines[currentIndex - 1] : null;
  const nextDiscipline = currentIndex < disciplines.length - 1 ? disciplines[currentIndex + 1] : null;

  return (
    <Box sx={{ padding: "36px" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
            Courses
          </Link>
          <Link component={RouterLink} to="/courses/agriculture" color={COLORS.MUTED}>
            Agriculture
          </Link>
          <Typography color={COLORS.BACKGROUND_COLOR1} style={{fontWeight: "600"}}>{title}</Typography>
        </Breadcrumbs>
        <Box sx={{ display: { xs: "flex", md: "none" }, gap: "18px", marginTop: "10px"}}>
          {prevDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              startIcon={<ArrowBackIcon />}
              component={RouterLink}
              to={`/courses/agriculture/${prevDiscipline.title}`}
            >
               Prev
            </Button>
          )}
          {nextDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              size="small"
              endIcon={<ArrowForwardIcon />}
              component={RouterLink}
              to={`/courses/agriculture/${nextDiscipline.title}`}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>

      <Card style={{ textAlign: "justify", height: "100%", marginTop: "20px",backgroundColor:COLORS.GREY_COLOR }}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Text content column */}
            <Grid item xs={12} sm={9} md={7} order={{ xs: 1, md: 1 }}>
              {/* Title and description */}
              <Typography variant="h6" gutterBottom style={{ marginTop: "10px",  fontFamily: COLORS.FONT,fontWeight: "600", textAlign: "left", color: COLORS.BACKGROUND_COLOR0 }}>
                {title}
              </Typography>
              <hr
                style={{
                  width: "15%",
                  backgroundColor: COLORS.BACKGROUND_COLOR1,
                  height: "4px",
                  border: "none",
                  margin: "5px 0",
                }}
              />
              <Typography variant="body1" paragraph style={{marginTop : "20px"}}>
                {title} {description}
              </Typography>

              {/* Scope */}
              <Typography variant="body1">
                <strong style={{color: COLORS.STRONG_COLOR}}>Scope:</strong>
              </Typography>
              <List dense>
                {scope.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon style={{ minWidth: "24px" }}>
                      <FiberManualRecordIcon style={{ fontSize: "13px" }} />
                    </ListItemIcon>
                    <ListItemText primary={item} disableTypography />
                  </ListItem>
                ))}
              </List>

              {/* Salary range */}
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                <strong style={{color: COLORS.STRONG_COLOR}}>Salary:</strong>{" "}
                <span style={{ padding: "3px 6px", borderRadius: "5px" }}>{salary}</span>
              </Typography>
            </Grid>

            {/* Image column */}
            <Grid item xs={12} sm={3} md={5} order={{ xs: 2, md: 2 }}>
              <Box sx={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "baseline", height: "100%", marginTop: { xs: "10px", md: "30px" } }}>
                <img src={disciplineDetails.imageSrc} alt={title} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BLACK,
                    padding: "10px 20px",
                    marginTop: "30px",
                    transition: "transform 0.3s ease",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100%"
                  }}
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1)";
                  }}
                  endIcon={<ArrowRightAltIcon style={{ backgroundColor: COLORS.BACKGROUND_COLOR2, color: COLORS.BLACK }} />}
                >
          Contact Us
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "space-between", marginTop: "20px" }}>
            {prevDiscipline && (
              <Button
                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                startIcon={<ArrowBackIcon />}
                component={RouterLink}
                to={`/courses/agriculture/${prevDiscipline.title}`}
              >
                Previous Course
              </Button>
            )}
            {nextDiscipline && (
              <Button

                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                endIcon={<ArrowForwardIcon />}
                component={RouterLink}
                to={`/courses/agriculture/${nextDiscipline.title}`}
              >
                Next Course
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AggricultureDetail;
