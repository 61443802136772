import React, { useEffect } from "react";
import { Typography, Button, Grid } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CounterGrid from "./CounterGrid";
import COLORS from "../../ColorCodes/color";

const KeyAspects = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container spacing={2} style={{ padding: "0px 17px" }}>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          width: { xs: "100%", md: "80%" },
          padding: isMobile ? "10px" : "30px 20px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            letterSpacing: "4px",
            color: "#666",
            textTransform: "uppercase",
            marginTop: "30px",
            marginBottom: "0",
          }}
        >
          ABOUT US
        </Typography>
        <Typography
          variant="h2"
          style={{
            textTransform: "uppercase",
            fontWeight: "600",
            marginTop: "10px",
            fontSize: "23px",
          }}
        >
          Key Aspects of Next Education Services
        </Typography>
        <hr
          style={{
            width: "30%",
            backgroundColor: COLORS.BACKGROUND_COLOR1,
            height: "4px",
            border: "none",
            margin: "10px 0",
          }}
        />
        <Typography
          variant="body1"
          align="justify"
          style={{ fontSize: "16px", marginTop: "20px" }}
          gutterBottom
        >
          Education consultancy services refer to professional assistance and guidance provided to individuals, institutions, or organizations in the field of education.
          These services can cover a wide range of areas, including academic advising, career counseling, admission assistance, curriculum development, institutional management, and more. Education consultants work to help clients make informed decisions and achieve their educational goals. Here are some common aspects of education consultancy services
        </Typography>

        <Typography
          variant="h2"
          style={{
            textTransform: "uppercase",
            fontWeight: "600",
            marginTop: "40px",
            fontSize: "23px",
          }}
        >
         WHY NEXT EDUCATION IS MOST POWERFUL WEAPON!
        </Typography>
        <hr
          style={{
            width: "30%",
            backgroundColor: COLORS.BACKGROUND_COLOR1,
            height: "4px",
            border: "none",
            margin: "10px 0",
          }}
        />
        <Typography
          variant="body1"
          align="justify"
          style={{ fontSize: "16px", marginTop: "20px" }}
          gutterBottom
        >
          We&apos;re here to guide you after your 12th standard. Get expert education counseling and career guidance tailored just for you. Explore higher education choices and career paths after 12th with us. Our services include admissions assistance, study abroad services, and placement assistance. Discover courses after the 12th and job opportunities after the 12th with our support. Based in Theni, we offer quality education counseling and career counseling. Your future starts here!
        </Typography>
        <Button
          variant="contained"
          style={{
            backgroundColor: COLORS.BACKGROUND_COLOR0,
            color: COLORS.WHITE,
            padding: "10px 20px",
            marginTop: "30px",
            transition: "transform 0.3s ease",
            fontSize: "14px",
            fontWeight: "600",
          }}
          onClick={() => {
            navigate("/contact");
            window.scrollTo(0, 0);
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR0;
            e.target.style.color = COLORS.BACKGROUND_COLOR1;
            e.target.style.transform = "scale(1.1)";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR0;
            e.target.style.color = COLORS.WHITE;
            e.target.style.transform = "scale(1)";
          }}
          endIcon={<ArrowRightAltIcon style={{ backgroundColor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE }} />}
        >
          Contact Us
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ width: { xs: "100%", md: "20%" }, textAlign: "center", position: "relative", padding: isMobile ? "10px" : "30px 20px" }}
      >

        <CounterGrid isMobile={isMobile} />
      </Grid>
    </Grid>
  );
};

export default KeyAspects;
