import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SessionContext } from "../screens/SessionContext";

const ProtectedRoute = ({ element }) => {
  const { isSessionExpired } = useContext(SessionContext);

  // If session is expired, redirect to login
  if (isSessionExpired) {
    return <Navigate to="/login" />;
  }

  // If session is valid, render the element
  return element;
};

export default ProtectedRoute;
