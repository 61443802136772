import React from "react";
import IconGrid from "./aboutUs/WhyChooseUs";
import AboutUsGrid from "./aboutUs/KeyAspects";
import CardGrid from "./aboutUs/Features";
import COLORS from "../ColorCodes/color";


const AboutUs = () => {
  return (
    <div style={{backgroundColor: COLORS.WHITE}}>
      <AboutUsGrid />
      <CardGrid />
      <IconGrid/>
    </div>
  );
};

export default AboutUs;
