import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  ImageList,
  ImageListItem,
  Tabs,
  Tab,
  CircularProgress,
  Pagination,
  useMediaQuery,
  useTheme,
  Breadcrumbs,
  Link
} from "@mui/material";
import CustomLightbox from "../../components/CustomLightbox";
import ImageIcon from "@mui/icons-material/Image";
import COLORS from "../../ColorCodes/color";

import { Link as RouterLink } from "react-router-dom";
const ImageGallery2023 = () => {
  const [images, setImages] = useState([]);
  const [districts] = useState(["Sivakasi"]);
  const [selectedDistrict, setSelectedDistrict] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const itemsPerPage = 15;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      try {

        const virudhunagarImages = await Promise.all(
          Array.from({ length: 25 }, (_, i) => {
            return import(`../../assets/uploads/2023/sivakasi/${i + 1}.JPG`).then(module => ({
              district: "Sivakasi",
              filepath: module.default,
            }));
          })
        );
        setImages([...virudhunagarImages]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedDistrict(newValue);
    setPage(1);
  };

  const filteredImages = images.filter(image => image.district === districts[selectedDistrict]);

  const pageCount = Math.ceil(filteredImages.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const paginatedImages = filteredImages.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleImageClick = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const handleCloseLightbox = () => {
    setLightboxOpen(false);
  };

  const handleMovePrev = () => {
    if (photoIndex === 0) {
      if (page > 1) {
        setPage(page - 1);
        setPhotoIndex(itemsPerPage - 1);
      }
    } else {
      setPhotoIndex((photoIndex - 1 + paginatedImages.length) % paginatedImages.length);
    }
  };

  const handleMoveNext = () => {
    if (photoIndex === paginatedImages.length - 1) {
      if (page < pageCount) {
        setPage(page + 1);
        setPhotoIndex(0);
      }
    } else {
      setPhotoIndex((photoIndex + 1) % paginatedImages.length);
    }
  };

  return (
    <Box sx={{ height: "100%", overflowY: "auto", padding: 2 }}>


      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/gallery" color={COLORS.MUTED}>
          Gallery
        </Link>
        <Link component={RouterLink} style={{textDecoration: "none", fontWeight: "600"}} to="/gallery/2023" color={COLORS.BACKGROUND_COLOR1}>
        2023 Education Fair
        </Link>
      </Breadcrumbs>

      <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE, marginTop: "15px" }}>
        <Tabs
          value={selectedDistrict}
          onChange={handleTabChange}
          aria-label="district tabs"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#202c45",
            },
          }}
        >
          {districts.map((district, index) => (
            <Tab key={index} label={district} sx={{ color: COLORS.WHITE, "&.Mui-selected": { color: COLORS.BACKGROUND_COLOR1 } }} />
          ))}
        </Tabs>
        <Typography
          sx={{ display: "flex", alignItems: "center", marginY: 1, fontSize: "13px", marginRight: "20px",}}
        >
          <ImageIcon sx={{ marginRight: "4px",  color: COLORS.BACKGROUND_COLOR1 }} /> <Box style={{color: COLORS.BACKGROUND_COLOR1}}>{filteredImages.length}</Box>
        </Typography>
      </div>
      <div style={{ backgroundColor: COLORS.GREY_COLOR, padding: "10px", borderRadius: "3px" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <ImageList variant="masonry" cols={isMobile ? 1 : isTab ? 2 : 5} gap={8}>
              {paginatedImages.map((image, index) => (
                <ImageListItem key={index} onClick={() => handleImageClick(index)} style={{ width: "100%", height: "200px", overflow: "hidden" }}>
                  <img
                    src={image.filepath}
                    alt=""
                    loading="lazy"
                    style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Box>
            {lightboxOpen && (
              <CustomLightbox
                images={paginatedImages}
                photoIndex={photoIndex}
                onCloseRequest={handleCloseLightbox}
                onMovePrevRequest={handleMovePrev}
                onMoveNextRequest={handleMoveNext}
              />
            )}
          </>
        )}
      </div>
    </Box>
  );
};

export default ImageGallery2023;
