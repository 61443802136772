import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CardMedia,
  IconButton,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import COLORS from "../../ColorCodes/color";
import { Link, useNavigate } from "react-router-dom";
import pdfImage from "../../assets/pdf.png";
import { AccountCircle, Email, Phone } from "@mui/icons-material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {jwtDecode} from "jwt-decode";
import { SessionContext } from "../SessionContext";
import { useTimer } from "react-timer-hook";
import LogoutIcon from "@mui/icons-material/Logout";

const QuestionBank = ({ setOpen }) => {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [userDetails, setUserDetails] = useState({ name: "", email: "", phone: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const [showTopIcon, setShowTopIcon] = useState(true);
  const openMenu = Boolean(anchorEl);

  const navigate = useNavigate();
  const { isSessionExpired, setIsSessionExpired } = useContext(SessionContext);

  const getExpiryTimestamp = () => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      const expiryTime = decodedToken.exp * 1000;
      return new Date(expiryTime);
    }
    return new Date();
  };


  const handleSessionExpired = () => {
    setIsSessionExpired(true);
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userDetails");
    navigate("/login");
  };


  const { seconds, minutes, hours, days, start, restart } = useTimer({
    expiryTimestamp: getExpiryTimestamp(),
    onExpire: handleSessionExpired,
  });

  useEffect(() => {
    importPDFs();
    const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (storedUserDetails) {
      setUserDetails(storedUserDetails);
    }
  }, []);

  useEffect(() => {
    if (!isSessionExpired) {
      start(getExpiryTimestamp());
    }
  }, [isSessionExpired]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowTopIcon((prev) => !prev);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const importPDFs = async () => {
    try {
      const context = require.context("../../assets/question", false, /\.pdf$/);
      const files = context.keys().map((key) => {
        const fileName = key.substring(key.lastIndexOf("/") + 1);
        return {
          name: fileName,
          url: context(key).default,
        };
      });
      setPdfFiles(files);
    } catch (error) {
      console.error("Error importing PDF files:", error);
    }
  };

  const handleLogout = () => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      const expiryDate = new Date(jwtDecode(token).exp * 1000);
      restart(expiryDate);
    }
    setOpenLogoutDialog(true);
  };

  const handleConfirmLogout = () => {
    setIsSessionExpired(true);
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userDetails");
    navigate("/login");
    setOpenLogoutDialog(false);
  };

  const handleCancelLogout = () => {
    setOpenLogoutDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuLogout = () => {
    handleMenuClose();
    handleLogout();
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0px" }}>
        <Typography variant="h2" style={{ textTransform: "uppercase", fontWeight: "600", fontSize: "23px" }}>
          Modal Question Paper
        </Typography>
        <Box>

          <IconButton onClick={handleMenuOpen} color="inherit">
            <AccountCircle sx={{fontSize: "32px"}}/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
          >
            <MenuItem >User Details</MenuItem>
            {userDetails.name && <MenuItem >
              <AccountCircle style={{ marginRight: 8 }} />
              {userDetails.name}
            </MenuItem>}
            {userDetails.email && <MenuItem >
              <Email style={{ marginRight: 8 }} />
              {userDetails.email}
            </MenuItem>}
            {userDetails.phone && <MenuItem >
              <Phone style={{ marginRight: 8 }} />
              {userDetails.phone}
            </MenuItem>}
            <MenuItem >
              {showTopIcon ? (
                <HourglassTopIcon style={{ marginRight: 8 }} />
              ) : (
                <HourglassBottomIcon style={{ marginRight: 8 }} />
              )}
              Session Expires In: {`${days}d ${hours}h ${minutes}m ${seconds}s`}
            </MenuItem>
            <MenuItem onClick={handleMenuLogout}> <LogoutIcon
              style={{ marginRight: 8 }} />Logout</MenuItem>
          </Menu>
        </Box>
      </Box>

      <hr style={{ width: "10%", backgroundColor: COLORS.BACKGROUND_COLOR1, height: "4px", border: "none", margin: "0px 0" }} />

      {isSessionExpired ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <Alert severity="error">
            Your session has expired. Please <Link to="/login">login</Link> again.
          </Alert>
        </Box>
      ) : (
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {pdfFiles.map((file, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Link
                to={`/questionBank/${encodeURIComponent(file.name)}`}
                style={{ textDecoration: "none" }}
                onClick={() => setOpen(false)}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    "&:hover": {
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ height: 80, objectFit: "contain", mt: 2 }}
                    image={pdfImage}
                    alt={file.name}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography style={{ fontSize: "16px" }} component="div">
                      {file.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Logout Confirmation Dialog */}
      <Dialog open={openLogoutDialog} onClose={handleCancelLogout}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          Are you sure you want to logout? Your session will expire in {`${days}d ${hours}h ${minutes}m ${seconds}s`}.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLogout} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QuestionBank;
