import React from "react";
import { Grid, Avatar, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import grid1Logo from "../../assets/graduates.png";
import grid4Logo from "../../assets/lecture.png";
import grid2Logo from "../../assets/desktop.png";
import grid3Logo from "../../assets/library.png";

const Info = () => {
  const avatars = [
    { id: 3, src: grid3Logo, text: "VISION", content: "At What Next Education Is Most Powerful Weapon, our vision is to lead in education counseling and career guidance in Theni and beyond, empowering students through personalized support and quality services." },
    { id: 4, src: grid4Logo, text: "MISSION", content: "Our mission at What Next Education Is Most Powerful Weapon is to provide comprehensive education counseling and career guidance. We are committed to offering personalized support to students and helping them make informed decisions about their future." },
    { id: 1, src: grid1Logo, text: "WHO WE ARE?", content: "Welcome to What Next! Your premier destination for expert education counseling and career guidance in Theni. Our dedicated team provides personalized support to help you make informed decisions about your future." },
    { id: 2, src: grid2Logo, text: "WHAT WE DO?", content: "Welcome to What Next! We offer education counseling, admissions assistance, and career guidance. Explore higher education choices and career paths after 12th with us. Located in Theni, we're your trusted partner." },
  ];

  return (
    <Grid container spacing={2} sx={{ padding: 5 }}>
      {avatars.map((avatar, index) => {
        const { ref, inView } = useInView({
          triggerOnce: true,
          threshold: 0.1,
        });

        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            key={avatar.id}
            ref={ref}
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : {}}
            transition={{ duration: 1.5, delay: index * 0.5 }}
          >
            <Grid container direction="column" alignItems="center">
              <Avatar
                alt={`Avatar ${avatar.id}`}
                src={avatar.src}
                sx={{ width: 100, height: 100, marginBottom: 1 }}
              />
              <Typography
                variant="h6"
                align="center"
                sx={{ marginTop: "10px", fontWeight: "600" }}
              >
                {avatar.text}
              </Typography>
              <Typography
                variant="body1"
                align="justify"
                sx={{ margin: "10px", fontSize: "16px" }}
              >
                {avatar.content}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Info;
