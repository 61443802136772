import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
  Button,
  Typography,
  Collapse,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import GetAppIcon from "@mui/icons-material/GetApp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableChartIcon from "@mui/icons-material/TableChart";
import CsvIcon from "@mui/icons-material/BarChart";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import dayjs from "dayjs";
import moment from "moment/moment";
import COLORS from "../../ColorCodes/color"
import CloseIcon from "@mui/icons-material/Close";

const LoginStudents = ({filtersLogin, setFiltersLogin, tempFiltersLogin, setTempFiltersLogin}) => {
  const [loginUsers, setLoginUsers] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    schools: [],
    courses: [],
    colleges: [],
  });
  const [searchQuery, setSearchQuery] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);


  useEffect(() => {
    const fetchLoginUsers = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/getAllLoginUsers");
        const data = response.data;
        setLoginUsers(data);

        const schools = [...new Set(data.map(user => user.school).filter(school => school))];
        const courses = [...new Set(data.map(user => user.preferredCourse).filter(course => course))];
        const colleges = [...new Set(data.map(user => user.preferredCollege).filter(college => college))];

        setFilterOptions({
          schools,
          courses,
          colleges,
        });
      } catch (error) {
        console.error("Error fetching login users:", error);
      }
    };

    fetchLoginUsers();
  }, []);

  const filteredLoginUsers = loginUsers
    .filter((user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((user) => {
      const userDate = new Date(user.createdAt);
      const filterDate = filtersLogin.monthYear ? new Date(filtersLogin.monthYear) : null;
      return (
        (!filtersLogin.createdAt || userDate.toLocaleDateString() === new Date(filtersLogin.createdAt).toLocaleDateString()) &&
        (!filtersLogin.preferredCourse || user.preferredCourse.toLowerCase().includes(filtersLogin.preferredCourse.toLowerCase())) &&
        (!filtersLogin.preferredCollege || user.preferredCollege.toLowerCase().includes(filtersLogin.preferredCollege.toLowerCase())) &&
        (!filtersLogin.startDate || userDate >= new Date(filtersLogin.startDate)) &&
        (!filtersLogin.endDate || userDate <= new Date(filtersLogin.endDate)) &&
        (!filterDate || (userDate.getMonth() === filterDate.getMonth() && userDate.getFullYear() === filterDate.getFullYear()))
      );
    })  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterMenuOpen = (event) => {
    setFilterAnchorEl(filterAnchorEl ? null : event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  const applyFilters = () => {
    setFiltersLogin(tempFiltersLogin);
    handleFilterMenuClose();
  };

  const clearFilters = () => {
    setTempFiltersLogin({
      createdAt: null,
      preferredCourse: "",
      preferredCollege: "",
      monthYear: null,
    });
    setFiltersLogin({
      createdAt: null,
      preferredCourse: "",
      preferredCollege: "",
      monthYear: null,
    });
    handleFilterMenuClose();
  };

  const clearFilters1 = (filterName) => {
    setFiltersLogin(prevFilters => ({
      ...prevFilters,
      [filterName]: filterName === "createdAt" || filterName === "monthYear" ? null : ""
    }));
    setTempFiltersLogin(prevFilters => ({
      ...prevFilters,
      [filterName]: filterName === "createdAt" || filterName === "monthYear" ? null : ""
    }));
  };


  const generatePDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [["Name", "Phone", "School", "Email", "Preferred Course", "Preferred College", "City", "District"]],
      body: filteredLoginUsers.map(user => [
        user.name, user.mobileNumber, user.school, user.email, user.preferredCourse, user.preferredCollege, user.city, user.district, user.message
      ]),
    });

    doc.save("LoginUsersReport.pdf");
  };

  const generateExcel = () => {
    const dataToExport = filteredLoginUsers.map(({ name, mobileNumber, school, email, preferredCourse, preferredCollege, city, district, message, createdAt }, index) => ({
      "S.No": index + 1,
      name,
      mobileNumber,
      school,
      email,
      preferredCourse,
      preferredCollege,
      city,
      district,
      message,
      createdAt: format(new Date(createdAt), "dd/MM/yyyy HH:mm:ss")
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "LoginUsers");
    XLSX.writeFile(workbook, "LoginUsersReport.xlsx");
  };

  const generateCSV = () => {
    const dataToExport = filteredLoginUsers.map(({ name, mobileNumber, school, email, preferredCourse, preferredCollege, city, district, message, createdAt }, index) => ({
      "S.No": index + 1,
      name,
      mobileNumber,
      school,
      email,
      preferredCourse,
      preferredCollege,
      city,
      district,
      message,
      createdAt: format(new Date(createdAt), "dd/MM/yyyy HH:mm:ss")
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const csv = XLSX.utils.sheet_to_csv(worksheet);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "LoginUsersReport.csv";
    a.click();
  };

  const handleRowClick = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
    setHighlightedRow(id);
  };

  return (
    <Box>
      <Typography
        variant="h6"
        style={{
          textTransform: "uppercase",
          fontWeight: "600",
          marginTop: "10px",
          fontSize: "18px",
          textAlign: "center"
        }}
      >
       Logged In Students DETAILS
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            label="Search by name"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Filter" arrow>
            <IconButton
              onClick={handleFilterMenuOpen}
              sx={{
                bgcolor: "black",
                color: COLORS.WHITE,
                "&:hover": { bgcolor: COLORS.WHITE, color: "black" },
              }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Report" arrow>
            <IconButton
              onClick={handleMenuOpen}
              sx={{
                bgcolor: "black",
                color: COLORS.WHITE,
                "&:hover": { bgcolor: COLORS.WHITE, color: "black" },
              }}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={generatePDF}>
            <ListItemIcon>
              <PictureAsPdfIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download as PDF</ListItemText>
          </MenuItem>
          <MenuItem onClick={generateExcel}>
            <ListItemIcon>
              <TableChartIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download as Excel</ListItemText>
          </MenuItem>
          <MenuItem onClick={generateCSV}>
            <ListItemIcon>
              <CsvIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download as CSV</ListItemText>
          </MenuItem>
        </Menu>
        <Collapse in={Boolean(filterAnchorEl)}>
          <Box sx={{ display: "flex", flexDirection: "column",gap: 2, padding: "30px", background: "#f0f0f0", marginRight: "60px" }}>
            <Typography variant="h6" style={{fontWeight: "700", letterSpacing: 4}}>FILTERS</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Created At"
                value={tempFiltersLogin.createdAt ? dayjs(tempFiltersLogin.createdAt) : null}
                onChange={(newValue) => setTempFiltersLogin((prev) => ({ ...prev, createdAt: newValue ? newValue.toDate() : null }))}
                renderInput={(params) => <TextField {...params} fullWidth />}
                sx={{background: COLORS.WHITE}}
              />
              <DatePicker
                views={["month", "year"]}
                label="Month/Year"
                sx={{background: COLORS.WHITE}}
                value={tempFiltersLogin.monthYear ? dayjs(tempFiltersLogin.monthYear) : null}
                onChange={(newValue) => setTempFiltersLogin((prev) => ({ ...prev, monthYear: newValue ? newValue.toDate() : null }))}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            <FormControl fullWidth>
              <InputLabel>Preferred Course</InputLabel>
              <Select
                value={tempFiltersLogin.preferredCourse}
                onChange={(e) => setTempFiltersLogin((prev) => ({ ...prev, preferredCourse: e.target.value }))}
                fullWidth
                sx={{background: COLORS.WHITE}}
                label="Preffered Course"
              >
                <MenuItem value="">All</MenuItem>
                {filterOptions.courses.map((course) => (
                  <MenuItem key={course} value={course}>{course}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Preferred College</InputLabel>
              <Select
                value={tempFiltersLogin.preferredCollege}
                label="Preffered College"
                onChange={(e) => setTempFiltersLogin((prev) => ({ ...prev, preferredCollege: e.target.value }))}
                fullWidth
                sx={{background: COLORS.WHITE}}
              >
                <MenuItem value="">All</MenuItem>
                {filterOptions.colleges.map((college) => (
                  <MenuItem key={college} value={college}>{college}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
              <Button onClick={clearFilters} sx={{ color: "black", bgcolor: COLORS.WHITE }}>
                Clear
              </Button>
              <Button onClick={applyFilters} sx={{ color: "black", bgcolor: COLORS.WHITE }}>
                Apply
              </Button>
            </Box>
          </Box>
        </Collapse>
      </Box>
      <Box sx={{display: "flex", gap: "15px", marginTop : "10px"}}>
        {filtersLogin.preferredCourse && (
          <div style={{display: "flex"}}>
            <div style={{background: COLORS.GREY_COLOR, padding: "5px 6px"}}>
              <span style={{fontWeight: "600"}}>Preferred Course:</span> {filtersLogin.preferredCourse}
            </div>
            <div style={{background: COLORS.GREY_COLOR, paddingRight: "10px", display: "flex", alignItems: "center"}}>
              <CloseIcon
                title="Close"
                sx={{cursor: "pointer", fontSize: "20px"}}
                onClick={() => clearFilters1("preferredCourse")}
              />
            </div>
          </div>
        )}
        {filtersLogin.preferredCollege && (
          <div style={{display: "flex"}}>
            <div style={{background: COLORS.GREY_COLOR, padding: "5px 6px"}}>
              <span style={{fontWeight: "600"}}>Preferred College:</span> {filtersLogin.preferredCollege}
            </div>
            <div style={{background: COLORS.GREY_COLOR, paddingRight: "10px", display: "flex", alignItems: "center"}}>
              <CloseIcon
                title="Close"
                sx={{cursor: "pointer", fontSize: "20px"}}
                onClick={() => clearFilters1("preferredCollege")}
              />
            </div>
          </div>
        )}
        {filtersLogin.monthYear && (
          <div style={{display: "flex"}}>
            <div style={{background: COLORS.GREY_COLOR, padding: "5px 6px"}}>
              <span style={{fontWeight: "600"}}>Month/Year:</span> {moment(filtersLogin.monthYear).format("MMMM YYYY")}
            </div>
            <div style={{background: COLORS.GREY_COLOR, paddingRight: "10px", display: "flex", alignItems: "center"}}>
              <CloseIcon
                title="Close"
                sx={{cursor: "pointer", fontSize: "20px"}}
                onClick={() => clearFilters1("monthYear")}
              />
            </div>
          </div>
        )}
        {filtersLogin.createdAt && (
          <div style={{display: "flex"}}>
            <div style={{background: COLORS.GREY_COLOR, padding: "5px 6px"}}>
              <span style={{fontWeight: "600"}}>Created At:</span> {moment(filtersLogin.createdAt).format("DD-MM-YYYY")}
            </div>
            <div style={{background: COLORS.GREY_COLOR, paddingRight: "10px", display: "flex", alignItems: "center"}}>
              <CloseIcon
                title="Close"
                sx={{cursor: "pointer", fontSize: "20px"}}
                onClick={() => clearFilters1("createdAt")}
              />
            </div>
          </div>
        )}

      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: COLORS.WHITE }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
              Name
              </TableCell>

              <TableCell>
              Phone
              </TableCell>
              <TableCell>
              City
              </TableCell>
              <TableCell>
              School
              </TableCell>
              <TableCell>
              Preferred Course
              </TableCell>
              <TableCell>
              Preferred College
              </TableCell>
              <TableCell>
              Date Registered
              </TableCell>
              <TableCell  sx={{width: "30px"}}>
              Login Count
              </TableCell>
              <TableCell sx={{width: "120px", marginLeft: "20px"}}>
              Last Login Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLoginUsers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                  <Typography variant="body1" color="text.secondary">
                    No data found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredLoginUsers.map((student) => (
                <React.Fragment key={student.id}>
                  <TableRow
                    onClick={() => handleRowClick(student.id)}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: highlightedRow === student.id ? "#f5f5f5" : "inherit"
                    }}
                  >
                    <TableCell style={{ width: "15%" }}>{student.name}</TableCell>
                    <TableCell style={{ width: "15%" }}>{student.mobileNumber}</TableCell>
                    <TableCell style={{ width: "10%" }}>{student.city}</TableCell>
                    <TableCell style={{ width: "15%" }}>{student.school}</TableCell>
                    <TableCell style={{ width: "15%" }}>{student.preferredCourse}</TableCell>
                    <TableCell style={{ width: "20%" }}>{student.preferredCollege}</TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {moment(student.createdAt).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell style={{ width: "15%", textAlign: "center" }}>
                      {student.loginCount}
                    </TableCell>
                    {student.lastLogin!= null ? <TableCell style={{ width: "25%", textAlign: "left",paddingLeft: "15px" }}>
                      {moment(student.lastLogin).format("DD/MM/YYYY")}<br></br>{moment(student.lastLogin).format("hh:mm A")}
                    </TableCell> : <TableCell style={{ width: "15%", textAlign: "center" }}>
                      -
                    </TableCell>}
                  </TableRow>
                  {expandedRow === student.id && (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ p: 0 }}>
                        <Collapse in={expandedRow === student.id}>
                          <Box sx={{ p: 2 }}>
                            {student.email && (
                              <Typography variant="body2" color="text.secondary" style={{ marginBottom: "10px" }}>
                                <strong>Email:</strong> {student.email}
                              </Typography>
                            )}
                            {student.district && (
                              <Typography variant="body2" color="text.secondary" style={{ marginBottom: "10px" }}>
                                <strong>District:</strong> {student.district}
                              </Typography>
                            )}
                            {student.city && (
                              <Typography variant="body2" color="text.secondary" style={{ marginBottom: "10px" }}>
                                <strong>City:</strong> {student.city}
                              </Typography>
                            )}
                            {student.createdAt && (
                              <Typography variant="body2" color="text.secondary">
                                <strong>Created At:</strong> {moment(student.createdAt).format("DD/MM/YYYY hh:mm A")}
                              </Typography>
                            )}

                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredLoginUsers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default LoginStudents;
