import React, { useState, useEffect } from "react";
import { Typography, Card, CardContent, CardMedia, Grid, ButtonBase, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import aggri from "../assets/courses/aggri.jpg";
import architect from "../assets/courses/architect.avif";
import arts from "../assets/courses/arts.jpg";
import engineer from "../assets/courses/engineer.jpg";
import management from "../assets/courses/management.avif";
import medical from "../assets/courses/medical.avif";
import health from "../assets/courses/health.avif";
import law from "../assets/courses/law.jpg";
import nurse from "../assets/courses/nurse.jpg";
import diploma from "../assets/courses/diploma.jpg";
import COLORS from "../ColorCodes/color";

const courses = [
  {
    title: "Agriculture",
    description: "Explore courses in agriculture designed to enhance sustainable development and innovation.",
    image: aggri,
    link: "/courses/agriculture"
  },
  {
    title: "Architecture",
    description: "Explore courses in architecture designed to enhance sustainable development and innovation.",
    image: architect,
    link: "/courses/architect"
  },
  {
    title: "Arts & Science",
    description: "Dive into the world of arts and science with our comprehensive courses covering a wide range of subjects.",
    image: arts,
    link: "/courses/arts-science"
  },
  {
    title: "Engineering",
    description: "Advance your engineering skills with our specialized courses in various engineering disciplines.",
    image: engineer,
    link: "/courses/engineering"
  },
  {
    title: "Management",
    description: "Develop your leadership and management skills through our expertly designed management courses.",
    image: management,
    link: "/courses/management"
  },
  {
    title: "Medical",
    description: "Gain in-depth knowledge and training in the medical field with our extensive medical courses.",
    image: medical,
    link: "/courses/medical"
  },
  {
    title: "Allied Health Science",
    description: "Explore the various allied health sciences and their impact on healthcare delivery.",
    image: health,
    link: "/courses/allied-health-science"
  },
  {
    title: "Nursing & Pharmacy",
    description: "Pursue a career in nursing and pharmacy with our dedicated courses designed for future healthcare professionals.",
    image: nurse,
    link: "/courses/nursing-pharmacy"
  },
  {
    title: "Law",
    description: "Study the principles and practices of law with our comprehensive law courses.",
    image: law,
    link: "/courses/law"
  },
  {
    title: "Diploma",
    description: "Earn a diploma in various fields to enhance your career prospects and skill set.",
    image: diploma,
    link: "/courses/diploma"
  },
];

const Courses = () => {
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (link) => {
    navigate(link);
  };

  const cardVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  };

  const cardHoveredStyle = {
    transform: "translateY(-5px)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",

  };

  return (
    <div style={{ padding: "17px" }}>
      <Typography
        variant="h2"
        style={{
          textTransform: "uppercase",
          fontWeight: "600",
          marginTop: "10px",
          fontSize: "23px",
        }}
      >
        Courses
      </Typography>
      <hr
        style={{
          width: "10%",
          backgroundColor: COLORS.BACKGROUND_COLOR1,
          height: "4px",
          border: "none",
          margin: "10px 0",
        }}
      />
      <Grid container spacing={3} style={{ paddingTop: "10px"}}>
        {courses.map((course, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <motion.div
              initial="initial"
              animate="animate"
              whileHover={{ scale: 1.05 }}
              variants={cardVariants}
              style={{ width: "100%", height: "100%" }}
            >
              <ButtonBase
                onClick={() => handleCardClick(course.link)}
                onMouseOver={() => setHoveredCard(index)}
                onMouseOut={() => setHoveredCard(null)}
                style={{ width: "100%", height: "100%" }}
              >
                <Card
                  style={{
                    height: "100%",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    ...((hoveredCard === index) && cardHoveredStyle),
                    backgroundColor: COLORS.GREY_COLOR
                  }}
                >
                  <CardMedia
                    component="img"
                    height="180px"
                    image={course.image}
                    alt={course.title}
                  />
                  <CardContent style={{ textAlign: "left"}}>
                    <Typography gutterBottom variant="h6" component="div" style={{color: COLORS.BACKGROUND_COLOR0, fontWeight: "700"}}>
                      {course.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {course.description}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ marginTop: "20px", color: COLORS.WHITE, background: COLORS.BACKGROUND_COLOR0, border: `2px solid ${COLORS.BACKGROUND_COLOR1}`,borderRadius: "10px", fontWeight: "500"}}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCardClick(course.link);
                      }}
                      sx={{fontSize: "12px" }}
                    >
                      Read More
                    </Button>
                  </CardContent>
                </Card>
              </ButtonBase>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Courses;
