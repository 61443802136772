import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  School as SchoolIcon,
  Gavel as GavelIcon,
  LocalHospital as LocalHospitalIcon,
  Spa as SpaIcon,
  Architecture as ArchitectureIcon,
  BusinessCenter as BusinessCenterIcon,
  MedicalServices as MedicalServicesIcon,
  Palette as PaletteIcon,
} from "@mui/icons-material";
import COLORS from "../ColorCodes/color";
import engineerImg from "../assets/engineer1.png";
import lawImg from "../assets/law1.png";
import MBBSImg from "../assets/MBBS.png";
import AggricultureImg from "../assets/agriculture.png";
import architectImg from "../assets/architect.png";
import managementImg from "../assets/management.png";
import medicalImg from "../assets/medical.png";
import artsImg from "../assets/arts.png";
import ReactCardFlip from "react-card-flip";
import { motion } from "framer-motion";


const iconStyle = {
  padding: "4px",
  fontSize: "24px",
  borderRadius: "10px",
  transition: "color 0.3s ease",
};

const tips = [
  {
    category: "Engineering",
    exams: [
      {
        name: "JEE MAINS",
        papers: [
          "JEE Main 2024 Question Paper with Solutions",
          "JEE Main 2023 Question Paper with Solutions",
          "JEE Main 2022 Question Papers with Solution",
          "JEE Main 2021 Question Paper with Solutions",
          "JEE Mains Question Papers 2020 pdf download",
          "JEE Main Question Papers 2019",
          "JEE Main Question Papers 2018",
          "JEE Main Question Papers 2017",
          "JEE Main Question Papers 2016",
        ],
      },
      { name: "JEE Advanced" },
      { name: "VIT EEE" },
      { name: "AEEE" },
      { name: "SRMJEE" },
      { name: "MET" },
      { name: "BITSAT" },
      { name: "PESSAT" },
      { name: "KIITEE" },
      { name: "COMED-K" },
      { name: "MHCET" },
      { name: "SNUSAT" },
    ],
    image: engineerImg,
  },
  {
    category: "Law",
    exams: [{ name: "CLAT", papers: ["2023", "2022", "2021"] }],
    image: lawImg,
  },
  {
    category: "MBBS",
    exams: [{ name: "NEET" }],
    image: MBBSImg,
  },
  {
    category: "Agriculture",
    exams: [{ name: "ICAR" }],
    image: AggricultureImg,
  },
  {
    category: "Architecture",
    exams: [{ name: "NATA" }],
    image: architectImg,
  },
  {
    category: "Management",
    exams: [
      { name: "TANCET" },
      { name: "CAT" },
      { name: "MAT" },
      { name: "CMAT" },
      { name: "XAT" },
      { name: "ATMA" },
      { name: "XLRI" },
      { name: "SNAP" },
      { name: "GMAT" },
      { name: "NMAT" },
      { name: "IIFT" },
      { name: "MMS" },
      { name: "TISSMAT" },
    ],
    image: managementImg,
  },
  {
    category: "Medical",
    exams: [{ name: "NEET" }],
    image: medicalImg,
  },
  {
    category: "Arts and Science",
    exams: [{ name: "CUET" }],
    image: artsImg,
  },
];

const EntranceExams = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [clickedCategory, setClickedCategory] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExamClick = (categoryIndex) => {
    if (selectedCategory === categoryIndex) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryIndex);
    }
    setClickedCategory(categoryIndex);
    setTimeout(() => setClickedCategory(null), 300);
  };

  const getIcon = (category) => {
    switch (category) {
    case "Engineering":
      return <SchoolIcon className="category-icon" style={iconStyle} />;
    case "Law":
      return <GavelIcon className="category-icon" style={iconStyle} />;
    case "MBBS":
      return <LocalHospitalIcon className="category-icon" style={iconStyle} />;
    case "Agriculture":
      return <SpaIcon className="category-icon" style={iconStyle} />;
    case "Architecture":
      return <ArchitectureIcon className="category-icon" style={iconStyle} />;
    case "Management":
      return <BusinessCenterIcon className="category-icon" style={iconStyle} />;
    case "Medical":
      return <MedicalServicesIcon className="category-icon" style={iconStyle} />;
    case "Arts and Science":
      return <PaletteIcon className="category-icon" style={iconStyle} />;
    default:
      return null;
    }
  };

  return (
    <div style={{ padding: "17px" }}>
      <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h2"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginTop: "10px",
              fontSize: "23px",
            }}
          >
            Entrance Exams
          </Typography>
          <hr
            style={{
              width: "10%",
              backgroundColor: COLORS.BACKGROUND_COLOR1,
              height: "4px",
              border: "none",
              margin: "10px 0",
            }}
          />
          <Typography
            variant="body1"
            align="justify"
            style={{ fontSize: "16px", marginTop: "20px" }}
            gutterBottom
          >
            Education consultancy services refer to professional assistance and
            guidance provided to individuals, institutions, or organizations in
            the field of education. These services can cover a wide range of
            areas, including academic advising, career counseling, admission
            assistance, curriculum development, institutional management, and
            more. Education consultants work to help clients make informed
            decisions and achieve their educational goals. Here are some common
            aspects of education consultancy services.
          </Typography>
        </Grid>
      </Grid>

      <div style={{ paddingTop: "10px" }}>
        <Grid container spacing={2}>
          {tips.map((category, categoryIndex) => (
            <Grid key={categoryIndex} item xs={12} sm={6} md={4} lg={3}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: categoryIndex * 0.1 }}
              >
                <ReactCardFlip
                  isFlipped={selectedCategory === categoryIndex}
                  flipDirection="vertical"
                >
                  {/* Front side of the card */}
                  <Card
                    onClick={() => handleExamClick(categoryIndex)}
                    style={{
                      cursor: "pointer",
                      marginBottom: "20px",
                      transition: "transform 0.3s",
                      background: COLORS.GREY_COLOR,
                      padding: "10px",
                      border: `2px dashed ${COLORS.BACKGROUND_COLOR1}`,
                      transform:
                          clickedCategory === categoryIndex
                            ? "scale(0.95)"
                            : "scale(1)",
                    }}
                    className="hover-effect"
                  >
                    <CardMedia
                      component="img"
                      image={category.image}
                      alt={category.category}
                      style={{ height: 210 }}
                    />
                    <CardContent
                      style={{
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        {getIcon(category.category)}
                        <Typography
                          variant="h6"
                          className="category-text"
                          style={{
                            fontWeight: "bold",
                            marginLeft: "10px",
                            transition: "color 0.3s ease",
                          }}
                        >
                          {category.category}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>

                  {/* Back side of the card */}
                  <Card
                    style={{
                      display:
                          selectedCategory === categoryIndex ? "block" : "none",
                      height: "300px",
                      overflowY: "auto",
                      padding: "10px",
                      background: COLORS.GREY_COLOR,
                      border: "1px solid #202c45",
                    }}
                  >
                    <CardContent>
                      <Typography
                        style={{ fontSize: "18px", fontWeight: "600", color: COLORS.BACKGROUND_COLOR1 }}
                      >
                          Exams for {category.category}
                      </Typography>
                      <List style={{ fontSize: "16px" }}>
                        {category.exams.map((exam, index) => (
                          <ListItem key={index}>
                            <ListItemText  primary={`❖ ${exam.name}`} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </ReactCardFlip>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </div>
      <style>{`
        .hover-effect:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          transform: scale(1.05);
        }

  &:hover .category-icon {
    color: ${COLORS.BACKGROUND_COLOR0};
  }

  &:hover .category-text {
    color: ${COLORS.BACKGROUND_COLOR0};
  }
      `}</style>
    </div>
  );
};

export default EntranceExams;
