import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  Card,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import login from "../assets/login_green.png";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { SessionContext } from "./SessionContext";
import ResetPasswordWithoutVerify from "./passwords/ForgotPassword";
import COLORS from "../ColorCodes/color";

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number must be numeric")
    .required("Mobile number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
});

const LoginQuestion = ({ setOpen }) => {
  const { isSessionExpired, setIsSessionExpired } = useContext(SessionContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [backendError, setBackendError] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const initialValues = {
    mobileNumber: "",
    password: "",
  };

  useEffect(() => {
    setOpen(false);
  }, [navigate]);

  const handleLogin = async (values, { setSubmitting }) => {
    try {
      const loginResponse = await axios.post("http://localhost:5000/api/login", values);
      const { token, name, email, phone } = loginResponse.data;

      localStorage.setItem("jwtToken", token);
      localStorage.setItem("userDetails", JSON.stringify({ name, email, phone }));
      setIsSessionExpired(false);
      navigate("/questionBank");
    } catch (error) {
      if (error.response && error.response.data) {
        setBackendError(error.response.data.message || "An error occurred during login.");
      } else {
        setBackendError("Error: Unable to process your request.");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNumberChange = (e, handleChange, setBackendError) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      handleChange(e);
      setBackendError("");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isMediumScreen ? "0px" : "60px",
        background: COLORS.GREY_COLOR,
      }}
    >
      <Card
        style={{
          width: isMediumScreen ? "100%" : "70%",
          maxWidth: "1100px",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={12}
            lg={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                background: COLORS.LIGHTER_TEAL
              }}
            >
              <img src={login} alt="" style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "90%",
                padding: "20px",
              }}
            >
              {!isSessionExpired && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Your session has expired. Please login again.
                </Alert>
              )}
              {showForgotPassword ? (
                <ResetPasswordWithoutVerify setShowForgotPassword={setShowForgotPassword} setOpen={setOpen} />
              ) : (
                <>
                  <Typography sx={{ mb: 1, color: COLORS.BACKGROUND_COLOR0, fontWeight: "600", fontSize: "17px" }}>
                    Login to View Question Bank
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", marginBottom: "10px", textAlign: "center" }}
                  >
                    Enter your details to login into your account
                  </Typography>
                  {backendError && <Alert severity="error" sx={{ mb: 2 }}>{backendError}</Alert>}
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                  >
                    {({ errors, touched, handleChange, handleBlur }) => (
                      <Form style={{ width: "100%" }}>
                        <Field
                          as={TextField}
                          fullWidth
                          id="mobileNumber"
                          name="mobileNumber"
                          label="Mobile Number"
                          autoComplete="tel"
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                          error={errors.mobileNumber && touched.mobileNumber}
                          helperText={errors.mobileNumber && touched.mobileNumber ? errors.mobileNumber : ""}
                          onChange={(e) => handleNumberChange(e, handleChange, setBackendError)}
                          onBlur={handleBlur}
                          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                        <Field
                          as={TextField}
                          fullWidth
                          id="password"
                          name="password"
                          label="Password"
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                          type={showPassword ? "text" : "password"}
                          autoComplete="current-password"
                          error={errors.password && touched.password}
                          helperText={errors.password && touched.password ? errors.password : ""}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            handleChange(e);
                            setBackendError("");
                          }}
                          onBlur={handleBlur}
                        />
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "12px", float: "left", color: "#183867", cursor: "pointer" }}
                          onClick={() => setShowForgotPassword(true)}
                        >
                          Forgot password?
                        </Typography>
                        <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: COLORS.BACKGROUND_COLOR0, "&:hover": { backgroundColor: COLORS.BACKGROUND_COLOR1, color: COLORS.BLACK, fontWeight: "700" } }} type="submit">
                          Login
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                      Don&apos;t have an account?
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        background: COLORS.LIGHTER_TEAL,
                        display: "inline-block",
                        padding: "5px 10px",
                        color: COLORS.BLACK,
                        fontWeight: "700",
                        cursor: "pointer",
                        borderRadius: "10px",
                        "&:hover": {
                          background: COLORS.BACKGROUND_COLOR1,
                          color: COLORS.BLACK,
                        },
                      }}
                      onClick={() => navigate("/register")}
                    >
                      Register Here
                    </Typography>
                  </div>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default LoginQuestion;
