import React, { useState, useEffect, useRef} from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CardMedia,
  InputAdornment,
  TextField,Breadcrumbs, Link
} from "@mui/material";
import law from "../../assets/courses/law.avif";
import law1 from "../../assets/coursesDetail/Law/2.jpg";
import law2 from "../../assets/coursesDetail/Law/3.jpg";
import law3 from "../../assets/coursesDetail/Law/4.jpg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import COLORS from "../../ColorCodes/color";

const disciplines = [
  {
    id: "law1",
    title: "BA LLB",
    description:
        "is an integrated undergraduate course that combines the study of Bachelor of Arts (BA) and Bachelor of Laws (LLB). It provides students with a comprehensive understanding of the legal system along with insights into various subjects of arts.",
    scope: [
      "Lawyer",
      "Legal Advisor",
      "Legal Consultant",
      "Judge (after further studies)",
      "Corporate Counsel",
      "Human Rights Advocate",
      "Law Professor"
    ],
    salary: "₹3,00,000 - ₹6,00,000 per year",
    imageSrc: law1
  },
  {
    id: "law2",
    title: "B.COM LLB",
    description:
        "is an integrated undergraduate course that combines the study of Bachelor of Commerce (B.Com) and Bachelor of Laws (LLB). It provides students with a strong foundation in both commerce and law, preparing them for a career in legal practice with a focus on commercial aspects.",
    scope: [
      "Corporate Lawyer",
      "Tax Consultant",
      "Legal Advisor (Finance)",
      "Compliance Officer",
      "Company Secretary",
      "Contract Specialist",
      "Legal Auditor"
    ],
    salary: "₹3,50,000 - ₹7,00,000 per year",
    imageSrc: law2
  },
  {
    id: "law3",
    title: "B.SC LLB",
    description:
        "is an integrated undergraduate course that combines the study of Bachelor of Science (B.Sc) and Bachelor of Laws (LLB). It offers students a unique blend of scientific knowledge and legal expertise, enabling them to specialize in areas such as environmental law, patent law, or intellectual property law.",
    scope: [
      "Patent Attorney",
      "Environmental Lawyer",
      "Intellectual Property Consultant",
      "Legal Analyst (Science)",
      "Technology Lawyer",
      "Forensic Scientist (Legal)",
      "Legal Researcher"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: law3
  },
];

const Law = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDisciplines, setFilteredDisciplines] = useState(disciplines);

  useEffect(() => {
    const handleFiltering = () => {
      if (!searchTerm.trim()) {
        setFilteredDisciplines(disciplines);
        return;
      }
      const filtered = disciplines.filter(discipline =>
        discipline.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDisciplines(filtered);
    };
    handleFiltering();
  }, [searchTerm, disciplines]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCardClick = (index,name) => {
    setSelectedCard(selectedCard === index ? null : index);
    navigate(`/courses/law/${name}`);
  };

  const CardComponent = () => {
    return (
      <div style={{ background: COLORS.GREY_COLOR, paddingLeft: "15px", paddingRight: "15px", paddingBottom: "22px" }}>
        {filteredDisciplines.length === 0 ? (
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            No courses found
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredDisciplines.map((discipline, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    cursor: "pointer",
                    transition: "box-shadow 0.3s ease-in-out",
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BACKGROUND_COLOR0,
                    "&:hover": {
                      boxShadow: 10,
                      color: COLORS.BACKGROUND_COLOR1
                    },
                  }}
                  onClick={() => handleCardClick(index, discipline.title)}
                >
                  <CardMedia
                    component="img"
                    image={discipline.imageSrc}
                    alt={discipline.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {discipline.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };
  return (
    <Box sx={{ padding: "36px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/courses"  color={COLORS.MUTED}>
          Courses
        </Link>
        <Link component={RouterLink} to="/courses/law" color={COLORS.BACKGROUND_COLOR1}>
        Law
        </Link>
      </Breadcrumbs>
      <div style={{  background: COLORS.GREY_COLOR, marginTop: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={titleRef}
            variant="h1"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginBottom: "10px",
              fontSize: "25px",
              textAlign: "center",
              background: COLORS.BACKGROUND_COLOR0,
              padding: "10px",
              color: COLORS.BACKGROUND_COLOR2,
              flexGrow: 1,
            }}
          >
           Law
          </Typography>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
          <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
          A law course is an educational program that delves into legal principles, systems, and practices. Designed to impart a thorough understanding of the legal framework, it covers diverse areas such as criminal and civil law. Aimed at preparing individuals for legal professions, the curriculum combines theoretical studies with practical exercises, providing a comprehensive foundation in the field of law.
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: "10px", textAlign: "justify" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Career Opportunities:</strong> Completing a law course opens doors to various legal professions, including practicing law, becoming a legal consultant, or working in policymaking roles.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Critical Thinking Skills:</strong> Law courses cultivate strong analytical and critical thinking skills, essential for legal reasoning, problem-solving, and effective decision-making.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Influence and Advocacy:</strong> A legal education equips individuals with the knowledge and skills to advocate for justice, influence policies, and contribute to positive societal changes.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Diverse Specializations:</strong> Law courses offer opportunities to specialize in diverse areas such as criminal law, corporate law, environmental law, and more, allowing for a tailored career path.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Global Perspective:</strong> Legal education provides an understanding of international law, fostering a global perspective and enabling professionals to navigate legal issues in an interconnected world.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <img src={law} alt="law" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: "10px", paddingRight:"10px"}}>
          <h2 style={{color: COLORS.BACKGROUND_COLOR0}}>COURSES OFFERED ({disciplines.length} courses)</h2>
          <TextField
            placeholder="Search courses..."
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box sx={{ mt: 4 }}>
          <CardComponent />
        </Box>
      </div>
    </Box>
  );
};

export default Law;
