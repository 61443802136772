import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  createTheme,
  ThemeProvider,
  Alert,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import districtsData from "./district.json";
import modalImg from "../assets/modalImg.jpg";
import COLORS from "../ColorCodes/color";

export default function FormDialog({ open, setOpen }) {
  const [error, setError] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const themestyle = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#202c45",
            fontSize: "16px",
            fontWeight: "500",
            "&.Mui-focused": {
              color: "#202c45",
            },
            "&.MuiInputLabel-shrink": {
              color: "#202c45",
            },
          },
          asterisk: {
            color: "red",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "red",
          },
        },
      },
    },
  });

  const handleClose = () => {
    setOpen(false);
    setError("");
    formik.resetForm();
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue("phone", value.replace(/\D/g, ""));
    setError("");
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const preparedValues = {
      ...values,
      school: values.school || "",
      preferredCollege: values.preferredCollege || "",
      district: values.district || "",
      message: values.message || "",
    };

    try {
      const response = await axios.post("http://localhost:5000/api/contact", preparedValues);
      console.log(response, "response");
      setError("");
      alert("Form submitted successfully!");
      resetForm();
      handleClose();
    } catch (error) {
      let errorMessage = error.response?.data?.message || "An error occurred";
      if (error.response && error.response.data && Array.isArray(error.response.data.errors)) {
        const validationErrors = error.response.data.errors;
        if (validationErrors.length > 0) {
          errorMessage = validationErrors[0].message || errorMessage;
        }
      }
      setError(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      city: "",
      preferredCourse: "",
      email: "",
      school: "",
      preferredCollege: "",
      district: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phone: Yup.string().matches(/^\d{10}$/, "Phone number must be 10 digits").required("Phone is required"),
      city: Yup.string().required("City is required"),
      preferredCourse: Yup.string().required("Preferred Course is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      school: Yup.string().nullable(),
      preferredCollege: Yup.string().nullable(),
      district: Yup.string().nullable(),
      message: Yup.string().nullable(),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <ThemeProvider theme={themestyle}>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: formik.handleSubmit,
        }}
        fullScreen={fullScreen}
        maxWidth="md"
      >
        <div style={{ background: COLORS.BACKGROUND_COLOR0 }}>
          <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                textAlign: "left",
                color: COLORS.WHITE,
                fontWeight: "600",
                fontSize: "17px",
              }}
            >
              Free Career Guidance Form
            </span>
            <CloseIcon
              style={{
                cursor: "pointer",
                marginTop: "5px",
                fontSize: "20px",
                backgroundColor: "transparent",
                transition: "background-color 0.3s ease",
                borderRadius: "20px",
                textAlign: "right",
                color: COLORS.WHITE,
              }}
              onClick={handleClose}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "black";
                e.target.style.color = COLORS.WHITE;
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = COLORS.TEXTCOLOR;
              }}
            />
          </DialogTitle>
        </div>
        <DialogContent>
          <Typography
            variant="h2"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginTop: "10px",
              fontSize: "23px",
              color: COLORS.BACKGROUND_COLOR0,
            }}
          >
            Lets Connect
          </Typography>
          <img src={modalImg} alt="" style={{ width: "100%", maxHeight: "20%", marginTop: "10px" }} />
          <Typography
            style={{
              marginBottom: "10px",
              marginTop: "10px",
              color: "black",
              fontSize: "14px",
            }}
          >
            Please fill and submit the form to connect with us
          </Typography>
          <div style={{ border: "2px solid #dcdcdc", padding: "20px" }}>
            {error && (
              <Alert severity="error" style={{ marginBottom: "10px" }}>
                {error}
              </Alert>
            )}

            <Grid container spacing={2}>
              {/* Your form fields */}
              <Grid item xs={12} sm={6}>
                <div>
                  <TextField
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="filled"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div style={{ color: "red" }}>{formik.errors.name}</div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <TextField
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="filled"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div style={{ color: "red" }}>{formik.errors.email}</div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <TextField
                    margin="dense"
                    id="phone"
                    name="phone"
                    label="Phone"
                    type="tel"
                    fullWidth
                    required
                    variant="filled"
                    value={formik.values.phone}
                    onChange={handlePhoneChange} // Update the handler to filter input
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div style={{ color: "red" }}>{formik.errors.phone}</div>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div>
                  <TextField
                    margin="dense"
                    id="school"
                    name="school"
                    label="School"
                    fullWidth
                    variant="filled"
                    value={formik.values.school}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.school && formik.errors.school && (
                    <div style={{ color: "red" }}>{formik.errors.school}</div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <TextField
                    margin="dense"
                    id="preferredCollege"
                    name="preferredCollege"
                    label="Preferred College"
                    fullWidth
                    variant="filled"
                    value={formik.values.preferredCollege}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.preferredCollege && formik.errors.preferredCollege && (
                    <div style={{ color: "red" }}>{formik.errors.preferredCollege}</div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <TextField
                    required
                    margin="dense"
                    id="preferredCourse"
                    name="preferredCourse"
                    label="Preferred Course"
                    fullWidth
                    variant="filled"
                    value={formik.values.preferredCourse}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.preferredCourse && formik.errors.preferredCourse && (
                    <div style={{ color: "red" }}>{formik.errors.preferredCourse}</div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <FormControl fullWidth margin="dense" variant="filled">
                    <InputLabel id="district-label">District</InputLabel>
                    <Select
                      labelId="district-label"
                      id="district"
                      name="district"
                      label="District"
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {districtsData.districts.map((district, index) => (
                        <MenuItem key={index} value={district}>
                          {district}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.district && formik.errors.district && (
                      <div style={{ color: "red" }}>{formik.errors.district}</div>
                    )}
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <TextField
                    required
                    margin="dense"
                    id="city"
                    name="city"
                    label="City"
                    fullWidth
                    variant="filled"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div style={{ color: "red" }}>{formik.errors.city}</div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <TextField
                    margin="dense"
                    id="message"
                    name="message"
                    label="Message"
                    multiline
                    rows={4}
                    fullWidth
                    variant="filled"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <div style={{ color: "red" }}>{formik.errors.message}</div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                marginTop: "10px",
                backgroundColor: COLORS.BACKGROUND_COLOR0,
                color: COLORS.WHITE,
                fontSize: "15px",
                "&:hover": {
                  backgroundColor: COLORS.BACKGROUND_COLOR0,
                  fontWeight: "700",
                },
              }}
            >
          Submit
            </Button>
          </div>
        </DialogContent>

      </Dialog>
    </ThemeProvider>
  );
}
