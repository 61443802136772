import React, { useState } from "react";
import { Grid, TextField, Button, InputAdornment, Typography, Box, MenuItem, Snackbar, Alert } from "@mui/material";
import { AccountCircle, Email as EmailIcon, Phone as PhoneIcon, Message as MessageIcon, LocationCity as LocationCityIcon, School as SchoolIcon } from "@mui/icons-material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BookIcon from "@mui/icons-material/Book";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import COLORS from "../../ColorCodes/color";
import districtsData from "../../components/district.json";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().matches(/^\d{10}$/, "Phone number must be 10 digits").required("Phone is required"),
  city: Yup.string().required("City is required"),
  school: Yup.string().required("School is required"),
  preferredCourse: Yup.string().required("Preferred course is required"),
});

const ContactForm = ({ isMobile, isTab }) => {
  const [hoverReset, setHoverReset] = useState(false);
  const [hoverRegister, setHoverRegister] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handlePhoneChange = (e, formik) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, "");
    formik.setFieldValue("phone", numericValue);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post("http://localhost:5000/api/contact", values);
      console.log(response, "response");
      setSnackbarMessage("Form submitted successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      resetForm();
    } catch (error) {
      setSnackbarMessage("Error submitting form. Please try again.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const buttonStyle = {
    transition: "transform 0.3s ease",
    background: COLORS.BACKGROUND_COLOR0,
    color: COLORS.WHITE,
  };

  return (
    <div style={{ padding: "5px", marginTop: "0px" }}>
      <Box sx={{ marginTop: isMobile || isTab ? "24px" : "15px", marginBottom: "20px", padding: "20px", border: `3px solid ${COLORS.BACKGROUND_COLOR0}`, boxShadow: 3 }}>
        <Typography
          variant="body1"
          align="justify"
          sx={{
            fontSize: "14px",
            marginTop: "10px",
            marginBottom: "30px"
          }}
          gutterBottom
        >
          Please fill and submit the form to contact us
        </Typography>
        <Formik
          initialValues={{
            name: "",
            email: "",
            school: "",
            phone: "",
            district: "",
            city: "",
            preferredCourse: "",
            preferredCollege: "",
            message: ""
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="name"
                    label="Name"
                    variant="outlined"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="phone"
                    label="Phone"
                    variant="outlined"
                    type="tel"
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                      inputProps: {
                        pattern: "[0-9]*",
                        inputMode: "numeric",
                      },
                    }}
                    onChange={(e) => handlePhoneChange(e, { setFieldValue })}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="school"
                    label="School"
                    variant="outlined"
                    error={touched.school && Boolean(errors.school)}
                    helperText={touched.school && errors.school}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SchoolIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="preferredCourse"
                    label="Preferred Course"
                    variant="outlined"
                    error={touched.preferredCourse && Boolean(errors.preferredCourse)}
                    helperText={touched.preferredCourse && errors.preferredCourse}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BookIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="preferredCollege"
                    label="Preferred College"
                    variant="outlined"
                    error={touched.preferredCollege && Boolean(errors.preferredCollege)}
                    helperText={touched.preferredCollege && errors.preferredCollege}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SchoolIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="district"
                    label="District"
                    variant="outlined"
                    select
                    error={touched.district && Boolean(errors.district)}
                    helperText={touched.district && errors.district}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationCityIcon />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {districtsData.districts.map((district, index) => (
                      <MenuItem key={index} value={district}>
                        {district}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="city"
                    label="City"
                    variant="outlined"
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ApartmentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="message"
                    label="Message"
                    variant="outlined"
                    multiline
                    rows={4}
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MessageIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    type="reset"
                    disabled={isSubmitting}
                    style={{
                      ...buttonStyle,
                      marginRight: "10px",
                      color: hoverReset ? COLORS.BACKGROUND_COLOR1 : COLORS.WHITE,
                      border: hoverReset ? `1px solid ${COLORS.BACKGROUND_COLOR1}` : null,
                      transform: hoverReset ? "scale(1.15)" : "scale(1)",
                    }}
                    onMouseEnter={() => setHoverReset(true)}
                    onMouseLeave={() => setHoverReset(false)}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    style={{
                      ...buttonStyle,
                      background: hoverRegister ? COLORS.BACKGROUND_COLOR1 : COLORS.BACKGROUND_COLOR0,
                      transform: hoverRegister ? "scale(1.15)" : "scale(1)",
                    }}
                    onMouseEnter={() => setHoverRegister(true)}
                    onMouseLeave={() => setHoverRegister(false)}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactForm;
