import React, { useEffect } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography, Card, CardContent, Grid, List, ListItem, ListItemText, ListItemIcon, Button } from "@mui/material";
import { FiberManualRecord as FiberManualRecordIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import health1 from "../../assets/coursesDetail/Arts & Science/27.jpg";
import health2 from "../../assets/coursesDetail/Arts & Science/28.jpg";
import health3 from "../../assets/coursesDetail/Arts & Science/29.jpg";
import health4 from "../../assets/coursesDetail/Arts & Science/30.jpg";
import health5 from "../../assets/coursesDetail/Arts & Science/31.jpg";
import health6 from "../../assets/coursesDetail/Arts & Science/8.jpg";
import health7 from "../../assets/coursesDetail/Arts & Science/32.jpg";
import health8 from "../../assets/coursesDetail/Arts & Science/33.jpg";
import health9 from "../../assets/coursesDetail/Arts & Science/34.jpg";
import health10 from "../../assets/coursesDetail/Arts & Science/35.jpg";
import health11 from "../../assets/coursesDetail/Arts & Science/36.jpg";
import health12 from "../../assets/coursesDetail/Arts & Science/37.jpg";
import health13 from "../../assets/coursesDetail/Arts & Science/38.jpg";
import health14 from "../../assets/coursesDetail/Arts & Science/39.jpg";
import health15 from "../../assets/coursesDetail/Arts & Science/40.jpg";
import health16 from "../../assets/coursesDetail/Arts & Science/41.jpg";
import COLORS from "../../ColorCodes/color";

const disciplines = [
  {
    id: "health1",
    title: "B.SC.(DIALYSIS TECHNOLOGY)",
    description: "is an undergraduate program that focuses on the study of dialysis procedures, equipment, and patient care. Students learn about renal physiology, dialysis techniques, and hemodialysis machines.",
    scope: [
      "Dialysis Technician",
      "Renal Nurse",
      "Dialysis Unit Manager",
      "Clinical Research Coordinator (Dialysis)",
      "Nephrology Technologist",
      "Dialysis Equipment Sales Representative",
      "Dialysis Center Administrator"
    ],
    salary: "₹3,00,000 - ₹5,50,000 per year",
    imageSrc: health1
  },
  {
    id: "health2",
    title: "B.SC.(PHYSICIAN ASSISTANT)",
    description: "is a degree program that trains students to work as part of a healthcare team, assisting physicians in patient care. Students learn about allied-health-science terminology, diagnostic procedures, and treatment protocols.",
    scope: [
      "Physician Assistant",
      "Medical Assistant",
      "Surgical Assistant",
      "Emergency Medicine Technician",
      "Clinical Research Coordinator",
      "Healthcare Administrator",
      "Community Health Worker"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health2
  },
  {
    id: "health3",
    title: "B.SC.(RADIOGRAPHY & IMAGING TECHNOLOGY)",
    description: "is a program that focuses on allied-health-science imaging techniques such as X-ray, CT scan, MRI, and ultrasound. Students learn about radiation safety, image interpretation, and patient positioning.",
    scope: [
      "Radiologic Technologist",
      "MRI Technologist",
      "CT Technologist",
      "Ultrasound Technologist",
      "Nuclear Medicine Technologist",
      "Radiography Supervisor",
      "Imaging Equipment Sales Representative"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health3
  },
  {
    id: "health4",
    title: "B.SC.(CARDIO PULMONARY & PERFUSION TECHNOLOGY)",
    description: "is a degree program that focuses on heart and lung function, as well as extracorporeal circulation techniques. Students learn about cardiac catheterization, pulmonary function testing, and cardiopulmonary bypass.",
    scope: [
      "Perfusionist",
      "Cardiovascular Technician",
      "Respiratory Therapist",
      "Cardiac Cath Lab Technologist",
      "Pulmonary Function Technologist",
      "Clinical Research Coordinator (Cardiology)",
      "Medical Equipment Specialist"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: health4
  },
  {
    id: "health5",
    title: "B.SC.(OPERATION THEATRE & ANESTHESIA TECHNOLOGY)",
    description: "is a program that focuses on surgical procedures, anesthesia administration, and operating room management. Students learn about sterile techniques, anesthesia equipment, and patient monitoring.",
    scope: [
      "Operation Theatre Technician",
      "Anesthesia Technologist",
      "Surgical Assistant",
      "Anesthesia Assistant",
      "Surgical Scrub Nurse",
      "Operating Room Manager",
      "Surgical Equipment Sales Representative"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health5
  },
  {
    id: "health6",
    title: "B.SC.(OPTOMETRY)",
    description: "is an undergraduate program that focuses on eye care and vision science. Students learn about eye anatomy, vision disorders, diagnostic techniques, and optometric practice management.",
    scope: [
      "Optometric Practice",
      "Ophthalmic Research",
      "Contact Lens Optometry",
      "Pediatric Optometry",
      "Low Vision Rehabilitation",
      "Vision Therapy",
      "Public Health Optometry"
    ],
    salary: "₹4,00,000 - ₹6,00,000 per year",
    imageSrc: health6
  },
  {
    id: "health7",
    title: "B.SC.(CARDIAC TECHNOLOGY)",
    description: "is an undergraduate program that focuses on the study of heart diseases, diagnostic tests, and cardiac monitoring techniques. Students learn about electrocardiography, echocardiography, and stress testing.",
    scope: [
      "Cardiac Technologist",
      "Echocardiography Technologist",
      "Cardiac Cath Lab Technologist",
      "Holter Monitor Technician",
      "Stress Test Technologist",
      "Pacemaker Technician",
      "Cardiac Rehabilitation Specialist"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health7
  },
  {
    id: "health8",
    title: "B.SC.(CARDIOVASCULAR TECHNOLOGY)",
    description: "is a degree program that focuses on the diagnosis and treatment of cardiovascular diseases. Students learn about vascular imaging, hemodynamics, and invasive cardiac procedures.",
    scope: [
      "Cardiovascular Technologist",
      "Vascular Technologist",
      "Echocardiography Technologist",
      "Cardiac Cath Lab Technologist",
      "Invasive Cardiovascular Specialist",
      "Clinical Research Coordinator (Cardiology)",
      "Cardiovascular Equipment Sales Representative"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: health8
  },
  {
    id: "health9",
    title: "B.SC.(MEDICAL LAB TECHNOLOGY)",
    description: "is a program that focuses on laboratory procedures and techniques used in allied-health-science diagnosis and treatment. Students learn about microbiology, hematology, clinical chemistry, and immunology.",
    scope: [
      "Medical Laboratory Technologist",
      "Clinical Laboratory Scientist",
      "Pathology Technician",
      "Blood Bank Technologist",
      "Molecular Biologist",
      "Laboratory Supervisor",
      "Quality Assurance Specialist"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health9
  },
  {
    id: "health10",
    title: "B.SC.(CLINICAL PSYCHOLOGY)",
    description: "is a degree program that focuses on the study of psychological disorders, therapy techniques, and mental health assessment. Students learn about counseling methods, psychotherapy, and psychological testing.",
    scope: [
      "Clinical Psychologist",
      "Counseling Psychologist",
      "Mental Health Counselor",
      "Behavioral Therapist",
      "Rehabilitation Counselor",
      "Child Psychologist",
      "Psychiatric Technician"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health10
  },
  {
    id: "health11",
    title: "B.SC.(ANATOMY)",
    description: "is an undergraduate program that focuses on the study of the structure and function of the human body. Students learn about anatomical principles, organ systems, and allied-health-science imaging techniques.",
    scope: [
      "Anatomist",
      "Medical Illustrator",
      "Forensic Scientist",
      "Surgical Technician",
      "Medical Writer",
      "Anatomical Model Maker",
      "Healthcare Educator"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health11
  },
  {
    id: "health12",
    title: "B.SC.(BIOCHEMISTRY)",
    description: "is a degree program that focuses on the study of chemical processes within living organisms. Students learn about molecular biology, metabolism, and biochemical pathways.",
    scope: [
      "Biochemist",
      "Pharmaceutical Research Scientist",
      "Clinical Biochemist",
      "Biotechnology Analyst",
      "Food Scientist",
      "Forensic Scientist",
      "Molecular Biologist"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: health12
  },
  {
    id: "health13",
    title: "B.SC.(NEURO ELECTRO PHYSIOLOGY)",
    description: "is a program that focuses on the study of electrical activity in the nervous system. Students learn about neurophysiology, electroencephalography (EEG), and nerve conduction studies.",
    scope: [
      "Neurophysiologist",
      "EEG Technologist",
      "Neurodiagnostic Technologist",
      "Clinical Neurophysiologist",
      "Neuropsychologist",
      "Electrophysiology Technician",
      "Neurology Research Assistant"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: health13
  },
  {
    id: "health14",
    title: "B.SC.(CLINICAL NUTRITION & DIETETICS)",
    description: "is a degree program that focuses on the study of nutrition science and its application in clinical settings. Students learn about dietary assessment, therapeutic nutrition, and nutrition counseling.",
    scope: [
      "Clinical Dietitian",
      "Nutrition Consultant",
      "Wellness Coach",
      "Public Health Nutritionist",
      "Sports Nutritionist",
      "Food Service Manager",
      "Research Dietitian"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health14
  },
  {
    id: "health15",
    title: "B.SC.(MICROBIOLOGY)",
    description: "is an undergraduate program that focuses on the study of microorganisms, including bacteria, viruses, fungi, and parasites. Students learn about microbial physiology, immunology, and infection control.",
    scope: [
      "Microbiologist",
      "Clinical Microbiologist",
      "Food Microbiologist",
      "Industrial Microbiologist",
      "Environmental Microbiologist",
      "Biotechnology Research Scientist",
      "Quality Control Microbiologist"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health15
  },
  {
    id: "health16",
    title: "B.SC.(PSYCHOLOGY)",
    description: "is a degree program that focuses on the study of human behavior, mental processes, and emotional responses. Students learn about psychological theories, research methods, and therapeutic techniques.",
    scope: [
      "Psychologist",
      "Counselor",
      "Human Resources Specialist",
      "Market Research Analyst",
      "Educational Psychologist",
      "Forensic Psychologist",
      "Mental Health Technician"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health16
  }
];


const AlliedHealthScienceDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const disciplineDetails = disciplines.find((discipline) => discipline.title === name);
  if (!disciplineDetails) {
    return <Typography variant="h5">Courses not found.</Typography>;
  }


  const { title, description, scope, salary } = disciplineDetails;

  const currentIndex = disciplines.findIndex((discipline) => discipline.title === name);
  const prevDiscipline = currentIndex > 0 ? disciplines[currentIndex - 1] : null;
  const nextDiscipline = currentIndex < disciplines.length - 1 ? disciplines[currentIndex + 1] : null;

  return (
    <Box sx={{ padding: "36px" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/courses"  color={COLORS.MUTED}>
            Courses
          </Link>
          <Link component={RouterLink} to="/courses/allied-health-science"  color={COLORS.MUTED}>
            Allied Health Science
          </Link>
          <Typography color={COLORS.BACKGROUND_COLOR1} style={{fontWeight: "600"}}>{title}</Typography>
        </Breadcrumbs>
        <Box sx={{ display: { xs: "flex", md: "none" }, gap: "18px", marginTop: "10px"}}>
          {prevDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={RouterLink}
              to={`/courses/allied-health-science/${prevDiscipline.title}`}
            >
              Prev
            </Button>
          )}
          {nextDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              size="small"
              endIcon={<ArrowForwardIcon />}
              component={RouterLink}
              to={`/courses/allied-health-science/${nextDiscipline.title}`}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>

      <Card style={{ textAlign: "justify", height: "100%", marginTop: "20px",backgroundColor:COLORS.GREY_COLOR}}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Text content column */}
            <Grid item xs={12} sm={9} md={7} order={{ xs: 1, md: 1 }}>
              {/* Title and description */}
              <Typography variant="h6" gutterBottom style={{ marginTop: "10px",  fontFamily: COLORS.FONT,fontWeight: "600", textAlign: "left", color: COLORS.BACKGROUND_COLOR0 }}>
                {title}
              </Typography>
              <hr
                style={{
                  width: "15%",
                  backgroundColor: COLORS.BACKGROUND_COLOR1,
                  height: "4px",
                  border: "none",
                  margin: "5px 0",
                }}
              />
              <Typography variant="body1" paragraph style={{marginTop : "20px"}}>
                {title} {description}
              </Typography>

              {/* Scope */}
              <Typography variant="body1">
                <strong style={{color: COLORS.STRONG_COLOR}}>Scope:</strong>              </Typography>
              <List dense>
                {scope.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon style={{ minWidth: "24px" }}>
                      <FiberManualRecordIcon style={{ fontSize: "13px" }} />
                    </ListItemIcon>
                    <ListItemText primary={item} disableTypography />
                  </ListItem>
                ))}
              </List>

              {/* Salary range */}
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                <strong style={{color: COLORS.STRONG_COLOR}}>Salary:</strong>{" "}
                <span style={{padding: "3px 6px", borderRadius: "5px" }}>{salary}</span>
              </Typography>
            </Grid>

            {/* Image column */}
            <Grid item xs={12} sm={3} md={5} order={{ xs: 2, md: 2 }}>
              <Box sx={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "baseline", height: "100%", marginTop: { xs: "10px", md: "30px" } }}>
                <img src={disciplineDetails.imageSrc} alt={title} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BLACK,
                    padding: "10px 20px",
                    marginTop: "30px",
                    transition: "transform 0.3s ease",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100%"
                  }}
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1)";
                  }}
                  endIcon={<ArrowRightAltIcon style={{ backgroundColor: COLORS.BACKGROUND_COLOR2, color: COLORS.BLACK }} />}
                >
          Contact Us
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "space-between", marginTop: "20px" }}>
            {prevDiscipline && (
              <Button
                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                startIcon={<ArrowBackIcon />}
                component={RouterLink}
                to={`/courses/allied-health-science/${prevDiscipline.title}`}
              >
                Previous Course
              </Button>
            )}
            {nextDiscipline && (
              <Button
                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                endIcon={<ArrowForwardIcon />}
                component={RouterLink}
                to={`/courses/allied-health-science/${nextDiscipline.title}`}
              >
                Next Course
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AlliedHealthScienceDetail;
