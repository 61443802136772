import React, { useState } from "react";
import {
  Card,
  TextField,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Alert,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import districtsData from "../components/district.json";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import COLORS from "../ColorCodes/color";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Invalid mobile number"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      "Password must contain at least 6 characters, one uppercase, one lowercase, one number and one special character"
    ),
  preferredCourse: Yup.string().required("Preferred Course is required"),
  school: Yup.string().required("School is required"),
  city: Yup.string().required("City is required"),
});

const RegisterQuestion = () => {
  const initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    dob: "",
    school: "",
    preferredCourse: "",
    preferredCollege: "",
    district: "",
    city: "",
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [showPassword, setShowPassword] = useState(false);
  const [registrationError, setRegistrationError] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [isRegister, setIsRegister] = useState(false);

  const handleLoginPage = () => {
    navigate("/login");
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {

      const registerResponse = await axios.post(
        "http://localhost:5000/api/register",
        values
      );
      console.log(registerResponse.data);

      setRegistrationError("Registration successful. Redirecting to login...");
      setIsRegister(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
      setSubmitting(false);

    } catch (error) {
      console.error("Error:", error);
      setRegistrationError(error.response?.data?.message || "Registration failed");
      setAlertOpen(true);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleNumberChange = (e, handleChange) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      handleChange(e);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isMediumScreen ? "0px" : "60px",
        backgroundColor: COLORS.GREY_COLOR
      }}
    >
      <Card
        style={{
          width: isMediumScreen ? "100%" : "70%",
          maxWidth: "1100px",
          borderRadius: "10px",
          padding: "30px",

        }}
      >
        <Typography
          sx={{ mb: 1, color: COLORS.BACKGROUND_COLOR0, fontWeight: "600", fontSize: "17px" }}
        >
          Register Here
        </Typography>
        <div
          style={{
            fontSize: "12px",
            marginBottom: "10px",
            display: "flex",
          }}
        >
          <Typography style={{ fontSize: "14px", marginTop : "5px", marginRight: "8px" }}>
            Already Have an Account?
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              background: COLORS.LIGHTER_TEAL,
              display: "inline-block",
              padding: "5px 10px",
              color: COLORS.BLACK,
              fontWeight: "700",
              cursor: "pointer",
              borderRadius: "10px",
              "&:hover": {
                background: COLORS.BACKGROUND_COLOR1,
                color: COLORS.BLACK,
              },
            }}
            onClick={handleLoginPage}
          >
            LOGIN
          </Typography>
        </div>
        <div
          style={{
            maxHeight: isMediumScreen ? "500px" : "700px",
            overflowY: "auto",
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue, handleChange}) => (
              <Form style={{ marginTop: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Name"
                      name="name"
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Mobile Number"
                      name="mobileNumber"
                      type="tel"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      onChange={(e) => handleNumberChange(e, handleChange)}
                      error={touched.mobileNumber && !!errors.mobileNumber}
                      helperText={touched.mobileNumber && errors.mobileNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Date of Birth"
                      name="dob"
                      type="date"
                      error={touched.dob && !!errors.dob}
                      helperText={touched.dob && errors.dob}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      required
                      label="School"
                      name="school"
                      error={touched.school && !!errors.school}
                      helperText={touched.school && errors.school}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Preferred Course"
                      name="preferredCourse"
                      error={touched.preferredCourse && !!errors.preferredCourse}
                      helperText={touched.preferredCourse && errors.preferredCourse}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="Preferred College"
                      name="preferredCollege"
                      error={touched.preferredCollege && !!errors.preferredCollege}
                      helperText={touched.preferredCollege && errors.preferredCollege}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      error={touched.district && !!errors.district}
                    >
                      <InputLabel>District</InputLabel>
                      <Field
                        name="district"
                        as={Select}
                        label="District"
                        onChange={(e) =>
                          setFieldValue("district", e.target.value)
                        }
                      >
                        {districtsData.districts.map((district, index) => (
                          <MenuItem key={index} value={district}>
                            {district}
                          </MenuItem>
                        ))}
                      </Field>
                      {touched.district && !!errors.district && (
                        <Typography variant="caption" color="error">
                          {errors.district}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      label="City"
                      name="city"
                      error={touched.city && !!errors.city}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
                    <Button
                      type="reset"
                      variant="outlined"
                      style={{ marginRight: "10px", background: COLORS.BACKGROUND_COLOR0, color: COLORS.WHITE }}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ background: COLORS.BACKGROUND_COLOR0 }}
                    >
                      Register
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleAlertClose} variant="filled" severity="error">
            {registrationError}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isRegister}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleAlertClose} severity="success">
            {registrationError}
          </Alert>
        </Snackbar>
      </Card>
    </div>
  );
};

export default RegisterQuestion;
