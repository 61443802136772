import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import GetAppIcon from "@mui/icons-material/GetApp";
import axios from "axios";
import COLORS from "../ColorCodes/color"

const CustomLightbox = ({ images, photoIndex, onCloseRequest, onMovePrevRequest, onMoveNextRequest }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleDownload = async () => {
    try {
      const response = await axios({
        url: images[photoIndex].filepath,
        method: "GET",
        responseType: "blob"
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `image_${photoIndex + 1}.jpg`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: 16, right: 16, color: "#fff" }}
        onClick={onCloseRequest}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <IconButton
        sx={{ position: "absolute", top: "50%", left: 16, transform: "translateY(-50%)", color: "#fff" }}
        onClick={onMovePrevRequest}
        aria-label="previous"
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        sx={{ position: "absolute", top: "50%", right: 16, transform: "translateY(-50%)", color: "#fff" }}
        onClick={onMoveNextRequest}
        aria-label="next"
      >
        <NavigateNextIcon />
      </IconButton>
      <Box
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          display: "flex",
          alignItems: "center",
          zIndex: 9999,
        }}
      >
        <IconButton onClick={handleDownload} sx={{ color: COLORS.WHITE}}>
          <GetAppIcon />
        </IconButton>
      </Box>
      <img
        src={images[photoIndex].filepath}
        alt=""
        style={{ maxHeight: "80vh", maxWidth: "80vw", display: loading ? "none" : "block"}}
        onLoad={handleImageLoad}
      />
    </Box>
  );
};

export default CustomLightbox;
