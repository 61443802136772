import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SessionContext } from "../screens/SessionContext";

const AdminPublicRoute = ({ element }) => {
  const { isAdminSessionExpired } = useContext(SessionContext);

  // If session is not expired (i.e., user is logged in), redirected to home or another page
  if (!isAdminSessionExpired) {
    return <Navigate to="/admin/dashboard" />;
  }

  // If session is expired, render the element
  return element;
};

export default AdminPublicRoute;
