import React, { useState, useEffect, useRef} from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CardMedia,
  InputAdornment,
  TextField,Breadcrumbs, Link
} from "@mui/material";
import arts from "../../assets/courses/arts.jpg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import COLORS from "../../ColorCodes/color";

const disciplines = [
  {
    id: 1,
    title: "B.A. TAMIL",
    description:"is an undergraduate degree program that focuses on the study of Tamil language, literature, and culture. Students learn about Tamil literature, linguistics, poetry, prose, and drama",
    scope: [
      "Tamil Language Teaching",
      "Translation and Interpretation",
      "Tamil Media and Journalism",
      "Content Writing",
      "Literary Criticism",
      "Tamil Research",
      "Government Language Officer"
    ],
    salary: "₹3,00,000 - ₹5,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/2.jpg"),
  },
  {
    id: 2,
    title: "B.A. ENGLISH",
    description:"is an undergraduate program that explores the English language, literature, and culture. Students study various literary genres, linguistic theories, and critical approaches to literature.",
    scope: [
      "English Language Teaching",
      "Writing and Editing",
      "Media and Communication",
      "Content Development",
      "Public Relations",
      "Copywriting",
      "Academic Research"
    ],
    salary: "₹3,00,000 - ₹5,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/3.jpg"),
  },
  {
    id: 3,
    title: "B.A. HISTORY",
    description:"is an undergraduate program that examines historical events, civilizations, and cultures. Students learn about different periods of history, historical research methods, and critical analysis of historical sources.",
    scope: [
      "Archival Management",
      "Museum Curatorship",
      "Heritage Tourism",
      "Historical Research",
      "Teaching and Education",
      "Public Administration",
      "Cultural Heritage Conservation"
    ],
    salary: "₹3,00,000 - ₹5,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/4.jpg"),
  },
  {
    id: 4,
    title: "BA IN HUMANITIES & SOCIAL SCIENCES",
    description:"is an interdisciplinary degree program that offers a broad understanding of human society, culture, and behavior. It covers subjects such as sociology, psychology, economics, and political science.",
    scope: [
      "Social Work",
      "Counseling and Therapy",
      "Nonprofit Management",
      "Market Research",
      "Human Resources",
      "Community Development",
      "Policy Analysis"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/5.jpg"),
  },
  {
    id: 5,
    title: "BA IN HOSPITALITY & TRAVEL",
    description:"is a specialized degree program that prepares students for careers in the hospitality and tourism industry. It covers areas such as hotel management, event planning, tourism marketing, and travel operations.",
    scope: [
      "Hotel Management",
      "Tourism Marketing",
      "Event Management",
      "Travel Agency Operations",
      "Resort Management",
      "Destination Management",
      "Cruise Line Operations"
    ],
    salary: "₹3,00,000 - ₹5,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/6.jpg"),
  },
  {
    id: 6,
    title: "BA SOCIOLOGY",
    description:"is a degree program that explores human society, social relationships, and social institutions. It covers topics such as social inequality, deviance, globalization, and social change.",
    scope: [
      "Social Research",
      "Community Development",
      "Human Resources",
      "Advocacy and Policy Analysis",
      "Market Research",
      "Education and Teaching",
      "Nonprofit Administration"
    ],
    salary: "₹3,00,000 - ₹5,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/7.jpg"),
  },
  {
    id: 7,
    title: "B.SC. OPTOMETRY",
    description:"is an undergraduate program that focuses on eye care and vision science. Students learn about eye anatomy, vision disorders, diagnostic techniques, and optometric practice management.",
    scope: [
      "Optometric Practice",
      "Ophthalmic Research",
      "Contact Lens Optometry",
      "Pediatric Optometry",
      "Low Vision Rehabilitation",
      "Vision Therapy",
      "Public Health Optometry"
    ],
    salary: "₹4,00,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/8.jpg"),
  },
  {
    id: 8,
    title: "BA PHILOSOPHY",
    description:"is a degree program that explores fundamental questions about existence, knowledge, morality, and the nature of reality. Students engage in critical thinking, argumentation, and philosophical analysis.",
    scope: [
      "Philosophy Professor",
      "Ethics Consultant",
      "Philosophical Writer",
      "Logician",
      "Policy Analyst",
      "Researcher",
      "Intellectual Property Analyst"
    ],
    salary: "₹3,00,000 - ₹5,50,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/10.jpg"),
  },
  {
    id: 9,
    title: "BA PHYSICAL EDUCATION",
    description:"is an undergraduate program that focuses on the study of physical fitness, sports science, and recreational activities. Students learn about exercise physiology, sports psychology, and coaching techniques.",
    scope: [
      "Physical Education Teacher",
      "Fitness Trainer",
      "Sports Coach",
      "Recreation Coordinator",
      "Exercise Physiologist",
      "Sports Administrator",
      "Personal Trainer"
    ],
    salary: "₹2,50,000 - ₹4,50,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/11.jpg"),
  },
  {
    id: 10,
    title: "BA JOURNALISM",
    description:"is a degree program that prepares students for careers in media, communication, and news reporting. It covers topics such as news writing, investigative journalism, multimedia reporting, and ethics in journalism.",
    scope: [
      "News Reporter",
      "Broadcast Journalist",
      "Multimedia Journalist",
      "Investigative Journalist",
      "Editor",
      "Public Relations Specialist",
      "Content Producer"
    ],
    salary: "₹3,00,000 - ₹5,50,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/12.jpg"),
  },
  {
    id: 11,
    title: "BA ECONOMICS",
    description:"is an undergraduate program that focuses on the study of economic systems, theories, and policies. Students analyze issues such as inflation, unemployment, international trade, and economic development.",
    scope: [
      "Economist",
      "Financial Analyst",
      "Policy Analyst",
      "Market Researcher",
      "Investment Banker",
      "Data Analyst",
      "Economic Consultant"
    ],
    salary: "₹3,00,000 - ₹5,50,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/13.jpg"),
  },
  {
    id: 12,
    title: "BA DEFENCE",
    description:"is a degree program that focuses on the study of national security, defense strategies, and military operations. Students learn about defense policy, strategic planning, and conflict resolution.",
    scope: [
      "Defense Analyst",
      "Military Intelligence Officer",
      "Security Consultant",
      "Defense Policy Researcher",
      "Arms Control Specialist",
      "Military Historian",
      "Counterterrorism Analyst"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/14.jpg"),
  },
  {
    id: 13,
    title: "B.COM",
    description:"is an undergraduate program that focuses on commerce, accounting, and business-related subjects. It covers areas such as accounting principles, business law, financial management, and taxation.",
    scope: [
      "Accountant",
      "Financial Analyst",
      "Auditor",
      "Tax Consultant",
      "Investment Banker",
      "Business Development Executive",
      "Treasury Manager"
    ],
    salary: "₹3,00,000 - ₹5,50,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/15.jpg"),
  },
  {
    id: 14,
    title: "ACCOUNTING & FINANCE",
    description:"is an undergraduate program that focuses on commerce, accounting, and business-related subjects. It covers areas such as accounting principles, business law, financial management, and taxation.",
    scope: [
      "Accountant",
      "Financial Analyst",
      "Auditor",
      "Tax Consultant",
      "Investment Banker",
      "Business Development Executive",
      "Treasury Manager"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/16.jpg"),
  },
  {
    id: 15,
    title: "BUSINESS ANALYTICS",
    description:"is a degree program that focuses on using data analysis and statistical methods to make informed business decisions. Students learn about data mining, predictive modeling, and business intelligence tools.",
    scope: [
      "Data Analyst",
      "Business Intelligence Analyst",
      "Data Scientist",
      "Market Research Analyst",
      "Operations Analyst",
      "Supply Chain Analyst",
      "Financial Analyst"
    ],
    salary: "₹4,00,000 - ₹7,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/17.jpg"),
  },
  {
    id: 16,
    title: "COMMERCE WITH COMPUTER APPLICATIONS",
    description:"is an undergraduate program that combines commerce subjects with computer science and applications. Students learn about accounting principles, business communication, and computerized accounting software.",
    scope: [
      "Financial Analyst",
      "Business Analyst",
      "Software Developer (Accounting Software)",
      "E-Commerce Manager",
      "IT Consultant (Finance Industry)",
      "Database Administrator",
      "Systems Analyst"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/18.jpg"),
  },
  {
    id: 17,
    title: "COST & MANAGEMENT ACCOUNTING",
    description:"is a specialized field that focuses on analyzing business costs, budgeting, and financial management. Students learn about cost-control techniques, performance evaluation, and managerial decision-making.",
    scope: [
      "Cost Accountant",
      "Management Accountant",
      "Financial Controller",
      "Budget Analyst",
      "Financial Planner",
      "Internal Auditor",
      "Tax Consultant (Cost Accounting)"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/19.jpg"),
  },
  {
    id: 18,
    title: "E-COMMERCE",
    description:"is a degree program that focuses on online business models, digital marketing, and electronic transactions. Students learn about website development, online payment systems, and e-commerce strategies.",
    scope: [
      "E-Commerce Manager",
      "Digital Marketing Specialist",
      "Online Merchandiser",
      "E-Commerce Analyst",
      "Website Developer",
      "Social Media Manager",
      "Customer Relationship Manager (E-Commerce)"
    ],
    salary: "₹3,50,000 - ₹6,00,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/20.jpg"),
  },
  {
    id: 19,
    title: "FINANCIAL SYSTEM",
    description:"is a program that focuses on the study of financial institutions, markets, and systems. Students learn about banking operations, financial regulations, and monetary policies.",
    scope: [
      "Financial Analyst",
      "Investment Banker",
      "Financial Planner",
      "Risk Manager",
      "Financial Consultant",
      "Bank Manager",
      "Treasury Analyst"
    ],
    salary: "₹4,00,000 - ₹6,50,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/21.jpg"),
  },
  {
    id: 20,
    title: "FOREIGN TRADE",
    description:"is a degree program that focuses on international business, trade policies, and global markets. Students learn about import-export procedures, international finance, and trade regulations.",
    scope: [
      "International Trade Analyst",
      "Export Coordinator",
      "Trade Compliance Specialist",
      "Global Supply Chain Manager",
      "Customs Broker",
      "International Marketing Manager",
      "Trade Finance Officer"
    ],
    salary: "₹4,00,000 - ₹6,50,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/22.jpg"),
  },
  {
    id: 21,
    title: "PROFESSIONAL ACCOUNTING",
    description:"is a specialized field that prepares students for careers in accounting, auditing, and financial reporting. Students learn about accounting standards, audit procedures, and taxation laws.",
    scope: [
      "Chartered Accountant",
      "Certified Public Accountant",
      "Auditor",
      "Tax Consultant",
      "Forensic Accountant",
      "Internal Auditor",
      "Financial Controller"
    ],
    salary: "₹4,00,000 - ₹6,50,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/23.jpg"),
  },
  {
    id: 22,
    title: "RETAIL MARKETING",
    description:"is a degree program that focuses on marketing strategies, consumer behavior, and retail management. Students learn about merchandising, store operations, and customer relationship management.",
    scope: [
      "Retail Manager",
      "Visual Merchandiser",
      "Marketing Coordinator",
      "Sales Manager",
      "Brand Manager",
      "Retail Buyer",
      "Store Planner"
    ],
    salary: "₹3,50,000 - ₹6,00,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/24.jpg"),
  },
  {
    id: 23,
    title: "BANKING & INSURANCE",
    description:"is a program that focuses on banking operations, insurance products, and risk management. Students learn about banking regulations, insurance policies, and financial planning.",
    scope: [
      "Bank Officer",
      "Insurance Agent",
      "Risk Analyst",
      "Loan Officer",
      "Claims Adjuster",
      "Underwriter",
      "Investment Advisor"
    ],
    salary: "₹4,00,000 - ₹6,50,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/25.jpg"),
  },
  {
    id: 24,
    title: "B.COM.COMMERCE WITH INFORMATION TECHNOLOGY",
    description:"is an undergraduate program that combines commerce subjects with information technology. Students learn about business information systems, database management, and e-commerce applications.",
    scope: [
      "Business Analyst (IT)",
      "IT Consultant",
      "Systems Analyst",
      "Database Administrator",
      "E-Commerce Manager",
      "Software Developer (Finance)",
      "Technology Auditor"
    ],
    salary: "₹3,50,000 - ₹6,00,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/26.jpg"),
  },
  {
    id: 25,
    title: "B.SC. ELECTRONICS & COMMUNICATION SYSTEMS",
    description:"is a degree program that focuses on the study of electronic devices, circuits, and communication systems. Students learn about analog and digital electronics, signal processing, and telecommunications.",
    scope: [
      "Electronics Engineer",
      "Telecommunication Engineer",
      "Embedded Systems Engineer",
      "Network Engineer",
      "Broadcast Engineer",
      "Control Systems Engineer",
      "RF Engineer"
    ],
    salary: "₹3,50,000 - ₹6,00,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/42.jpg"),
  },
  {
    id: 26,
    title: "B.SC. BIOTECHNOLOGY",
    description:"is a program that focuses on the study of biological processes and their applications in medicine, agriculture, and industry. Students learn about genetic engineering, bioprocessing, and biotechnology research methods.",
    scope: [
      "Biotechnologist",
      "Research Scientist",
      "Pharmaceutical Analyst",
      "Bioprocess Engineer",
      "Molecular Biologist",
      "Bioinformatics Specialist",
      "Quality Control Analyst"
    ],
    salary: "₹4,00,000 - ₹6,50,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/43.jpg"),
  },
  {
    id: 27,
    title: "B.SC. MICROBIOLOGY",
    description:"is a degree program that focuses on the study of microorganisms, including bacteria, viruses, fungi, and protozoa. Students learn about microbial physiology, genetics, and pathogenesis.",
    scope: [
      "Microbiologist",
      "Medical Laboratory Technologist",
      "Clinical Research Associate",
      "Quality Assurance Microbiologist",
      "Environmental Microbiologist",
      "Industrial Microbiologist",
      "Food Microbiologist"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/44.jpg"),
  },
  {
    id: 28,
    title: "B.SC. COSTUME DESIGN AND FASHION",
    description:"is an undergraduate program that focuses on the study of clothing design, fashion trends, and garment construction. Students learn about textile science, pattern making, and fashion merchandising.",
    scope: [
      "Fashion Designer",
      "Apparel Merchandiser",
      "Fashion Stylist",
      "Textile Designer",
      "Fashion Illustrator",
      "Costume Designer (Film/Theater)",
      "Fashion Buyer"
    ],
    salary: "₹3,00,000 - ₹5,50,0000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/45.jpg"),
  },
  {
    id: 29,
    title: "B.SC. CATERING SCIENCE AND HOTEL MANAGEMENT",
    description:"integrates culinary arts and hospitality management, preparing students for careers in hotel and restaurant operations. The program emphasizes a blend of practical cooking skills and business acumen to meet the demands of the dynamic hospitality industry.",
    scope: [
      "Hotel Manager",
      "Restaurant Manager",
      "Food and Beverage Manager",
      "Event Planner",
      "Executive Chef",
      "Catering Manager",
      "Front Office Manager"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/46.jpg"),
  },
  {
    id: 30,
    title: "B.SC. PSYCHOLOGY",
    description:"is an undergraduate program that focuses on the scientific study of behavior and mental processes. Students learn about psychological theories, research methods, and applied psychology.",
    scope: [
      "Clinical Psychologist",
      "Counseling Psychologist",
      "School Psychologist",
      "Industrial-Organizational Psychologist",
      "Forensic Psychologist",
      "Health Psychologist",
      "Rehabilitation Counselor"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/47.jpg"),
  },
  {
    id: 31,
    title: "B.SC. COMPUTER SCIENCE",
    description:" is a degree program that focuses on the study of computer systems, programming languages, and software development. Students learn about algorithms, data structures, and computer architecture.",
    scope: [
      "Software Developer",
      "Systems Analyst",
      "Network Administrator",
      "Database Administrator",
      "Web Developer",
      "Cybersecurity Analyst",
      "IT Consultant"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/48.jpg"),
  },
  {
    id: 32,
    title: "B.SC. COMPUTER SCIENCE & APPLICATIONS",
    description:"is an undergraduate program that focuses on the practical applications of computer science concepts. Students learn about software engineering, database management, and application development.",
    scope: [
      "Application Developer",
      "Systems Analyst",
      "Database Administrator",
      "IT Project Manager",
      "Web Designer",
      "Software Quality Assurance Engineer",
      "Mobile App Developer"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/49.jpg"),
  },
  {
    id: 33,
    title: "B.SC. SOFTWARE SYSTEMS",
    description:"is a degree program that focuses on the study of software engineering principles and methodologies. Students learn about software design, development, and testing.",
    scope: [
      "Software Engineer",
      "Systems Analyst",
      "Software Developer",
      "Quality Assurance Analyst",
      "Technical Support Engineer",
      "Software Architect",
      "Embedded Systems Engineer"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/50.jpg"),
  },
  {
    id: 34,
    title: "B.SC. INFORMATION TECHNOLOGY",
    description:"is a program that focuses on the study of information systems, network administration, and cybersecurity. Students learn about IT infrastructure, database management, and information security.",
    scope: [
      "IT Manager",
      "Network Administrator",
      "Systems Analyst",
      "Cybersecurity Specialist",
      "Database Administrator",
      "Cloud Solutions Architect",
      "IT Consultant"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/51.jpg"),
  },
  {
    id: 35,
    title: "B.SC. COMPUTER TECHNOLOGY",
    description:"is an undergraduate program that focuses on the study of computer hardware, software, and systems integration. Students learn about computer architecture, digital electronics, and computer networks.",
    scope: [
      "Hardware Engineer",
      "Systems Administrator",
      "Network Technician",
      "Technical Support Specialist",
      "Embedded Systems Engineer",
      "IT Sales Representative",
      "IT Trainer"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/52.jpg"),
  },
  {
    id: 36,
    title: "B.SC. ARTIFICIAL INTELLIGENCE & MACHINE LEARNING",
    description:"is a degree program that focuses on the study of intelligent systems and algorithms. Students learn about machine learning techniques, data mining, and neural networks.",
    scope: [
      "AI Engineer",
      "Machine Learning Engineer",
      "Data Scientist",
      "Robotics Engineer",
      "Natural Language Processing Specialist",
      "AI Research Scientist",
      "Autonomous Systems Developer"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/53.jpg"),
  },
  {
    id: 37,
    title: "B.SC. DATA SCIENCE",
    description:"is a degree program that focuses on the study of data analysis, machine learning, and data visualization. Students learn about statistical methods, programming languages, and big data technologies.",
    scope: [
      "Data Scientist",
      "Data Analyst",
      "Business Intelligence Analyst",
      "Machine Learning Engineer",
      "Data Engineer",
      "Data Architect",
      "Data Visualization Specialist"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/54.jpg"),
  },
  {
    id: 38,
    title: "B.SC. INFORMATION SYSTEMS & MANAGEMENT",
    description:"is a program that focuses on the study of information technology and its applications in business settings. Students learn about database management, information security, and project management.",
    scope: [
      "IT Manager",
      "Systems Analyst",
      "Business Analyst",
      "Database Administrator",
      "IT Consultant",
      "Project Manager",
      "Information Security Analyst"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/55.jpg"),
  },
  {
    id: 39,
    title: "BCA",
    description:"(Bachelor of Computer Applications)is an undergraduate program that focuses on the study of computer applications in various fields. Students learn about programming languages, database management, and software development.",
    scope: [
      "Software Developer",
      "Web Developer",
      "Mobile App Developer",
      "Systems Analyst",
      "Network Administrator",
      "Database Administrator",
      "IT Support Specialist"
    ],
    salary: "₹3,00,000 - ₹5,50,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/56.jpg"),
  },
  {
    id: 40,
    title: "B.SC. MATHEMATICS",
    description:" is a degree program that focuses on the study of mathematical concepts, theories, and applications. Students learn about calculus, algebra, probability, and statistics.",
    scope: [
      "Mathematician",
      "Statistician",
      "Actuary",
      "Data Analyst",
      "Operations Research Analyst",
      "Quantitative Analyst",
      "Financial Analyst"
    ],
    salary: "₹3,50,000 - ₹6,00,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/57.jpg"),
  },
  {
    id: 41,
    title: "B.SC. ZOOLOGY",
    description:"is a degree program that focuses on the study of animal biology, including animal anatomy, physiology, and behavior. Students learn about animal classification, evolution, and ecology.",
    scope: [
      "Zoologist",
      "Wildlife Biologist",
      "Marine Biologist",
      "Conservation Scientist",
      "Zookeeper",
      "Animal Behaviorist",
      "Environmental Educator"
    ],
    salary: "₹3,00,000 - ₹5,50,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/59.jpg"),
  },
  {
    id: 42,
    title: "B.SC. PHYSICS",
    description:"is an undergraduate program that focuses on the study of natural phenomena, including motion, energy, and matter. Students learn about classical and modern physics, including mechanics, electromagnetism, and quantum mechanics.",
    scope: [
      "Physicist",
      "Research Scientist",
      "Engineer",
      "Data Analyst",
      "Science Teacher",
      "Aerospace Engineer",
      "Nuclear Physicist"
    ],
    salary: "₹3,50,000 - ₹6,00,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/60.jpg"),
  },
  {
    id: 43,
    title: "B.SC. CHEMISTRY",
    description:"is a degree program that focuses on the study of chemical elements, compounds, and reactions. Students learn about organic, inorganic, and physical chemistry, as well as analytical techniques.",
    scope: [
      "Chemist",
      "Pharmaceutical Scientist",
      "Materials Scientist",
      "Quality Control Analyst",
      "Environmental Chemist",
      "Forensic Scientist",
      "Chemical Engineer"
    ],
    salary: "₹3,50,000 - ₹6,00,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/61.jpg"),
  },
  {
    id: 44,
    title: "B.SC. FOOD & NUTRITION",
    description:"is a program that focuses on the study of food science, nutrition, and dietetics. Students learn about food composition, human nutrition, and dietary planning.",
    scope: [
      "Nutritionist/Dietitian",
      "Food Technologist",
      "Research Scientist (Food Science)",
      "Clinical Dietitian",
      "Community Nutritionist",
      "Food Safety Specialist",
      "Wellness Coach"
    ],
    salary: "₹3,00,000 - ₹5,50,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/62.jpg"),
  },
  {
    id: 45,
    title: "B.SC. AGROBIOLOGY",
    description:"is an undergraduate program that focuses on the study of agriculture, including plant cultivation, crop management, and agricultural economics. Students learn about soil science, pest management, and sustainable agriculture practices.",
    scope: [
      "Agricultural Scientist",
      "Agronomist",
      "Crop Consultant",
      "Farm Manager",
      "Agricultural Extension Officer",
      "Agricultural Economist",
      "Soil Scientist"
    ],
    salary: "₹3,00,000 - ₹5,50,000  per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/63.jpg"),
  },
  {
    id: 46,
    title: "B.SC. INTERNET OF THINGS",
    description:"is a degree program that focuses on the study of interconnected devices and sensors, enabling data exchange and automation. Students learn about IoT technologies, wireless communication, and sensor networks.",
    scope: [
      "IoT Engineer",
      "Embedded Systems Developer",
      "IoT Solutions Architect",
      "IoT Security Specialist",
      "Data Analyst (IoT)",
      "Smart City Planner",
      "Industrial IoT Consultant"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: require("../../assets/coursesDetail/Arts & Science/64.jpg"),
  },
];

const ArtsScience = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDisciplines, setFilteredDisciplines] = useState(disciplines);

  useEffect(() => {
    const handleFiltering = () => {
      if (!searchTerm.trim()) {
        setFilteredDisciplines(disciplines);
        return;
      }
      const filtered = disciplines.filter(discipline =>
        discipline.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDisciplines(filtered);
    };
    handleFiltering();
  }, [searchTerm, disciplines]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCardClick = (index,name) => {
    setSelectedCard(selectedCard === index ? null : index);
    navigate(`/courses/arts-science/${name}`);
  };

  const CardComponent = () => {
    return (
      <div style={{ background: COLORS.GREY_COLOR, paddingLeft: "15px", paddingRight: "15px", paddingBottom: "22px" }}>
        {filteredDisciplines.length === 0 ? (
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            No courses found
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredDisciplines.map((discipline, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    cursor: "pointer",
                    transition: "box-shadow 0.3s ease-in-out",
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BACKGROUND_COLOR0,
                    "&:hover": {
                      boxShadow: 10,
                      color: COLORS.BACKGROUND_COLOR1
                    },
                  }}
                  onClick={() => handleCardClick(index, discipline.title)}
                >
                  <CardMedia
                    component="img"
                    image={discipline.imageSrc}
                    alt={discipline.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {discipline.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };
  return (
    <Box sx={{ padding: "36px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
          Courses
        </Link>
        <Link component={RouterLink} to="/courses/arts-science" style={{fontWeight: "600"}} color={COLORS.BACKGROUND_COLOR1}>
        Arts & Science
        </Link>
      </Breadcrumbs>
      <div style={{  background: COLORS.GREY_COLOR, marginTop: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={titleRef}
            variant="h1"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginBottom: "10px",
              fontSize: "25px",
              textAlign: "center",
              background: COLORS.BACKGROUND_COLOR0,
              padding: "10px",
              color: COLORS.BACKGROUND_COLOR2,
              flexGrow: 1,
            }}
          >
          Arts & Science
          </Typography>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
          <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
          Arts courses engage students in creative expression and cultural studies, exploring literature, visual and performing arts, and the humanities. Concurrently, science courses delve into systematic inquiry, emphasizing disciplines like physics, chemistry, biology, and mathematics. The combination of arts and science education cultivates a holistic understanding of the world, fostering critical thinking, interdisciplinary perspectives, and a balanced intellectual foundation. Students gain both the expressive tools of the arts and the analytical methods of science, contributing to a well-rounded education that transcends traditional disciplinary boundaries.
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: "10px", textAlign: "justify" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Interdisciplinary Perspectives:</strong> A combination of arts and science courses provides a holistic education, fostering interdisciplinary thinking. This broadens perspectives, allowing students to approach complex issues from multiple angles and integrate diverse knowledge and skills.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Critical Thinking and Analytical Skills:</strong> Arts courses develop creativity and critical thinking, while science courses emphasize analytical skills and evidence-based reasoning. The combination strengthens cognitive abilities, enabling students to analyze, evaluate, and solve problems in a comprehensive manner.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Versatile Career Opportunities:</strong> Graduates with a background in both arts and science have a diverse skill set, opening doors to a wide range of career opportunities. They may pursue professions in fields such as communication, research, education, technology, and more, where a blend of analytical and creative skills is valued.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Effective Communication Skills:</strong> Arts courses enhance communication skills through writing, presentation, and visual expression, while science courses emphasize precision in conveying complex ideas. This combination equips students with effective communication skills essential in various professional contexts.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Adaptability and Innovation:</strong> The combination of arts and science encourages adaptability and innovation. Students learn to navigate diverse challenges, think creatively, and apply scientific methodologies, fostering a mindset that is crucial in addressing the evolving demands of the modern world.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <img src={arts} alt="arts" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: "10px", paddingRight:"10px"}}>
          <h2 style={{color: COLORS.BACKGROUND_COLOR0}}>COURSES OFFERED ({disciplines.length} courses)</h2>
          <TextField
            placeholder="Search courses..."
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box sx={{ mt: 4 }}>
          <CardComponent />
        </Box>
      </div>

    </Box>
  );
};

export default ArtsScience;
