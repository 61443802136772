import React from "react";
import { Viewer, SpecialZoomLevel, Worker } from "@react-pdf-viewer/core";
import { ThemeContext } from "@react-pdf-viewer/core";
import { useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PDFViewer = () => {
  const [currentTheme, setCurrentTheme] = React.useState("light");
  const themeContext = { currentTheme, setCurrentTheme };
  const theme = useTheme();
  const { filename } = useParams();
  const [pdfFile, setPdfFile] = React.useState(null);
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  React.useEffect(() => {
    const importPdf = async () => {
      try {
        const pdfModule = await import(`../assets/question/${filename}`);
        setPdfFile(pdfModule.default);
      } catch (error) {
        console.error("Error importing PDF:", error);
      }
    };

    importPdf();
  }, [filename]);


  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [defaultScale, setDefaultScale] = React.useState(SpecialZoomLevel.PageFit);

  React.useEffect(() => {
    const determineDefaultScale = () => {
      if (isSmallScreen || isMediumScreen) return SpecialZoomLevel.PageFit;
      if (isLargeScreen || isExtraLargeScreen) return 1.0;
      return SpecialZoomLevel.PageFit;
    };

    setDefaultScale(determineDefaultScale());
  }, [isSmallScreen, isMediumScreen, isLargeScreen, isExtraLargeScreen]);

  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    EnterFullScreen: () => <></>,
    Open: () => <></>,
    Print: () => <></>,
  });

  return (
    <div style={{ height: "100vh", paddingBottom: "20px" }}>
      <ThemeContext.Provider value={themeContext}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          {pdfFile && (
            <Viewer
              fileUrl={pdfFile}
              plugins={[toolbarPluginInstance]}
              defaultScale={defaultScale}
              theme={currentTheme}
            />
          )}
        </Worker>
      </ThemeContext.Provider>
    </div>
  );
};

export default PDFViewer;
