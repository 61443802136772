import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import CountUp from "react-countup";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useInView } from "react-intersection-observer";
import COLORS from "../../ColorCodes/color";

const CounterGrid = ({ isMobile }) => {

  const counters = [
    { id: 1, icon: <SentimentSatisfiedAltIcon style={{ fontSize: "44px" }} />, title: "HAPPY STUDENTS", start: 0, end: 5248 },
    { id: 4, icon: <EmojiEventsIcon style={{ fontSize: "44px" }} />, title: "AWARDS WON", start: 0, end: 24 },
  ];

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <Box
      ref={ref}
      sx={{
        mt: isMobile ? 1 : 6,
        paddingBottom: "20px",

        borderRadius: "10px",
        padding: isMobile ? 2 : 3,
      }}
    >
      <Grid container direction="column" spacing={2} style={{ textAlign: "center" }}>
        {counters.map((counter) => (
          <Grid item key={counter.id}>
            <Box
              sx={{
                p: isMobile ? 1 : 2,
                textAlign: "center",
                borderRadius: "5px",
                border: "4px solid #202c45",
                backgroundColor: COLORS.GREY_COLOR,
              }}
            >
              <Box sx={{ mb: 2, color: COLORS.BACKGROUND_COLOR1 }}>{counter.icon}</Box>
              {inView && (
                <CountUp start={counter.start} end={counter.end} duration={2.5} separator="," delay={0}>
                  {({ countUpRef }) => (
                    <Typography variant="h3" ref={countUpRef} gutterBottom />
                  )}
                </CountUp>
              )}
              <Typography style={{ fontSize: "18px" }} gutterBottom>
                {counter.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CounterGrid;
