import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Grid, Box} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import openIcon from "../assets/24-7.png";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import COLORS from "../ColorCodes/color";

const useCustomViewport = () => {
  const [isCustomViewport, setIsCustomViewport] = React.useState(false);

  React.useEffect(() => {
    const checkViewportSize = () => {
      if (window.innerWidth === 820 && window.innerHeight === 1180) {
        setIsCustomViewport(true);
      } else if(window.innerWidth === 768 && window.innerHeight === 1024){
        setIsCustomViewport(true);
      }  else {
        setIsCustomViewport(false);
      }
    };

    checkViewportSize();
    window.addEventListener("resize", checkViewportSize);

    return () => window.removeEventListener("resize", checkViewportSize);
  }, []);

  return isCustomViewport;
};

const AppHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isCustomViewport = useCustomViewport();

  const openFacebook = () => {
    window.open("https://www.facebook.com/thenieducationalconsultancy.theni?mibextid=JRoKGi", "_blank");
  };

  const openInstagram = () => {
    window.open("https://www.instagram.com/theni_educational_consultancy?igsh=MTAwZDIwdGhueG1heQ==", "_blank");
  };

  const openWhatsApp = () => {
    window.open("https://wa.me/9442557269", "_blank");
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+919442557269";
  };

  return (
    <AppBar position="static" style={{ background: COLORS.BACKGROUND_COLOR0, paddingLeft: "6px", paddingRight: "17px" , paddingBottom: isMobile || isCustomViewport  ? "10px": "0px"}}>
      <Toolbar disableGutters>
        <Grid container alignItems="center" justifyContent={isMobile || isCustomViewport ? "center" : "flex-start"}>
          <Grid item xs={12} md={8} container justifyContent={isMobile || isCustomViewport ? "center" : "flex-start"} spacing={2}>
            <Grid item>
              <Box sx={{ flexGrow: 0 }}>
                <Grid item>
                  <Box style={{ display: "flex", alignItems: "center", marginBottom: "15px", marginTop: "20px" }}>
                    <img src={openIcon} alt="" style={{ width: 25, height: 25, marginLeft :"10px"}}/>
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ cursor: "pointer",  color: COLORS.WHITE , fontSize: "14px", marginLeft: "10px" }}
                      >
                         WE ARE OPEN!
                      </Typography>

                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item style={{ marginTop: "6px"}}>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "15px", marginTop: "15px" }}>
                <PhoneAndroidIcon
                  sx={{ marginRight: "6px", cursor: "pointer", color: COLORS.WHITE}}
                  onClick={handlePhoneClick}
                />
                <Typography
                  variant="body1"
                  sx={{ cursor: "pointer", color: COLORS.WHITE, fontSize: "14px" }}
                  onClick={handlePhoneClick}
                >
              91-9442557269
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} container justifyContent={isMobile || isCustomViewport ? "center" : "flex-end"} spacing={2}>
            <Grid item>
              <Tooltip title="Facebook">
                <IconButton
                  size="small"
                  onClick={openFacebook}
                  sx={{ color: "#fff" }}
                >
                  <FacebookIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Instagram">
                <IconButton
                  size="small"
                  onClick={openInstagram}
                  sx={{ color: "#fff" }}
                >
                  <InstagramIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="WhatsApp">
                <IconButton
                  size="small"
                  onClick={openWhatsApp}
                  sx={{ color: "#fff" }}
                >
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
