import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import logo from "../assets/logo.svg";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import RoomIcon from "@mui/icons-material/Room";
import COLORS from "../ColorCodes/color";
import { Box, Tooltip, Typography, List, ListItem, ListItemText, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import theni from "../assets/uploads/2024/theni/20.JPG";
import viru from "../assets/uploads/2024/virudhunagar/4.JPG";
import sivakasi from "../assets/uploads/2023/sivakasi/7.JPG"

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const pages = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/about" },
    { name: "Courses", path: "/courses" },
    { name: "Gallery", path: "/gallery" },
    { name: "Entrance Exams", path: "/entrance-exams" },
    { name: "Question Bank", path: "/login" },
    { name: "Contact Us", path: "/contact" }
  ];

  const latestEvents = [
    { image: theni, name: "Theni Education Fair", date: "June 15, 2024" },
    { image: viru, name: " Virudhunagar Education Fair", date: "May 20, 2024" },
    { image: sivakasi, name: " Sivakasi Education Fair", date: "April 4, 2023" },
  ];

  const googleMapsUrl = "https://www.google.com/maps/place/THENI+EDUCATIONAL+CONSULTANCY/@10.0095625,77.4741636,17z/data=!4m10!1m2!2m1!1s2F5G%2BRRF,+Sungam+Rd,+NGO+Colony,+Suppan+Ragavan+Colony,+NRT+Nagar,+Theni,+Tamil+Nadu+625531!3m6!1s0x3b07152152862c6b:0xd68e5a308f5251f4!8m2!3d10.0095625!4d77.4770625!15sClsyRjVHK1JSRiwgU3VuZ2FtIFJkLCBOR08gQ29sb255LCBTdXBwYW4gUmFnYXZhbiBDb2xvbnksIE5SVCBOYWdhciwgVGhlbmksIFRhbWlsIE5hZHUgNjI1NTMxkgEKY29uc3VsdGFudOABAA!16s%2Fg%2F11s1sp3sgb?entry=ttu";

  const handleEmailClick = () => {
    window.location.href = "mailto:infowhatnextedu@gmail.com";
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+919442557269";
  };

  const iconStyle = {
    fontSize: "35px",
    padding: 5,
    cursor: "pointer",
    transition: "transform 0.3s ease",
  };

  const iconHoverStyle = {
    transform: "scale(1.2)",
  };

  const listItemStyle = {
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1) translateX(10%)",
      color: COLORS.FOOTER_HOVER_COLOR,
      fontWeight: "bold"
    },
  };

  return (
    <footer style={{
      backgroundColor: COLORS.BACKGROUND_COLOR0,
      color: COLORS.WHITE,
      padding: "20px 0",
      bottom: 0,
      width: "100%",
      zIndex: 1000,
      fontWeight: 600
    }}>
      <Grid container spacing={3} style={{ maxWidth: "xl", margin: "auto", width: "100%" }}>

        {/* Logo and Follow us section */}
        <Grid item xs={12} md={6} lg={3} style={{ padding: "20px", textAlign: "center" }}>
          <img src={logo} alt="" style={{ width: "100%", marginBottom: "10px", background: "white" }} />
          <div>
            <p style={{ margin: 0, fontSize: "14px", textAlign: "left" }}>Thank you for being so interested in What Next!</p>
            <p style={{ margin: 0, fontSize: "14px", textAlign: "left" }}>If you have any questions, inquiries, or feedback, please feel free to reach out to us.</p>
          </div>
          <Typography variant="body1" style={{ marginTop: "14px", fontSize: "14px", textAlign: "left" }}>Follow us</Typography>
          <Box sx={{ display: "flex", justifyContent: "left", marginTop: "10px", gap: "20px" }}>
            <Tooltip title="Facebook">
              <a href="https://www.facebook.com/thenieducationalconsultancy.theni?mibextid=JRoKGi" rel="noopener noreferrer">
                <FacebookOutlinedIcon
                  style={{
                    ...iconStyle,
                    background: "#3B5998",
                    color: "#fff",
                  }}
                  sx={{
                    "&:hover": iconHoverStyle,
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Instagram" sx={{ marginLeft: "10px" }}>
              <a href="https://www.instagram.com/theni_educational_consultancy?igsh=MTAwZDIwdGhueG1heQ==" rel="noopener noreferrer">
                <InstagramIcon
                  style={{
                    ...iconStyle,
                    background: "rgb(230, 6, 80)",
                    color: "#fff",
                  }}
                  sx={{
                    "&:hover": iconHoverStyle,
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title="WhatsApp" sx={{ marginLeft: "10px" }}>
              <a href="https://wa.me/9442557269" rel="noopener noreferrer">
                <WhatsAppIcon
                  style={{
                    ...iconStyle,
                    background: "rgb(0, 136, 84)",
                    color: "#fff",
                  }}
                  sx={{
                    "&:hover": iconHoverStyle,
                  }}
                />
              </a>
            </Tooltip>
          </Box>
        </Grid>

        {/* Pages List */}
        <Grid item xs={12} md={6} lg={3} sx={{ padding: "10px", textAlign: { xs: "left", md: "left" } }}>
          <Box>
            <Typography style={{ fontSize: "17px" }} gutterBottom>
              Pages
            </Typography>
            <List style={{ textAlign: "left" }}>
              {pages.map((page, index) => (
                <React.Fragment key={page.path}>
                  <ListItem
                    component={Link}
                    to={page.path}
                    button
                    sx={listItemStyle}
                    style={{ textDecoration: "none", fontWeight: "600" }}
                  >
                    <ListItemText primary={page.name} primaryTypographyProps={{ style: { fontSize: "14px" } }} />
                  </ListItem>
                  {index !== pages.length && <Divider style={{ backgroundColor: "#ABA1A1", marginRight: "20px" }} />}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Grid>


        {/* Latest Events */}
        <Grid item xs={12} md={6} lg={3} sx={{ padding: "10px" }}>
          <Typography gutterBottom style={{ textAlign: "left", fontSize: "17px" }}>
        Latest Events
          </Typography>
          <List>
            {latestEvents.map((event, index) => (
              <React.Fragment key={event.name}>
                <ListItem disableGutters>
                  <Link to="/gallery" style={{ textDecoration: "none", width: "100%" }}>
                    <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
                      <Box style={{ flex: 1 }}>
                        <img src={event.image} alt={event.name} style={{ width: "100%", height: "auto", marginRight: "10px" }} />
                      </Box>
                      <Box style={{ flex: 3, marginLeft: "10px" }}>
                        <Typography variant="body1" sx={{ cursor: "pointer", "&:hover": { color: COLORS.FOOTER_HOVER_COLOR}, fontSize: "14px", color: COLORS.WHITE }}>{event.name}</Typography>
                        <Typography variant="body2" style={{ fontSize: "12px", color: "#888", width: "100%" }}>{event.date}</Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItem>
                {index !== latestEvents.length - 1 && <Divider style={{ backgroundColor: "#ABA1A1", marginRight: "20px", marginBottom: "10px" }} />}
              </React.Fragment>
            ))}
          </List>
        </Grid>

        {/* Contact Section */}
        <Grid item xs={12} md={6} lg={3} sx={{ padding: "10px" }}>
          <Typography gutterBottom style={{ textAlign: "left", fontSize: "17px" }}>
            Contact
          </Typography>
          <Box style={{ display: "flex", alignItems: "center", marginBottom: "15px", marginTop: "20px" }}>
            <CallIcon sx={{ marginRight: "10px", cursor: "pointer", "&:hover": { color: COLORS.FOOTER_HOVER_COLOR } }} onClick={handlePhoneClick} />
            <Typography variant="body1" sx={{ cursor: "pointer", "&:hover": { color: COLORS.FOOTER_HOVER_COLOR }, fontSize: "14px" }} onClick={handlePhoneClick}>91-9442557269</Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
            <EmailIcon sx={{ marginRight: "10px", cursor: "pointer", "&:hover": { color: COLORS.FOOTER_HOVER_COLOR } }} onClick={handleEmailClick} />
            <Typography variant="body1" sx={{ cursor: "pointer", "&:hover": { color: COLORS.FOOTER_HOVER_COLOR }, fontSize: "14px" }} onClick={handleEmailClick}>infowhatnextedu@gmail.com</Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
            <RoomIcon sx={{ marginRight: "10px", cursor: "pointer", "&:hover": { color: COLORS.FOOTER_HOVER_COLOR } }} />
            <Typography variant="body1" style={{ cursor: "pointer", "&:hover": { color: COLORS.FOOTER_HOVER_COLOR, textDecoration: "underline" } }}>
              <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" style={{ color: "#fff", textDecoration: "none", fontSize: "14px" }}>
              Old Bus Stand Inside, Police Booth Upstairs, Theni - 625531.
              </a>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;

