import React, { useState, useEffect, useRef} from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CardMedia,
  InputAdornment,
  TextField,Breadcrumbs, Link
} from "@mui/material";
import AlliedHealthScience from "../../assets/courses/health.avif";
import health1 from "../../assets/coursesDetail/Arts & Science/27.jpg";
import health2 from "../../assets/coursesDetail/Arts & Science/28.jpg";
import health3 from "../../assets/coursesDetail/Arts & Science/29.jpg";
import health4 from "../../assets/coursesDetail/Arts & Science/30.jpg";
import health5 from "../../assets/coursesDetail/Arts & Science/31.jpg";
import health6 from "../../assets/coursesDetail/Arts & Science/8.jpg";
import health7 from "../../assets/coursesDetail/Arts & Science/32.jpg";
import health8 from "../../assets/coursesDetail/Arts & Science/33.jpg";
import health9 from "../../assets/coursesDetail/Arts & Science/34.jpg";
import health10 from "../../assets/coursesDetail/Arts & Science/35.jpg";
import health11 from "../../assets/coursesDetail/Arts & Science/36.jpg";
import health12 from "../../assets/coursesDetail/Arts & Science/37.jpg";
import health13 from "../../assets/coursesDetail/Arts & Science/38.jpg";
import health14 from "../../assets/coursesDetail/Arts & Science/39.jpg";
import health15 from "../../assets/coursesDetail/Arts & Science/40.jpg";
import health16 from "../../assets/coursesDetail/Arts & Science/41.jpg";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import COLORS from "../../ColorCodes/color";

const disciplines = [
  {
    id: "health1",
    title: "B.SC.(DIALYSIS TECHNOLOGY)",
    description: "is an undergraduate program that focuses on the study of dialysis procedures, equipment, and patient care. Students learn about renal physiology, dialysis techniques, and hemodialysis machines.",
    scope: [
      "Dialysis Technician",
      "Renal Nurse",
      "Dialysis Unit Manager",
      "Clinical Research Coordinator (Dialysis)",
      "Nephrology Technologist",
      "Dialysis Equipment Sales Representative",
      "Dialysis Center Administrator"
    ],
    salary: "₹3,00,000 - ₹5,50,000 per year",
    imageSrc: health1
  },
  {
    id: "health2",
    title: "B.SC.(PHYSICIAN ASSISTANT)",
    description: "is a degree program that trains students to work as part of a healthcare team, assisting physicians in patient care. Students learn about allied-health-science terminology, diagnostic procedures, and treatment protocols.",
    scope: [
      "Physician Assistant",
      "Medical Assistant",
      "Surgical Assistant",
      "Emergency Medicine Technician",
      "Clinical Research Coordinator",
      "Healthcare Administrator",
      "Community Health Worker"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health2
  },
  {
    id: "health3",
    title: "B.SC.(RADIOGRAPHY & IMAGING TECHNOLOGY)",
    description: "is a program that focuses on allied-health-science imaging techniques such as X-ray, CT scan, MRI, and ultrasound. Students learn about radiation safety, image interpretation, and patient positioning.",
    scope: [
      "Radiologic Technologist",
      "MRI Technologist",
      "CT Technologist",
      "Ultrasound Technologist",
      "Nuclear Medicine Technologist",
      "Radiography Supervisor",
      "Imaging Equipment Sales Representative"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health3
  },
  {
    id: "health4",
    title: "B.SC.(CARDIO PULMONARY & PERFUSION TECHNOLOGY)",
    description: "is a degree program that focuses on heart and lung function, as well as extracorporeal circulation techniques. Students learn about cardiac catheterization, pulmonary function testing, and cardiopulmonary bypass.",
    scope: [
      "Perfusionist",
      "Cardiovascular Technician",
      "Respiratory Therapist",
      "Cardiac Cath Lab Technologist",
      "Pulmonary Function Technologist",
      "Clinical Research Coordinator (Cardiology)",
      "Medical Equipment Specialist"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: health4
  },
  {
    id: "health5",
    title: "B.SC.(OPERATION THEATRE & ANESTHESIA TECHNOLOGY)",
    description: "is a program that focuses on surgical procedures, anesthesia administration, and operating room management. Students learn about sterile techniques, anesthesia equipment, and patient monitoring.",
    scope: [
      "Operation Theatre Technician",
      "Anesthesia Technologist",
      "Surgical Assistant",
      "Anesthesia Assistant",
      "Surgical Scrub Nurse",
      "Operating Room Manager",
      "Surgical Equipment Sales Representative"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health5
  },
  {
    id: "health6",
    title: "B.SC.(OPTOMETRY)",
    description: "is an undergraduate program that focuses on eye care and vision science. Students learn about eye anatomy, vision disorders, diagnostic techniques, and optometric practice management.",
    scope: [
      "Optometric Practice",
      "Ophthalmic Research",
      "Contact Lens Optometry",
      "Pediatric Optometry",
      "Low Vision Rehabilitation",
      "Vision Therapy",
      "Public Health Optometry"
    ],
    salary: "₹4,00,000 - ₹6,00,000 per year",
    imageSrc: health6
  },
  {
    id: "health7",
    title: "B.SC.(CARDIAC TECHNOLOGY)",
    description: "is an undergraduate program that focuses on the study of heart diseases, diagnostic tests, and cardiac monitoring techniques. Students learn about electrocardiography, echocardiography, and stress testing.",
    scope: [
      "Cardiac Technologist",
      "Echocardiography Technologist",
      "Cardiac Cath Lab Technologist",
      "Holter Monitor Technician",
      "Stress Test Technologist",
      "Pacemaker Technician",
      "Cardiac Rehabilitation Specialist"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health7
  },
  {
    id: "health8",
    title: "B.SC.(CARDIOVASCULAR TECHNOLOGY)",
    description: "is a degree program that focuses on the diagnosis and treatment of cardiovascular diseases. Students learn about vascular imaging, hemodynamics, and invasive cardiac procedures.",
    scope: [
      "Cardiovascular Technologist",
      "Vascular Technologist",
      "Echocardiography Technologist",
      "Cardiac Cath Lab Technologist",
      "Invasive Cardiovascular Specialist",
      "Clinical Research Coordinator (Cardiology)",
      "Cardiovascular Equipment Sales Representative"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: health8
  },
  {
    id: "health9",
    title: "B.SC.(MEDICAL LAB TECHNOLOGY)",
    description: "is a program that focuses on laboratory procedures and techniques used in allied-health-science diagnosis and treatment. Students learn about microbiology, hematology, clinical chemistry, and immunology.",
    scope: [
      "Medical Laboratory Technologist",
      "Clinical Laboratory Scientist",
      "Pathology Technician",
      "Blood Bank Technologist",
      "Molecular Biologist",
      "Laboratory Supervisor",
      "Quality Assurance Specialist"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health9
  },
  {
    id: "health10",
    title: "B.SC.(CLINICAL PSYCHOLOGY)",
    description: "is a degree program that focuses on the study of psychological disorders, therapy techniques, and mental health assessment. Students learn about counseling methods, psychotherapy, and psychological testing.",
    scope: [
      "Clinical Psychologist",
      "Counseling Psychologist",
      "Mental Health Counselor",
      "Behavioral Therapist",
      "Rehabilitation Counselor",
      "Child Psychologist",
      "Psychiatric Technician"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health10
  },
  {
    id: "health11",
    title: "B.SC.(ANATOMY)",
    description: "is an undergraduate program that focuses on the study of the structure and function of the human body. Students learn about anatomical principles, organ systems, and allied-health-science imaging techniques.",
    scope: [
      "Anatomist",
      "Medical Illustrator",
      "Forensic Scientist",
      "Surgical Technician",
      "Medical Writer",
      "Anatomical Model Maker",
      "Healthcare Educator"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health11
  },
  {
    id: "health12",
    title: "B.SC.(BIOCHEMISTRY)",
    description: "is a degree program that focuses on the study of chemical processes within living organisms. Students learn about molecular biology, metabolism, and biochemical pathways.",
    scope: [
      "Biochemist",
      "Pharmaceutical Research Scientist",
      "Clinical Biochemist",
      "Biotechnology Analyst",
      "Food Scientist",
      "Forensic Scientist",
      "Molecular Biologist"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: health12
  },
  {
    id: "health13",
    title: "B.SC.(NEURO ELECTRO PHYSIOLOGY)",
    description: "is a program that focuses on the study of electrical activity in the nervous system. Students learn about neurophysiology, electroencephalography (EEG), and nerve conduction studies.",
    scope: [
      "Neurophysiologist",
      "EEG Technologist",
      "Neurodiagnostic Technologist",
      "Clinical Neurophysiologist",
      "Neuropsychologist",
      "Electrophysiology Technician",
      "Neurology Research Assistant"
    ],
    salary: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: health13
  },
  {
    id: "health14",
    title: "B.SC.(CLINICAL NUTRITION & DIETETICS)",
    description: "is a degree program that focuses on the study of nutrition science and its application in clinical settings. Students learn about dietary assessment, therapeutic nutrition, and nutrition counseling.",
    scope: [
      "Clinical Dietitian",
      "Nutrition Consultant",
      "Wellness Coach",
      "Public Health Nutritionist",
      "Sports Nutritionist",
      "Food Service Manager",
      "Research Dietitian"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health14
  },
  {
    id: "health15",
    title: "B.SC.(MICROBIOLOGY)",
    description: "is an undergraduate program that focuses on the study of microorganisms, including bacteria, viruses, fungi, and parasites. Students learn about microbial physiology, immunology, and infection control.",
    scope: [
      "Microbiologist",
      "Clinical Microbiologist",
      "Food Microbiologist",
      "Industrial Microbiologist",
      "Environmental Microbiologist",
      "Biotechnology Research Scientist",
      "Quality Control Microbiologist"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health15
  },
  {
    id: "health16",
    title: "B.SC.(PSYCHOLOGY)",
    description: "is a degree program that focuses on the study of human behavior, mental processes, and emotional responses. Students learn about psychological theories, research methods, and therapeutic techniques.",
    scope: [
      "Psychologist",
      "Counselor",
      "Human Resources Specialist",
      "Market Research Analyst",
      "Educational Psychologist",
      "Forensic Psychologist",
      "Mental Health Technician"
    ],
    salary: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: health16
  }
];

const AlliedHealthSciences = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDisciplines, setFilteredDisciplines] = useState(disciplines);

  useEffect(() => {
    const handleFiltering = () => {
      if (!searchTerm.trim()) {
        setFilteredDisciplines(disciplines);
        return;
      }
      const filtered = disciplines.filter(discipline =>
        discipline.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDisciplines(filtered);
    };
    handleFiltering();
  }, [searchTerm, disciplines]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCardClick = (index,name) => {
    setSelectedCard(selectedCard === index ? null : index);
    navigate(`/courses/allied-health-science/${name}`);
  };

  const CardComponent = () => {
    return (
      <div style={{ background: COLORS.GREY_COLOR, paddingLeft: "15px", paddingRight: "15px", paddingBottom: "22px" }}>
        {filteredDisciplines.length === 0 ? (
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            No courses found
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredDisciplines.map((discipline, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    cursor: "pointer",
                    transition: "box-shadow 0.3s ease-in-out",
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BACKGROUND_COLOR0,
                    "&:hover": {
                      boxShadow: 10,
                      color: COLORS.BACKGROUND_COLOR1
                    },
                  }}
                  onClick={() => handleCardClick(index, discipline.title)}
                >
                  <CardMedia
                    component="img"
                    image={discipline.imageSrc}
                    alt={discipline.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {discipline.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };

  return (
    <Box sx={{ padding: "36px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/courses"  color={COLORS.MUTED}>
          Courses
        </Link>
        <Link component={RouterLink} to="/courses/allied-health-science"style={{fontWeight: "600"}} color={COLORS.BACKGROUND_COLOR1}>
        Allied Health Science
        </Link>
      </Breadcrumbs>
      <div style={{  background: COLORS.GREY_COLOR, marginTop: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={titleRef}
            variant="h1"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginBottom: "10px",
              fontSize: "25px",
              textAlign: "center",
              background: COLORS.BACKGROUND_COLOR0,
              padding: "10px",
              color: COLORS.BACKGROUND_COLOR2,
              flexGrow: 1,
            }}
          >
           Allied health science
          </Typography>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
          <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
          Allied courses refer to supplementary or complementary educational programs that support and enhance skills relevant to a specific profession or industry. These courses are designed to provide additional knowledge and expertise, often focusing on practical applications, to individuals seeking a broader skill set in conjunction with their primary field of study or profession. Allied courses are tailored to complement and enrich the learning experience in diverse career paths.
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: "10px", textAlign: "justify" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Enhanced Skill Set:</strong> Allied courses provide individuals with additional skills and knowledge, complementing their primary field of study or profession and enhancing their overall skill set.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Career Diversification:</strong> Completing allied courses allows individuals to diversify their career options by gaining expertise in related areas, making them more versatile and adaptable in the job market.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Professional Advancement:</strong> Allied courses contribute to professional growth by offering specialized knowledge and staying updated on emerging trends, ensuring individuals remain competitive in their respective fields.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Increased Employability:</strong> Having a broader skill set through allied courses makes individuals more attractive to employers, increasing their chances of securing employment and thriving in dynamic work environments.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Holistic Learning Experience:</strong> Pursuing allied courses enriches the overall learning experience, fostering a well-rounded education that combines specialized expertise with a broader understanding of related disciplines, preparing individuals for multifaceted challenges in their careers.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <img src={AlliedHealthScience} alt="AlliedHealthScience" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: "10px", paddingRight:"10px"}}>
          <h2 style={{color: COLORS.BACKGROUND_COLOR0}}>COURSES OFFERED ({disciplines.length} courses)</h2>
          <TextField
            placeholder="Search courses..."
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box sx={{ mt: 4 }}>
          <CardComponent />
        </Box>
      </div>
    </Box>
  );
};

export default AlliedHealthSciences;
