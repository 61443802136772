import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { AccountCircle, Logout as LogoutIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import { useTimer } from "react-timer-hook";
import LoginStudents from "./LoginStudents";
import RegisteredStudents from "./RegisteredStudents";
import COLORS from "../../ColorCodes/color";
import { SessionContext } from "../../screens/SessionContext";

const Dashboard = () => {
  const { setIsAdminSessionExpired } = useContext(SessionContext);
  const [view, setView] = useState("registeredStudents");
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const openMenu = Boolean(anchorEl);
  const [filters, setFilters] = useState({
    createdAt: null,
    preferredCourse: "",
    preferredCollege: "",
    monthYear: null,
  });
  const [tempFilters, setTempFilters] = useState({
    createdAt: null,
    preferredCourse: "",
    preferredCollege: "",
    monthYear: null,
  });
  const [filtersLogin, setFiltersLogin] = useState({
    createdAt: null,
    preferredCourse: "",
    preferredCollege: "",
    monthYear: null,
  });
  const [tempFiltersLogin, setTempFiltersLogin] = useState({
    createdAt: null,
    preferredCourse: "",
    preferredCollege: "",
    monthYear: null,
  });


  const getExpiryTimestamp = () => {
    const token = localStorage.getItem("adminToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      const expiryTime = decodedToken.exp * 1000;
      return new Date(expiryTime);
    }
    return new Date();
  };

  const handleSessionExpired = () => {
    localStorage.removeItem("adminToken");
    localStorage.removeItem("userDetails");
    setIsAdminSessionExpired(true);
    navigate("/admin");
  };

  const { seconds, minutes, hours, days, start } = useTimer({
    expiryTimestamp: getExpiryTimestamp(),
    onExpire: handleSessionExpired,
  });

  useEffect(() => {
    start(getExpiryTimestamp());
  }, [start]);

  const handleViewChange = (event, newValue) => {
    setView(newValue);
  };

  const handleLogout = () => {
    setOpenLogoutDialog(true);
  };

  const handleConfirmLogout = () => {
    handleSessionExpired();
    setOpenLogoutDialog(false);
  };

  const handleCancelLogout = () => {
    setOpenLogoutDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuLogout = () => {
    handleMenuClose();
    handleLogout();
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
        <Box>
          <Typography
            variant="h2"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginTop: "10px",
              fontSize: "23px",
            }}
          >
            Admin Dashboard
          </Typography>
          <hr
            style={{
              width: "50%",
              backgroundColor: COLORS.BACKGROUND_COLOR1,
              height: "4px",
              border: "none",
              margin: "10px 0",
            }}
          />
        </Box>
        <Box>
          <IconButton onClick={handleMenuOpen} color="inherit">
            <AccountCircle sx={{ fontSize: "32px" }} />
          </IconButton>
          <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuLogout}>
              <LogoutIcon sx={{ marginRight: 1 }} />
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Tabs
        value={view}
        onChange={handleViewChange}
        aria-label="dashboard tabs"
        variant="fullWidth"
        sx={{ mb: 3, backgroundColor: COLORS.BACKGROUND_COLOR0 }}
        TabIndicatorProps={{
          style: {
            height: "0px",
          },
        }}
      >
        <Tab
          label="Registered Students"
          value="registeredStudents"
          disableRipple
          sx={{
            color: view === "registeredStudents" ? "#00ABFF" : "#00ABFF",
            fontWeight: view === "registeredStudents" ? "bold" : "normal",
            "&.Mui-selected": {
              color: "white",
              fontWeight: "bold",
            },
          }}
        />
        <Tab
          label="Login Students"
          value="loginStudents"
          disableRipple
          sx={{
            color: view === "loginStudents" ? "#00ABFF" : "#00ABFF",
            fontWeight: view === "loginStudents" ? "bold" : "normal",
            "&.Mui-selected": {
              color: "white",
              fontWeight: "bold",
            },
          }}
        />
      </Tabs>

      <Box sx={{ p: 0 }}>
        {view === "registeredStudents" && <RegisteredStudents filters={filters} setFilters={setFilters} tempFilters={tempFilters} setTempFilters={setTempFilters}/>}
        {view === "loginStudents" && <LoginStudents filtersLogin={filtersLogin} setFiltersLogin={setFiltersLogin} tempFiltersLogin={tempFiltersLogin} setTempFiltersLogin={setTempFiltersLogin} />}
      </Box>

      {/* Logout Confirmation Dialog */}
      <Dialog open={openLogoutDialog} onClose={handleCancelLogout}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          Are you sure you want to logout? Your session will expire in {`${days}d ${hours}h ${minutes}m ${seconds}s`}.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLogout} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
