import React from "react";
import { Typography } from "@mui/material";
import FastMarquee from "react-fast-marquee";
import imageSrc from "../../assets/new-gif-i.gif";
import COLORS from "../../ColorCodes/color";
const MarqueeText = ({ googleFormUrl }) => {

  const handleMarqueeClick = () => {
    window.open(googleFormUrl, "_blank");
  };

  return (
    <FastMarquee speed={100} onClick={handleMarqueeClick} style={{ cursor: "pointer" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={imageSrc} alt="" style={{ marginRight: "10px", height: "30px", width: "auto" }} />
        <Typography variant="body2" align="center" sx={{ fontStyle: "italic", color: COLORS.BACKGROUND_COLOR0, fontSize: "15px" }}>
          <a href={googleFormUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit", fontSize: "15px", fontWeight :"600" }}>
            We are providing free career guidance. If you are interested, please contact us 9442557269, 7592025726
          </a>
        </Typography>
      </div>
    </FastMarquee>
  );
};

export default MarqueeText;
