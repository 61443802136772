import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SessionContext } from "../screens/SessionContext";

const PublicRoute = ({ element }) => {
  const { isSessionExpired } = useContext(SessionContext);

  // If session is not expired (i.e., user is logged in), redirect to home or another page
  if (!isSessionExpired) {
    return <Navigate to="/questionBank" />;
  }

  // If session is expired, render the element
  return element;
};

export default PublicRoute;
