import React, { useState, useEffect} from "react";
import { Box, Typography, Card, CardActionArea, CardContent, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ImageGallery2023 from "./gallery/ImageGallery2023";
import COLORS from "../ColorCodes/color";

const Gallery = () => {
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCardClick = (year) => {
    if (year === "2024" || year === "2023") {
      navigate(`/gallery/${year}`);
    } else {
      setSelectedYear(year);
    }
  };

  const getBackgroundColor = (year) => {
    return year === "2024" ? "#f5a623" : "#92b3e8";
  };

  return (
    <Box sx={{ padding: 2 }}>
      {!selectedYear ? (
        <>
          <Typography
            variant="h2"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginTop: "10px",
              fontSize: "23px",
            }}
          >
            Visuals Expo
          </Typography>
          <hr
            style={{
              width: "10%",
              backgroundColor: COLORS.BACKGROUND_COLOR1,
              height: "4px",
              border: "none",
              margin: "10px 0",
            }}
          />
          <Typography
            variant="subtitle1"
            style={{
              marginTop: "10px",
              fontSize: "16px",
              color: "#555",
            }}
          >
            Welcome to our image gallery. Here you can find photos of various meetings conducted in different districts. Browse through the images to get a glimpse of our events and activities.
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            {["2024", "2023"].map((year) => (
              <Grid item xs={12} sm={6} md={2} key={year}>
                <Card sx={{ height: "100%", backgroundColor: COLORS.GREY_COLOR }} onClick={() => handleCardClick(year)}>
                  <CardActionArea sx={{ height: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        paddingTop: "75%",
                        position: "relative",
                        backgroundColor: getBackgroundColor(year),
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        clipPath: "polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%)",
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="div"
                        style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
                      >
                        {year}
                      </Typography>
                    </Box>
                    <CardContent style={{}}>
                      <Typography variant="subtitle1" align="center" style={{ marginTop: "8px", fontWeight: "600", color: COLORS.BACKGROUND_COLOR0 }}>
                        {year} Education Fair
                      </Typography>
                      <Typography variant="subtitle2" align="center" style={{ color: COLORS.BACKGROUND_COLOR0 }}>
                        {year === "2023" ? "(Sivakasi)" : "(Theni, Viruthunagar)"}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <ImageGallery2023 />
      )}
    </Box>
  );
};

export default Gallery;
