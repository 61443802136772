import React, { useEffect } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography, Card, CardContent, Grid, List, ListItem, ListItemText, ListItemIcon, Button } from "@mui/material";
import { FiberManualRecord as FiberManualRecordIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import nurse1 from "../../assets/coursesDetail/Nursing/2.jpg";
import nurse2 from "../../assets/coursesDetail/Nursing/3.jpg";
import nurse3 from "../../assets/coursesDetail/Nursing/4.jpg";
import nurse4 from "../../assets/coursesDetail/Nursing/5.jpg";
import nurse5 from "../../assets/coursesDetail/Nursing/6.jpg";
import nurse6 from "../../assets/coursesDetail/Nursing/7.jpg";
import nurse7 from "../../assets/coursesDetail/Nursing/8.jpg";
import COLORS from "../../ColorCodes/color";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const disciplines = [
  {
    id: "nurse1",
    title: "BSC. (NURSING)",
    description:
        "is an undergraduate degree program that focuses on the study of nursing principles, healthcare practices, and patient care. Students learn about anatomy, physiology, pharmacology, and nursing ethics.",
    scope: [
      "Registered Nurse",
      "Nurse Practitioner",
      "Nurse Educator",
      "Nurse Manager",
      "Critical Care Nurse",
      "Pediatric Nurse",
      "Public Health Nurse"
    ],
    salary: "₹3,00,000 - ₹6,00,000 per year",
    imageSrc: nurse1
  },
  {
    id: "nurse2",
    title: "ANM (AUXILIARY NURSE MIDWIFERY)",
    description:
        "is a diploma program that focuses on basic nursing skills, maternal and child healthcare, and community nursing. Students learn about primary healthcare, maternal care, and preventive healthcare practices.",
    scope: [
      "Auxiliary Nurse Midwife",
      "Community Health Worker",
      "Maternity Nurse",
      "Health Educator",
      "Family Planning Counselor",
      "Rural Healthcare Worker",
      "Immunization Coordinator"
    ],
    salary: "₹2,00,000 - ₹4,00,000 per year",
    imageSrc: nurse2
  },
  {
    id: "nurse3",
    title: "DIPLOMA NURSING",
    description:
        "is a program that focuses on the study of nursing principles, patient care, and healthcare practices. Students learn about medical-surgical nursing, pediatric nursing, and psychiatric nursing.",
    scope: [
      "Licensed Practical Nurse",
      "Nursing Assistant",
      "Medical-Surgical Nurse",
      "Psychiatric Nurse",
      "Pediatric Nurse",
      "Geriatric Nurse",
      "Home Health Nurse"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: nurse3
  },
  {
    id: "nurse4",
    title: "GNM (GENERAL NURSING AND MIDWIFERY)",
    description:
        "is a diploma program that focuses on the study of nursing principles, healthcare practices, and midwifery skills. Students learn about medical-surgical nursing, obstetrics, and gynecology.",
    scope: [
      "Staff Nurse",
      "Midwife",
      "Community Health Nurse",
      "Nursing Supervisor",
      "Critical Care Nurse",
      "Operation Theatre Nurse",
      "Infection Control Nurse"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: nurse4
  },
  {
    id: "nurse5",
    title: "B.PHARM",
    description:
        "or Bachelor of Pharmacy, is an undergraduate degree program focused on pharmaceutical science and medication management. Students study drug composition, development, and its effects on the human body, preparing them for roles in pharmacy practice, research, and the pharmaceutical industry. The curriculum typically includes coursework in pharmacology, medicinal chemistry, pharmaceutics, and pharmacy practice.",
    scope: [
      "Pharmacist Assistant",
      "Pharmaceutical Sales Representative",
      "Clinical Research Associate",
      "Healthcare Consultant",
      "Drug Safety Associate",
      "Medical Writer",
      "Pharmacy Manager"
    ],
    salary: "₹3,00,000 - ₹5,00,000 per year",
    imageSrc: nurse5
  },
  {
    id: "nurse6",
    title: "D.PHARM",
    description:
        "(Diploma in Pharmacy) is a diploma program that focuses on the study of pharmaceutical sciences, drug dispensing, and pharmacy practice. Students learn about pharmacy nursing-pharmacys, drug dosage forms, and drug interactions.",
    scope: [
      "Pharmacist Assistant",
      "Pharmacy Technician",
      "Hospital Pharmacy Assistant",
      "Retail Pharmacy Assistant",
      "Pharmaceutical Sales Assistant",
      "Drug Safety Assistant",
      "Pharmacy Manager"
    ],
    salary: "₹2,00,000 - ₹4,00,000 per year",
    imageSrc: nurse6
  },
  {
    id: "nurse7",
    title: "PHARM D",
    description:
        "(Doctor of Pharmacy) is a professional doctoral degree program that focuses on advanced pharmacy practice and patient care. Students learn about clinical pharmacy, pharmacotherapy, and patient counseling.",
    scope: [
      "Clinical Pharmacist",
      "Pharmacist Consultant",
      "Hospital Pharmacist",
      "Clinical Research Pharmacist",
      "Pharmacovigilance Officer",
      "Drug Information Specialist",
      "Pharmaceutical Care Specialist"
    ],
    salary: "₹4,00,000 - ₹8,00,000 per year",
    imageSrc: nurse7
  },
];

const NursingDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const disciplineDetails = disciplines.find((discipline) => discipline.title === name);
  if (!disciplineDetails) {
    return <Typography variant="h5">Courses not found.</Typography>;
  }

  const { title, description, scope, salary } = disciplineDetails;

  const currentIndex = disciplines.findIndex((discipline) => discipline.title === name);
  const prevDiscipline = currentIndex > 0 ? disciplines[currentIndex - 1] : null;
  const nextDiscipline = currentIndex < disciplines.length - 1 ? disciplines[currentIndex + 1] : null;

  return (
    <Box sx={{ padding: "36px" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
            Courses
          </Link>
          <Link component={RouterLink} to="/courses/nursing-pharmacy" color={COLORS.MUTED}>
            Nursing & Pharmacy
          </Link>
          <Typography color={COLORS.BACKGROUND_COLOR1} style={{fontWeight: "600"}}>{title}</Typography>
        </Breadcrumbs>
        <Box sx={{ display: { xs: "flex", md: "none" }, gap: "18px", marginTop: "10px"}}>
          {prevDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={RouterLink}
              to={`/courses/nursing-pharmacy/${prevDiscipline.title}`}
            >
              Prev
            </Button>
          )}
          {nextDiscipline && (
            <Button
              sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
              },}}
              size="small"
              endIcon={<ArrowForwardIcon />}
              component={RouterLink}
              to={`/courses/nursing-pharmacy/${nextDiscipline.title}`}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>

      <Card style={{ textAlign: "justify", height: "100%", marginTop: "20px", backgroundColor:COLORS.GREY_COLOR  }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} md={7} order={{ xs: 1, md: 1 }}>
              {/* Title and description */}
              <Typography variant="h6" gutterBottom style={{ marginTop: "10px",  fontFamily: COLORS.FONT,fontWeight: "600", textAlign: "left", color: COLORS.BACKGROUND_COLOR0 }}>
                {title}
              </Typography>
              <hr
                style={{
                  width: "15%",
                  backgroundColor: COLORS.BACKGROUND_COLOR1,
                  height: "4px",
                  border: "none",
                  margin: "5px 0",
                }}
              />
              <Typography variant="body1" paragraph style={{marginTop : "20px"}}>
                {title} {description}
              </Typography>

              {/* Scope */}
              <Typography variant="body1">
                <strong style={{color: COLORS.STRONG_COLOR}}>Scope:</strong>
              </Typography>
              <List dense>
                {scope.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon style={{ minWidth: "24px" }}>
                      <FiberManualRecordIcon style={{ fontSize: "13px" }} />
                    </ListItemIcon>
                    <ListItemText primary={item} disableTypography />
                  </ListItem>
                ))}
              </List>

              {/* Salary range */}
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                <strong style={{color: COLORS.STRONG_COLOR}}>Salary:</strong>{" "}
                <span style={{ padding: "3px 6px", borderRadius: "5px" }}>{salary}</span>
              </Typography>
            </Grid>

            {/* Image column */}
            <Grid item xs={12} sm={3} md={5} order={{ xs: 2, md: 2 }}>
              <Box sx={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "baseline", height: "100%", marginTop: { xs: "10px", md: "30px" } }}>
                <img src={disciplineDetails.imageSrc} alt={title} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BLACK,
                    padding: "10px 20px",
                    marginTop: "30px",
                    transition: "transform 0.3s ease",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100%"
                  }}
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1)";
                  }}
                  endIcon={<ArrowRightAltIcon style={{ backgroundColor: COLORS.BACKGROUND_COLOR2, color: COLORS.BLACK }} />}
                >
          Contact Us
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "space-between", marginTop: "20px" }}>
            {prevDiscipline && (
              <Button
                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                startIcon={<ArrowBackIcon />}
                component={RouterLink}
                to={`/courses/nursing-pharmacy/${prevDiscipline.title}`}
              >
                Previous Course
              </Button>
            )}
            {nextDiscipline && (
              <Button
                sx={{color: COLORS.BACKGROUND_COLOR2, background: COLORS.BACKGROUND_COLOR0, "&:hover": {
                  background: COLORS.BACKGROUND_COLOR0, color: COLORS.BACKGROUND_COLOR1
                },}}
                endIcon={<ArrowForwardIcon />}
                component={RouterLink}
                to={`/courses/nursing-pharmacy/${nextDiscipline.title}`}
              >
                Next Course
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NursingDetail;
