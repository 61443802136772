import React, { useState, useEffect, useRef} from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CardMedia,
  InputAdornment,
  TextField,Breadcrumbs, Link
} from "@mui/material";
import management from "../../assets/courses/management.avif";
import bba1 from "../../assets/coursesDetail/Management/2.jpg";
import bba2 from "../../assets/coursesDetail/Management/3.jpg";
import bba3 from "../../assets/coursesDetail/Management/4.jpg";
import bba4 from "../../assets/coursesDetail/Management/5.jpg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import COLORS from "../../ColorCodes/color";

const disciplines = [
  {
    id: "BBA1",
    title: "BBA (BUSINESS ADMINISTRATION)",
    description:
        "is an undergraduate program that focuses on the study of business management principles, practices, and strategies. Students learn about organizational behavior, marketing, finance, and human resource management.",
    scopeItems: [
      "Management Consultant",
      "Marketing Manager",
      "Financial Analyst ",
      "Human Resources Manager",
      "Operations Manager",
      "Business Development Executive",
      "Entrepreneur"
    ],
    salaryRange: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: bba1
  },
  {
    id: "BBA2",
    title: "BBA (COMPUTER APPLICATION)",
    description:
        "is a degree program that combines business management principles with computer applications. Students learn about business information systems, software development, and database management.",
    scopeItems: [
      "IT Project Manager",
      "Business Analyst (IT)",
      "Systems Administrator",
      "Database Administrator",
      "Web Developer",
      "Software Quality Assurance Analyst",
      "IT Consultant"
    ],
    salaryRange: "₹3,00,000 - ₹5,50,000 per year",
    imageSrc: bba2
  },
  {
    id: "BBA3",
    title: "BBA (LOGISTICS)",
    description:
        "is a program that focuses on the study of supply chain management, transportation, and distribution processes. Students learn about inventory control, logistics management, and international trade.",
    scopeItems: [
      "Logistics Manager",
      "Supply Chain Analyst",
      "Warehouse Operations Manager",
      "Transportation Coordinator",
      "Procurement Specialist",
      "Freight Broker",
      "Distribution Center Manager"
    ],
    salaryRange: "₹3,50,000 - ₹6,00,000 per year",
    imageSrc: bba3
  },
  {
    id: "BBA4",
    title: "BBA (AVIATION MANAGEMENT)",
    description:
        "is an undergraduate program that focuses on the study of aviation management and operations. Students learn about airport management, airline operations, and aviation safety regulations.",
    scopeItems: [
      "Airport Manager",
      "Airline Operations Manager",
      "Aviation Safety Inspector",
      "Flight Dispatcher",
      "Aircraft Maintenance Manager",
      "Aviation Sales Executive",
      "Airline Customer Service Manager"
    ],
    salaryRange: "₹4,00,000 - ₹6,50,000 per year",
    imageSrc: bba4
  },
];
const Management = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDisciplines, setFilteredDisciplines] = useState(disciplines);

  useEffect(() => {
    const handleFiltering = () => {
      if (!searchTerm.trim()) {
        setFilteredDisciplines(disciplines);
        return;
      }
      const filtered = disciplines.filter(discipline =>
        discipline.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDisciplines(filtered);
    };
    handleFiltering();
  }, [searchTerm, disciplines]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCardClick = (index,name) => {
    setSelectedCard(selectedCard === index ? null : index);
    navigate(`/courses/management/${name}`);
  };

  const CardComponent = () => {
    return (
      <div style={{ background: COLORS.GREY_COLOR, paddingLeft: "15px", paddingRight: "15px", paddingBottom: "22px" }}>
        {filteredDisciplines.length === 0 ? (
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            No courses found
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredDisciplines.map((discipline, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    cursor: "pointer",
                    transition: "box-shadow 0.3s ease-in-out",
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BACKGROUND_COLOR0,
                    "&:hover": {
                      boxShadow: 10,
                      color: COLORS.BACKGROUND_COLOR1
                    },
                  }}
                  onClick={() => handleCardClick(index, discipline.title)}
                >
                  <CardMedia
                    component="img"
                    image={discipline.imageSrc}
                    alt={discipline.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {discipline.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };

  return (
    <Box sx={{ padding: "36px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
          Courses
        </Link>
        <Link component={RouterLink} to="/courses/management" style={{fontWeight: "600"}} color={COLORS.BACKGROUND_COLOR1}>
        Management
        </Link>
      </Breadcrumbs>
      <div style={{ padding: "0px", background:COLORS.GREY_COLOR, marginTop: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={titleRef}
            variant="h1"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginBottom: "10px",
              fontSize: "25px",
              textAlign: "center",
              background: COLORS.BACKGROUND_COLOR0,
              padding: "10px",
              color: COLORS.BACKGROUND_COLOR2,
              flexGrow: 1,
            }}
          >
           MANAGEMENT
          </Typography>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
          <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
          Management courses are educational programs that focus on providing knowledge and skills related to the principles, practices, and techniques of effective organizational leadership and administration. These courses cover a range of topics, including strategic planning, decision-making, resource allocation, personnel management, and business ethics, with the goal of preparing individuals for managerial roles in various industries and sectors. Management education often incorporates case studies, practical exercises, and real-world applications to equip students with the abilities necessary for successful leadership and efficient operation of organizations.
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: "10px", textAlign: "justify" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Leadership Skills Development:</strong> Management courses focus on developing leadership skills, including strategic thinking, decision-making, and team management. Graduates are equipped with the capabilities to lead and inspire teams, making them valuable assets in professional settings.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Versatile Career Opportunities:</strong> A management education opens up diverse career opportunities across industries and sectors. Graduates can pursue roles in business, healthcare, finance, hospitality, and more, as effective management is essential for the success of organizations in various fields.              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Problem-Solving Abilities:</strong> Management courses emphasize analytical thinking and problem-solving. Graduates are trained to identify challenges, analyze situations, and implement effective solutions, contributing to the overall efficiency and success of an organization.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Entrepreneurial Skills:</strong> Management education often includes aspects of entrepreneurship, teaching individuals how to start and manage their own businesses. This empowers graduates with the skills needed to navigate the complexities of running a successful enterprise.
              </Typography>
              <Typography variant="body1" paragraph>  <strong style={{color:COLORS.STRONG_COLOR}}>Global Perspective:</strong> Many management courses incorporate an international and cross-cultural perspective. This prepares graduates to work in a globalized business environment, understanding diverse markets, cultural nuances, and international business practices.              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <img src={management} alt="management" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: "10px", paddingRight:"10px"}}>
          <h2 style={{color: COLORS.BACKGROUND_COLOR0}}>COURSES OFFERED ({disciplines.length} courses)</h2>
          <TextField
            placeholder="Search courses..."
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box sx={{ mt: 4 }}>
          <CardComponent />
        </Box>
      </div>
    </Box>
  );
};
export default Management;
