import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Footer from "./screens/Footer";
import Home from "./screens/Home";
import AboutUs from "./screens/AboutUs";
import Courses from "./screens/Courses";
import ContactUs from "./screens/ContactUs";
import EntranceExams from "./screens/EntranceExams";
import Gallery from "./screens/Gallery";
import ImageGallery2024 from "./screens/gallery/ImageGallery2024";
import ImageGallery2023 from "./screens/gallery/ImageGallery2023";
import LoginQuestion from "./screens/LoginQuestion";
import RegisterQuestion from "./screens/RegisterQuestion";
import QuestionBank from "./screens/questionBank/QuestionBank";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import { SessionProvider } from "./screens/SessionContext";
import PDFViewer from "./components/PDFViewer";
import AggricultureDetail from "./screens/coursesDetails/AggricultureDetail";
import Aggriculture from "./screens/coursesDetails/Agriculture";
import ArchitectDetail from "./screens/coursesDetails/ArchitectDetail";
import Architect from "./screens/coursesDetails/Architect";
import Management from "./screens/coursesDetails/Management";
import ManagementDetail from "./screens/coursesDetails/ManagementDetail";
import Law from "./screens/coursesDetails/Law";
import LawDetail from "./screens/coursesDetails/LawDetail";
import Nursing from "./screens/coursesDetails/Nursing";
import NursingDetail from "./screens/coursesDetails/NursingDetail";
import Diploma from "./screens/coursesDetails/Diploma";
import DiplomaDetail from "./screens/coursesDetails/DiplomaDetail";
import Medical from "./screens/coursesDetails/Medical";
import MedicalDetail from "./screens/coursesDetails/MedicalDetails";
import AlliedHealthScienceDetail from "./screens/coursesDetails/AlliedHealthScienceDetail";
import AlliedHealthSciences from "./screens/coursesDetails/AlliedHealthScience";
import ArtsScience from "./screens/coursesDetails/ArtsScience";
import ArtsScienceDetail from "./screens/coursesDetails/ArtsScienceDetail";
import Engineering from "./screens/coursesDetails/Engineering";
import EngineeringDetail from "./screens/coursesDetails/EngineeringDetail";
import AdminLogin from "./screens/admin/AdminLogin";
import Dashboard from "./screens/admin/dashboard";
import AdminProtectedRoute from "./components/AdminProtectedRoute";
import AdminPublicRoute from "./components/AdminPublicRoute";


function App() {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (window.location.pathname.startsWith("/questionBank")|| window.location.pathname.startsWith("/admin")) {
      setOpen(false);
    } else {
      setOpen(false);
    }
  }, [window.location.pathname]);

  return (
    <SessionProvider>
      <Router>
        <ResponsiveAppBar open={open} setOpen={setOpen} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/agriculture" element={<Aggriculture />} />
          <Route path="/courses/agriculture/:name" element={<AggricultureDetail />} />
          <Route path="/courses/architect" element={<Architect />} />
          <Route path="/courses/architect/:name" element={<ArchitectDetail />} />
          <Route path="/courses/management" element={<Management />} />
          <Route path="/courses/management/:name" element={<ManagementDetail />} />
          <Route path="/courses/law" element={<Law />} />
          <Route path="/courses/law/:name" element={<LawDetail />} />
          <Route path="/courses/medical" element={<Medical />} />
          <Route path="/courses/medical/:name" element={<MedicalDetail />} />
          <Route path="/courses/diploma" element={<Diploma />} />
          <Route path="/courses/diploma/:name" element={<DiplomaDetail />} />
          <Route path="/courses/nursing-pharmacy" element={<Nursing />} />
          <Route path="/courses/nursing-pharmacy/:name" element={<NursingDetail />} />
          <Route path="/courses/allied-health-science" element={<AlliedHealthSciences />} />
          <Route path="/courses/allied-health-science/:name" element={<AlliedHealthScienceDetail />} />
          <Route path="/courses/arts-science" element={<ArtsScience />} />
          <Route path="/courses/arts-science/:name" element={<ArtsScienceDetail />} />
          <Route path="/courses/engineering" element={<Engineering />} />
          <Route path="/courses/engineering/:name" element={<EngineeringDetail />} />
          <Route path="/entrance-exams" element={<EntranceExams />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/2024" element={<ImageGallery2024 />} />
          <Route path="/gallery/2023" element={<ImageGallery2023 />} />
          <Route path="/contact" element={<ContactUs setOpen={setOpen} />} />
          <Route path="/login" element={<PublicRoute element={<LoginQuestion setOpen={setOpen} />} />} />
          <Route path="/register" element={<PublicRoute element={<RegisterQuestion setOpen={setOpen} />} />} />
          <Route path="/questionBank" element={<ProtectedRoute element={<QuestionBank setOpen={setOpen} />} />} />
          <Route path="/questionBank/:filename" element={<ProtectedRoute element={<PDFViewer setOpen={setOpen} />} />} />
          <Route path="/admin" element={<AdminPublicRoute element={<AdminLogin setOpen={setOpen} />} />} />
          <Route path="/admin/dashboard" element={<AdminProtectedRoute element={<Dashboard />} />} />
        </Routes>
        <Footer />
      </Router>
    </SessionProvider>
  );
}

export default App;
