import React, { useEffect } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography, Card, CardContent, Grid, List, ListItem, ListItemText, ListItemIcon, Button } from "@mui/material";
import { FiberManualRecord as FiberManualRecordIcon} from "@mui/icons-material";
import barch from "../../assets/coursesDetail/agri/2.jpg";
import COLORS from "../../ColorCodes/color";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const disciplines = [
  {
    id: 1,
    title: "BARCH",
    description:"is a professional degree program that prepares students for a career in architectural design and construction. It covers areas such as architectural theory, building technology, urban planning, and sustainable design principles.",
    scope: [
      "Architectural Design",
      "Urban Planning",
      "Sustainable Architecture",
      "Construction Management",
      "Building Information Modeling (BIM)",
      "Interior Design",
      "Historic Preservation"
    ],
    salary: "₹3,50,000 - ₹5,00,000 per year",
    imageSrc: barch,
  },
];

const ArchitectDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const disciplineDetails = disciplines.find((discipline) => discipline.title === name);
  if (!disciplineDetails) {
    return <Typography variant="h5">Courses not found.</Typography>;
  }

  const { title, description, scope, salary } = disciplineDetails;

  return (
    <Box sx={{ padding: "36px" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
            Courses
          </Link>
          <Link component={RouterLink} to="/courses/architect" color={COLORS.MUTED}>
            Architecture
          </Link>
          <Typography color={COLORS.BACKGROUND_COLOR1} style={{fontWeight: "600"}}>{title}</Typography>
        </Breadcrumbs>
      </Box>

      <Card style={{ textAlign: "justify", height: "100%", marginTop: "20px",backgroundColor:COLORS.GREY_COLOR }}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Text content column */}
            <Grid item xs={12} sm={9} md={7} order={{ xs: 1, md: 1 }}>
              {/* Title and description */}
              <Typography variant="h6" gutterBottom style={{ marginTop: "10px",  fontFamily: COLORS.FONT,fontWeight: "600", textAlign: "left", color: COLORS.BACKGROUND_COLOR0 }}>
                {title}
              </Typography>
              <hr
                style={{
                  width: "10%",
                  backgroundColor: COLORS.BACKGROUND_COLOR1,
                  height: "4px",
                  border: "none",
                  margin: "5px 0",
                }}
              />
              <Typography variant="body1" paragraph  style={{marginTop :"20px"}}>
                {title} {description}
              </Typography>

              {/* Scope */}
              <Typography variant="body1">
                <strong  style={{color: COLORS.STRONG_COLOR}}>Scope:</strong>
              </Typography>
              <List dense>
                {scope.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon style={{ minWidth: "24px" }}>
                      <FiberManualRecordIcon style={{ fontSize: "13px" }} />
                    </ListItemIcon>
                    <ListItemText primary={item} disableTypography />
                  </ListItem>
                ))}
              </List>

              {/* Salary range */}
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                <strong  style={{color: COLORS.STRONG_COLOR}}>Salary:</strong>{" "}
                <span style={{ padding: "3px 6px", borderRadius: "5px" }}>{salary}</span>
              </Typography>
            </Grid>

            {/* Image column */}
            <Grid item xs={12} sm={3} md={5} order={{ xs: 2, md: 2 }}>
              <Box sx={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "baseline", height: "100%", marginTop: { xs: "10px", md: "30px" } }}>
                <img src={disciplineDetails.imageSrc} alt={title} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BLACK,
                    padding: "10px 20px",
                    marginTop: "30px",
                    transition: "transform 0.3s ease",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100%"
                  }}
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = COLORS.BACKGROUND_COLOR2;
                    e.target.style.color = COLORS.BLACK;
                    e.target.style.transform = "scale(1)";
                  }}
                  endIcon={<ArrowRightAltIcon style={{ backgroundColor: COLORS.BACKGROUND_COLOR2, color: COLORS.BLACK }} />}
                >
          Contact Us
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ArchitectDetail;
