import React from "react";
import CarouselComponent from "./homeComponents/BannerImage";
import GridComponent from "./homeComponents/Info";
import MarqueeText from "./homeComponents/MarqueeText";
import FeedbackSection from "./homeComponents/Testimonials";
import COLORS from "../ColorCodes/color";

const Home = () => {
  const googleFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSeo05Y-JA-pgqtNlKn7DDH0_Ck06ZPUf9t_Iugy3R4yyrL82g/viewform";

  return (
    <div>
      <CarouselComponent />
      <GridComponent />
      <div style={{ backgroundColor: COLORS.BACKGROUND_COLOR2, padding: "10px", marginTop: "0px", textAlign: "center" }}>
        <MarqueeText googleFormUrl={googleFormUrl} />
      </div>
      <FeedbackSection />
    </div>
  );
};

export default Home;
