import React, { useState, useEffect, useRef} from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CardMedia,
  InputAdornment,
  TextField,Breadcrumbs, Link
} from "@mui/material";
import diploma from "../../assets/courses/diploma.jpg";
import diploma1 from "../../assets/coursesDetail/diploma/1.jpg";
import diploma2 from "../../assets/coursesDetail/diploma/2.jpg";
import diploma3 from "../../assets/coursesDetail/diploma/3.jpg";
import diploma4 from "../../assets/coursesDetail/diploma/4.jpg";
import diploma5 from "../../assets/coursesDetail/diploma/5.jpg";
import diploma6 from "../../assets/coursesDetail/diploma/6.jpg";
import diploma7 from "../../assets/coursesDetail/diploma/7.jpg";
import diploma8 from "../../assets/coursesDetail/diploma/8.jpg";
import diploma9 from "../../assets/coursesDetail/diploma/9.jpg";
import diploma10 from "../../assets/coursesDetail/diploma/10.jpg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import COLORS from "../../ColorCodes/color";

const disciplines = [
  {
    id: "diploma1",
    title: "DIPLOMA IN CIVIL ENGINEERING",
    description:
        "is a vocational program that provides training in various aspects of civil engineering, including construction techniques, surveying, and structural analysis. Students learn about building design, infrastructure development, and project management.",
    scopeItems: [
      "Civil Engineering Technician",
      "Construction Supervisor",
      "Surveyor",
      "CAD Technician",
      "Estimator",
      "Site Inspector",
      "Highway Technician"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma1
  },
  {
    id: "diploma2",
    title: "DIPLOMA IN MECHANICAL ENGINEERING",
    description:
        "is a vocational program that focuses on the study of mechanical systems, machinery, and manufacturing processes. Students learn about thermodynamics, fluid mechanics, and machine design.",
    scopeItems: [
      "Mechanical Engineering Technician",
      "Maintenance Supervisor",
      "CAD/CAM Engineer",
      "Quality Control Inspector",
      "Production Supervisor",
      "HVAC Technician",
      "Tool Designer"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma2
  },
  {
    id: "diploma3",
    title: "DIPLOMA IN ELECTRICAL ENGINEERING",
    description:
        "is a vocational program that provides training in electrical systems, power generation, and distribution. Students learn about circuit analysis, electrical machines, and renewable energy technologies.",
    scope: [
      "Electrical Engineering Technician",
      "Electrician",
      "Power Plant Operator",
      "Control Panel Designer",
      "Instrumentation Technician",
      "Renewable Energy Technician",
      "Maintenance Electrician"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma3
  },
  {
    id: "diploma4",
    title: "DIPLOMA IN ELECTRONICS AND COMMUNICATION ENGINEERING",
    description:
        "is a vocational program that focuses on the study of electronic devices, circuits, and communication systems. Students learn about analog and digital electronics, telecommunications, and microcontroller programming.",
    scope: [
      "Electronics Technician",
      "Communication Engineer",
      "Network Technician",
      "Embedded Systems Developer",
      "Broadcast Technician",
      "RF Engineer",
      "Telecommunication Technician"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma4
  },
  {
    id: "diploma5",
    title: "DIPLOMA IN COMPUTER SCIENCE AND ENGINEERING",
    description:
        "is a vocational program that provides training in computer hardware, software development, and information technology. Students learn about programming languages, database management, and computer networks.",
    scopeItems: [
      "Computer Technician",
      "IT Support Specialist",
      "Web Developer",
      "Network Administrator",
      "Software Tester",
      "System Analyst",
      "Cybersecurity Technician"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma5
  },
  {
    id: "diploma6",
    title: "DIPLOMA IN CIVIL CONSTRUCTION",
    description:
        "is a vocational program that provides training in construction management, building technology, and project supervision. Students learn about construction materials, structural design, and construction safety regulations.",
    scopeItems: [
      "Construction Supervisor",
      "Building Inspector",
      "Construction Estimator",
      "Site Engineer",
      "Project Coordinator",
      "Quantity Surveyor",
      "Construction Foreman"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma6
  },
  {
    id: "diploma7",
    title: "DIPLOMA IN INTERIOR DESIGN",
    description:
        "is a vocational program that focuses on the study of interior spaces, aesthetics, and design principles. Students learn about space planning, furniture design, and interior decoration techniques.",
    scopeItems: [
      "Interior Designer",
      "Furniture Designer",
      "Architectural Drafter",
      "Visual Merchandiser",
      "Set Designer",
      "Exhibition Designer",
      "Retail Space Planner"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma7
  },
  {
    id: "diploma8",
    title: "DIPLOMA IN HOTEL MANAGEMENT",
    description:
        "is a vocational program that provides training in hospitality operations, food service management, and hotel administration. Students learn about front office operations, housekeeping, and culinary arts.",
    scopeItems: [
      "Hotel Manager",
      "Restaurant Manager",
      "Front Desk Supervisor",
      "Food and Beverage Manager",
      "Banquet Manager",
      "Executive Chef",
      "Event Coordinator"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma8
  },
  {
    id: "diploma9",
    title: "DIPLOMA IN FASHION DESIGNING",
    description:
        "is a vocational program that provides training in various aspects of fashion design, including garment construction, pattern making, and textile selection. Students learn about fashion trends, design principles, and fashion illustration techniques.",
    scopeItems: [
      "Fashion Designer",
      "Fashion Stylist",
      "Apparel Merchandiser",
      "Fashion Illustrator",
      "Costume Designer",
      "Textile Designer",
      "Fashion Consultant"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma9
  },
  {
    id: "diploma10",
    title: "DIPLOMA IN AUTOMOBILE ENGINEERING",
    description:
        "is a vocational program that focuses on the study of automotive technology, vehicle design, and maintenance. Students learn about engine mechanics, vehicle dynamics, and automotive electronics.",
    scopeItems: [
      "Automobile Technician",
      "Automotive Engineer",
      "Service Advisor",
      "Automotive Designer",
      "Diagnostics Technician",
      "Quality Control Inspector",
      "Fleet Manager"
    ],
    salary: "₹2,50,000 - ₹5,00,000 per year",
    imageSrc: diploma10
  }
];

const Diploma = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDisciplines, setFilteredDisciplines] = useState(disciplines);

  useEffect(() => {
    const handleFiltering = () => {
      if (!searchTerm.trim()) {
        setFilteredDisciplines(disciplines);
        return;
      }
      const filtered = disciplines.filter(discipline =>
        discipline.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDisciplines(filtered);
    };
    handleFiltering();
  }, [searchTerm, disciplines]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCardClick = (index,name) => {
    setSelectedCard(selectedCard === index ? null : index);
    navigate(`/courses/diploma/${name}`);
  };

  const CardComponent = () => {
    return (
      <div style={{ background: COLORS.GREY_COLOR, paddingLeft: "15px", paddingRight: "15px", paddingBottom: "22px" }}>
        {filteredDisciplines.length === 0 ? (
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            No courses found
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredDisciplines.map((discipline, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    cursor: "pointer",
                    transition: "box-shadow 0.3s ease-in-out",
                    backgroundColor: COLORS.BACKGROUND_COLOR2,
                    color: COLORS.BACKGROUND_COLOR0,
                    "&:hover": {
                      boxShadow: 10,
                      color: COLORS.BACKGROUND_COLOR1
                    },
                  }}
                  onClick={() => handleCardClick(index, discipline.title)}
                >
                  <CardMedia
                    component="img"
                    image={discipline.imageSrc}
                    alt={discipline.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {discipline.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    );
  };

  return (
    <Box sx={{ padding: "36px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/courses" color={COLORS.MUTED}>
          Courses
        </Link>
        <Link component={RouterLink} to="/courses/diploma" style={{fontWeight: "600"}} color={COLORS.BACKGROUND_COLOR1}>
       Diploma
        </Link>
      </Breadcrumbs>
      <div style={{  background: COLORS.GREY_COLOR, marginTop: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={titleRef}
            variant="h1"
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              marginBottom: "10px",
              fontSize: "25px",
              textAlign: "center",
              background: COLORS.BACKGROUND_COLOR0,
              padding: "10px",
              color: COLORS.BACKGROUND_COLOR2,
              flexGrow: 1,
            }}
          >
           DIPLOMA
          </Typography>
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px" }}>
          <Typography variant="body1" paragraph style={{ textAlign: "justify" }}>
          Diploma courses are educational programs that offer specialized and practical training in a specific field or industry. These courses typically provide a focused curriculum designed to impart practical skills and knowledge necessary for entry-level positions in various professions. Diploma programs are often shorter in duration compared to degree programs, making them a more concise and targeted option for individuals seeking practical, hands-on training in a specific area of study.
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", padding: "10px", textAlign: "justify" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Focused Skill Development:</strong> Diploma courses provide specialized and focused training, allowing individuals to develop practical skills quickly. This targeted approach prepares students for specific roles in industries such as healthcare, technology, business, and more.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Quick Entry into the Workforce:</strong>Diplomas often have shorter durations compared to degree programs, enabling students to enter the workforce sooner. This quick entry allows individuals to start gaining professional experience and applying their skills in a practical setting.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Cost-Effective Education:</strong> Diploma programs are generally more cost-effective than traditional degree programs. The shorter duration and focused curriculum often result in lower tuition fees and living expenses, making education more accessible to a broader range of individuals.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Hands-On Learning:</strong> Diploma courses emphasize hands-on learning and practical application of skills. This approach ensures that students are well-prepared for the demands of the workplace, as they acquire real-world experience during their studies.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong style={{color:COLORS.STRONG_COLOR}}>Career-Specific Training:</strong> Diploma courses are designed to provide training that is directly applicable to specific careers. This targeted education aligns with industry needs, making diploma holders well-suited for roles that require specialized skills and practical knowledge.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <img src={diploma} alt="diploma" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{paddingLeft: "10px", paddingRight:"10px"}}>
          <h2 style={{color: COLORS.BACKGROUND_COLOR0}}>COURSES OFFERED ({disciplines.length} courses)</h2>
          <TextField
            placeholder="Search courses..."
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Box sx={{ mt: 4 }}>
          <CardComponent />
        </Box>
      </div>
    </Box>
  );
};

export default Diploma;
